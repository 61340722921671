import config from "../config";
import async from "async";
import bigInt from "big-integer";
import {
  CLAIM,
  CLAIM_RETURNED,
  CLAIM_HODLER,
  CLAIM_HODLER_RETURNED,
  PUBLIC_CLAIM,
  CONFIGURE,
  CONFIGURE_RETURNED,
  ERROR,
  GET_BALANCES,
  GET_BALANCES_RETURNED,
  GET_PRESALE_INFO,
  GET_PRESALE_INFO_RETURNED,
  GET_PUBLIC_POOL_INFO,
  GET_PUBLIC_POOL_INFO_RETURNED,
  STAKE,
  STAKE_V1,
  STAKE_RETURNED,
  UNSTAKE,
  UNSTAKE_RETURNED,
  UPDATE_REWARDS,
  INVEST,
  PUBLIC_INVEST,
  GET_POOLS_SUMMARY,
  GET_POOLS_SUMMARY_RETURNED,
  APPLY,
  APPLY_RETURNED,
  LOCK_LIQUIDITY,
  LOCK_LIQUIDITY_RETURNED,
  VOTE,
  GET_VEST_STATS,
  GET_VEST_STATS_RETURNED,
  COLLECT_FUND,
  CANCEL_PRESALE,
  SEND_UNSOLD_TOKENS,
  MOVE_STAKE,
  WRITE,
  CUSTOM_WRITE,
  WRITE_RETURNED,
  toFixed,
  sleep,
  UNSTAKE_V1,
  GET_FARMING_INFO,
  GET_FARMING_INFO_RETURNED,
  FARMING_STAKE,
  FARMING_WITHDRAW,
  FARMING_CLAIM,
  ALLOW_CLAIM,
  GET_REFUND,
  PUBLIC_WITHDRAW,
  PUBLIC_WITHDRAW_RETURNED,
  getTokenName,
  getTokenPrice,
  PUBLIC_LOCK,
  PUBLIC_LOCK_RETURNED,
  THEME_CHANGE,
  THEME_CHANGE_RETURNED,
  THEME_MODE_CHANGE_RETURNED,
  THEME_MODE_CHANGE,
  SWAP,
  SWAP_RETURNED,
  fundingTokens,
  expandFixed,
  GET_SWAP_INFO_RETURNED,
  GET_SWAP_INFO,
  ENVIRONMENT,
} from "../constants";

import {
  _getBUIDLPrice,
  _getBUIDLBalance,
  _getSwapData,
  _callClaimReward,
  _callClaimHodler,
  _callWithdraw,
  _callLock,
  _getVESTBalance,
  _getStakedBalance,
  _callApply,
  _checkApproval,
  _callUnstake,
  _callStake,
  _callLockLiquidity,
  _callWrite,
  _getPresaleAbiByBscsId,
  _getPresaleAbiByVersion,
  _getFarmingInfo,
  _getStartInfo,
  _getErc20Balance,
  _callSwap,
} from "./private";

import Web3 from "web3";

import {
  authereum,
  injected,
  injectedtw,
  ledger,
  portis,
  trezor,
  walletconnect,
} from "./connectors";
import { api } from "../constants/api";

const Dispatcher = require("flux").Dispatcher;
const Emitter = require("events").EventEmitter;

const dispatcher = new Dispatcher();
const emitter = new Emitter();

const rpcUrls = {
  bsc: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
  ],
  mtc: [
    "https://rpc-mainnet.maticvigil.com",
    "https://rpc-mainnet.matic.quiknode.pro",
  ],
  pls: ["https://rpc.v4.testnet.pulsechain.com"],
  base:
    ENVIRONMENT === "mainnet"
      ? [
          // "https://mainnet.base.org",
          "https://base-rpc.publicnode.com",
          "https://developer-access-mainnet.base.org",
        ]
      : ["https://base-sepolia.publicnode.com"],
};

const cgcNetworkIds = {
  bsc: "binance-smart-chain",
  mtc: "polygon-pos",
  pls: "pulsechain",
  base: "base",
};

class Store {
  constructor() {
    const themeType = localStorage.getItem("VEST_THEME_TYPE") || "base";
    this.store = {
      themeType,
      currentBlock: 0,
      universalGasPrice: "0.001",
      account: {},
      web3: null,
      connectorsByName: {
        MetaMask: injected,
        TrustWallet: injectedtw,
        // WalletConnect: walletconnect,
        // Ledger: ledger,
        // Trezor: trezor,
        // Portis: portis,
        // Authereum: authereum,
      },
      web3context: null,
      languages: [
        {
          language: "English",
          code: "en",
        },
      ],

      currentPresale: "",
      tokenAddress: config[themeType].vestTokenAddress,
      tokenAbi: config[themeType].vestTokenAbi,
      StakedContractAbi: config[themeType].vestStakingAbi,
      StakedContractAddress: config[themeType].vestStaking,

      StarterInfoAbi: config[themeType].starterInfoV3Abi,
      StarterInfoAddress: config[themeType].starterInfoV3Address,

      starterVestingContract: config[themeType].starterVestingAddress,
      starterVestingAbi: config[themeType].starterVestingAbi,

      pools: {},
      poolInfos: {},
      farming: {
        rewardAllocation: bigInt(),
        totalSupply: bigInt(),
        totalBurned: bigInt(),
        totalLpLocked: bigInt(),
        halvingTimestamp: 0,
        accountInfo: {},
        lpTotalSupply: bigInt(),
        lpBnbBalance: bigInt(),
        rewardEarned: bigInt(),
        withdrawalLimit: 25,
        abi: config[themeType].farmingAbi,
        address: config[themeType].farmingAddress,
        lpAbi: config[themeType].farmingPairAbi,
        lpAddress: config[themeType].farmingPairAddress,
        withdrawLimit: 25,
        withdrawCycle: 86400,
        claimFee: 4,
      },
      bnbPrice: 0,
      bnbBalance: bigInt(),
      vestBalance: bigInt(),
      startPrice: 0,
      totalStakedLocked: bigInt(),
      stakedBalance: bigInt(),
      lastStakedTime: 0,
      lastUnstakedTime: 0,
      burnFee: 50,
      minStakeAmount: bigInt(),

      bnbRewardBalance: bigInt(),
      factoryBalance: bigInt(),
      lockedBalance: bigInt(),
      stakedAndLockedBalance: bigInt(),
      vestStats: {},
      stakingPool: {},
      factoryPool: {
        address: "0x00000000000000000",
        starterInfo: "0x00000000000000000",
        stakingPool: "0x00000000000000000",
        owner: "0x00000000000000000",
        pendingOwner: "0x00000000000000000",
      },
      swapData: {
        swapSymbol: config[themeType].swapSymbol,
        swapTokenAddress: config[themeType].swapToken,
        swapTokenAbi: config[themeType].swapTokenAbi,
        swapRouterAddress: config[themeType].swapRouter,
        swapRouterAbi: config[themeType].swapRouterAbi,
        swapTokenBalance: bigInt(),
        swapPrice: bigInt(),
      },
      rpcIndex: 0,
    };

    dispatcher.register(
      function (payload) {
        switch (payload.type) {
          case THEME_CHANGE:
            this.themeChange(payload);
            break;
          case CONFIGURE:
            this.configure(payload);
            break;
          case GET_BALANCES:
            this.getBalances(payload);
            break;
          case GET_FARMING_INFO:
            this.getFarmingBalances(payload);
            break;
          case STAKE:
            this.stake(payload);
            break;
          case UNSTAKE:
            this.unstake(payload);
            break;
          case SWAP:
            this.swap(payload);
            break;
          case GET_SWAP_INFO:
            this.swapInfo(payload);
            break;
          case STAKE_V1:
            this.stakeV1(payload);
            break;
          case UNSTAKE_V1:
            this.unstakeV1(payload);
            break;
          case CLAIM:
            this.claim(payload);
            break;
          case PUBLIC_WITHDRAW:
            this.withdraw(payload);
            break;
          case PUBLIC_LOCK:
            this.lock(payload);
            break;
          case CLAIM_HODLER:
            this.claimHodler(payload);
            break;
          case PUBLIC_CLAIM:
            this.claim(payload);
            break;
          case UPDATE_REWARDS:
            this.updateRewards(payload);
            break;
          case GET_PRESALE_INFO:
            this.getPresaleInfo(payload);
            break;
          case INVEST:
            this.invest(payload);
            break;
          case PUBLIC_INVEST:
            this.invest(payload);
            break;
          case GET_POOLS_SUMMARY:
            this.getPoolsSummary();
            break;
          case APPLY:
            this.apply(payload);
            break;
          case GET_PUBLIC_POOL_INFO:
            this.getPublicPoolInfo(payload);
            break;
          case LOCK_LIQUIDITY:
            this.lockLiquidity(payload);
            break;
          case VOTE:
            this.vote(payload);
            break;
          case GET_VEST_STATS:
            this.getVestStats(payload);
            break;
          case COLLECT_FUND:
            this.collectFunds(payload);
            break;
          case CANCEL_PRESALE:
            this.cancelPresale(payload);
            break;
          case SEND_UNSOLD_TOKENS:
            this.sendUnsoldTokens(payload);
            break;
          case ALLOW_CLAIM:
            this.allowClaim(payload);
            break;
          case MOVE_STAKE:
            this.moveStake(payload);
            break;
          case WRITE:
            this.write(payload);
            break;
          case CUSTOM_WRITE:
            this.customWrite(payload);
            break;
          case FARMING_STAKE:
            this.farmStake(payload);
            break;
          case FARMING_WITHDRAW:
            this.farmWithdraw(payload);
            break;
          case THEME_MODE_CHANGE:
            this.themeModeChange(payload);
            break;
          case FARMING_CLAIM:
            this.farmClaim(payload);
            break;
          case GET_REFUND:
            this.getRefund(payload);
            break;
          default: {
          }
        }
      }.bind(this)
    );
  }

  getStore(index) {
    return this.store[index];
  }

  setStore(obj) {
    this.store = {
      ...this.store,
      ...obj,
    };
    return emitter.emit("StoreUpdated");
  }

  initializeStore = () => {
    const themeType = store.getStore("themeType");

    this.store = {
      themeType,
      currentBlock: 0,
      universalGasPrice: "0.001",
      account: {},
      web3: null,
      connectorsByName: {
        MetaMask: injected,
        TrustWallet: injectedtw,
        WalletConnect: walletconnect,
        Ledger: ledger,
        Trezor: trezor,
        Portis: portis,
        Authereum: authereum,
      },
      web3context: null,
      languages: [
        {
          language: "English",
          code: "en",
        },
      ],

      currentPresale: "",
      tokenAddress: config[themeType].vestTokenAddress,
      tokenAbi: config[themeType].vestTokenAbi,
      StakedContractAbi: config[themeType].vestStakingAbi,
      StakedContractAddress: config[themeType].vestStaking,

      StarterInfoAbi: config[themeType].starterInfoV3Abi,
      StarterInfoAddress: config[themeType].starterInfoV3Address,

      VestingContract: config[themeType].vestingAddress,
      VestingContractAbi: config[themeType].vestingAbi,

      starterVestingContract: config[themeType].starterVestingAddress,
      starterVestingAbi: config[themeType].starterVestingAbi,

      pools: {},
      poolInfos: {},
      farming: {
        rewardAllocation: bigInt(),
        totalSupply: bigInt(),
        totalBurned: bigInt(),
        totalLpLocked: bigInt(),
        halvingTimestamp: 0,
        accountInfo: {},
        lpTotalSupply: bigInt(),
        lpBnbBalance: bigInt(),
        rewardEarned: bigInt(),
        withdrawalLimit: 25,
        abi: config[themeType].farmingAbi,
        address: config[themeType].farmingAddress,
        lpAbi: config[themeType].farmingPairAbi,
        lpAddress: config[themeType].farmingPairAddress,
        withdrawLimit: 25,
        withdrawCycle: 86400,
        claimFee: 4,
      },
      bnbPrice: 0,
      bnbBalance: bigInt(),
      vestBalance: bigInt(),
      startPrice: 0,
      totalStakedLocked: bigInt(),
      stakedBalance: bigInt(),
      lastStakedTime: 0,
      lastUnstakedTime: 0,
      burnFee: 50,
      minStakeAmount: bigInt(),

      bnbRewardBalance: bigInt(),
      factoryBalance: bigInt(),
      lockedBalance: bigInt(),
      stakedAndLockedBalance: bigInt(),
      vestStats: {},
      stakingPool: {},
      factoryPool: {
        address: "0x00000000000000000",
        starterInfo: "0x00000000000000000",
        stakingPool: "0x00000000000000000",
        owner: "0x00000000000000000",
        pendingOwner: "0x00000000000000000",
      },
      swapData: {
        swapSymbol: config[themeType].swapSymbol,
        swapTokenAddress: config[themeType].swapToken,
        swapTokenAbi: config[themeType].swapTokenAbi,
        swapRouterAddress: config[themeType].swapRouter,
        swapRouterAbi: config[themeType].swapRouterAbi,
        swapTokenBalance: bigInt(),
        swapPrice: bigInt(),
      },
      rpcIndex: 0,
    };
  };

  themeChange = ({ content: { themeType } }) => {
    localStorage.setItem("VEST_THEME_TYPE", themeType);
    const orgThemeType = store.getStore("themeType");
    store.setStore({ themeType });
    if (orgThemeType !== themeType) {
      store.initializeStore();
      store.getPoolsSummary();
      store.getVestStats();
      return emitter.emit(THEME_CHANGE_RETURNED);
    }
  };

  configure = async () => {
    const web3 = new Web3(store.getStore("web3context").library.provider);
    const currentBlock = await web3.eth.getBlockNumber();

    store.setStore({
      currentBlock: currentBlock,
    });

    window.setTimeout(() => {
      emitter.emit(CONFIGURE_RETURNED);
    }, 100);
  };

  getRpcUrl = async () => {
    const themeType = store.getStore("themeType");

    let rpcIndex = 0;
    while (1) {
      for (rpcIndex = 0; rpcIndex < rpcUrls[themeType].length; rpcIndex++) {
        try {
          const web3 = new Web3(rpcUrls[themeType][rpcIndex]);
          const currentBlock = await web3.eth.getBlockNumber();
          console.log(
            "Connected to RPC:",
            rpcUrls[themeType][rpcIndex],
            currentBlock
          );
          store.setStore({
            rpcIndex,
          });
          return rpcUrls[themeType][rpcIndex];
        } catch (error) {
          console.log("Invalid URL", rpcUrls[themeType][rpcIndex]);
          await sleep(1000);
        }
      }
      await sleep(10000);
    }
  };

  themeModeChange = ({ content: { themeMode } }) => {
    localStorage.setItem("THEME_MODE", themeMode);
    store.setStore({ themeMode });
    return emitter.emit(THEME_MODE_CHANGE_RETURNED, themeMode);
  };

  swap = async ({ content: { amount, amountOut, direction } }) => {
    const themeType = store.getStore("themeType");
    const account = store.getStore("account");
    const web3 = new Web3(store.getStore("web3context").library.provider);
    const buidlTokenAddress = store.getStore("tokenAddress");
    const swapData = store.getStore("swapData");

    const swapRouterContract = new web3.eth.Contract(
      swapData.swapRouterAbi,
      swapData.swapRouterAddress
    );

    const path =
      direction === 0
        ? [swapData.swapTokenAddress, buidlTokenAddress]
        : [buidlTokenAddress, swapData.swapTokenAddress];

    const inputToken =
      direction === 0 ? swapData.swapTokenAddress : buidlTokenAddress;

    const outputToken =
      direction === 0 ? buidlTokenAddress : swapData.swapTokenAddress;

    amount =
      inputToken === swapData.swapTokenAddress
        ? expandFixed(
            amount,
            fundingTokens[themeType].find(
              (token) => token.value === swapData.swapTokenAddress
            ).decimals
          ).toString()
        : expandFixed(amount, 18).toString();
    amountOut =
      outputToken === swapData.swapTokenAddress
        ? expandFixed(
            amountOut,
            fundingTokens[themeType].find(
              (token) => token.value === swapData.swapTokenAddress
            ).decimals
          ).toString()
        : expandFixed(amountOut, 18).toString();

    if (
      web3.utils.toChecksumAddress(inputToken) !==
      web3.utils.toChecksumAddress(
        fundingTokens[themeType].find((token) => token.label === "ETH").value
      )
    ) {
      const isApproved = await _checkApproval(
        store,
        dispatcher,
        emitter,
        config[themeType].ERC20Abi,
        inputToken,
        account,
        Web3.utils.toWei(amount.toString()),
        swapData.swapRouterAddress
      );
      if (!isApproved) {
        return emitter.emit(ERROR, {
          message: "APPROVE failed",
        });
      }
    }
    _callSwap(
      store,
      dispatcher,
      emitter,
      web3,
      account,
      swapRouterContract,
      path,
      inputToken,
      outputToken,
      amount,
      amountOut,
      (err, res) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }
        return emitter.emit(SWAP_RETURNED, res);
      }
    );
  };

  getBalances = async () => {
    try {
      const account = store.getStore("account");
      const rpcUrl = await this.getRpcUrl();
      const web3 = new Web3(rpcUrl);

      const currentBlock = await web3.eth.getBlockNumber();
      store.setStore({
        currentBlock: currentBlock,
      });

      async.parallel(
        [
          (callbackInnerInner) => {
            _getVESTBalance(
              store,
              dispatcher,
              emitter,
              web3,
              account,
              callbackInnerInner
            );
          },
          (callbackInnerInner) => {
            _getStakedBalance(
              store,
              dispatcher,
              emitter,
              web3,
              account,
              callbackInnerInner
            );
          },
          (callbackInnerInner) => {
            _getBUIDLPrice(callbackInnerInner);
          },
          (callbackInnerInner) => {
            _getBUIDLBalance(store, web3, account, callbackInnerInner);
          },
        ],
        (err, data) => {
          if (err) {
            console.log(err);
            return emitter.emit(ERROR, err);
          }
          store.setStore({
            vestBalance: data[0].balance,
            totalStakedLocked: data[0].totalStakedLocked,
            stakedBalance: data[1].balance,
            lastStakedTime: data[1].lastStakedTime,
            lastUnstakedTime: data[1].lastUnstakedTime,
            burnFee: data[1].burnFee,
            minStakeAmount: data[1].minStakeAmount,
            buidlPrice: data[2],
            buidlBalance: data[3]?.balance || bigInt(),
          });
          return emitter.emit(GET_BALANCES_RETURNED);
        }
      );
    } catch (error) {
      console.error("GET_BALANCES", error);
      return emitter.emit(ERROR, error);
    }
  };

  swapInfo = async () => {
    try {
      const account = store.getStore("account");
      const rpcUrl = await this.getRpcUrl();
      const web3 = new Web3(rpcUrl);

      const currentBlock = await web3.eth.getBlockNumber();
      store.setStore({
        currentBlock: currentBlock,
      });

      const _swapData = await _getSwapData(store, web3, account);

      store.setStore({
        swapData: _swapData,
      });

      return emitter.emit(GET_SWAP_INFO_RETURNED);
    } catch (error) {
      console.error("GET_SWAP_INFO", error);
      return emitter.emit(ERROR, error);
    }
  };

  getVestStats = async () => {
    const themeType = store.getStore("themeType");
    try {
      const url = `https://base.api.starter.xyz/vest/${themeType}/stats`;
      const res = await api.get(url);

      store.setStore({
        vestStats: res.data,
      });
      return emitter.emit(GET_VEST_STATS_RETURNED);
    } catch (error) {
      console.error("GET_VEST_STATS_RETURNED", error);
      return emitter.emit(ERROR, error);
    }
  };

  getPresaleInfo = async ({ content: { poolIndex } }) => {
    try {
      const account = store.getStore("account");
      const rpcUrl = await this.getRpcUrl();
      const web3 = new Web3(rpcUrl);
      const presales = store.getStore("presales");
      const presale = presales[poolIndex];
      const presaleContract = new web3.eth.Contract(
        presale.presaleAbi,
        presale.presaleAddress
      );
      const totalInvestorsCount = await presaleContract.methods
        .totalInvestorsCount()
        .call();
      const totalCollectedWei = await presaleContract.methods
        .totalCollectedWei()
        .call();
      const totalTokens = await presaleContract.methods.totalTokens().call();
      const tokensLeft = await presaleContract.methods.tokensLeft().call();
      const tokenPriceInWei = await presaleContract.methods
        .tokenPriceInWei()
        .call();
      const hardCapInWei = await presaleContract.methods.hardCapInWei().call();
      const softCapInWei = await presaleContract.methods.softCapInWei().call();
      const maxInvestInWei = await presaleContract.methods
        .maxInvestInWei()
        .call();
      const minInvestInWei = await presaleContract.methods
        .minInvestInWei()
        .call();
      const openTime = await presaleContract.methods.openTime().call();
      const closeTime = await presaleContract.methods.closeTime().call();
      const cakeLiquidityAddingTime = presaleContract.methods
        .cakeLiquidityAddingTime
        ? await presaleContract.methods.cakeLiquidityAddingTime().call()
        : 0;

      const saleTitle = web3.utils.hexToUtf8(
        await presaleContract.methods.saleTitle().call()
      );
      const linkTelegram = web3.utils.hexToUtf8(
        await presaleContract.methods.linkTelegram().call()
      );
      const linkTwitter = web3.utils.hexToUtf8(
        await presaleContract.methods.linkTwitter().call()
      );
      const linkGithub = web3.utils.hexToUtf8(
        await presaleContract.methods.linkGithub().call()
      );
      const linkWebsite = web3.utils.hexToUtf8(
        await presaleContract.methods.linkWebsite().call()
      );
      const linkLogo =
        poolIndex !== "wsbprivate" &&
        poolIndex !== "wisepublic" &&
        poolIndex !== "vestSeedSale" &&
        poolIndex !== "vestPrivateSale"
          ? web3.utils.hexToUtf8(
              await presaleContract.methods.linkLogo().call()
            )
          : await presaleContract.methods.linkLogo().call();
      const auditedInfo = presaleContract.methods.auditInformation
        ? await presaleContract.methods.auditInformation().call()
        : {
            auditor: "0x0",
            isVerified: false,
            isWarning: false,
            linkAudit: "",
            verifiedHash: "",
            warningHash: "",
          };
      const claimAllowed = await presaleContract.methods.claimAllowed().call();
      const claimCycle = presaleContract.methods.claimCycle
        ? await presaleContract.methods.claimCycle().call()
        : 10000000000;

      const auditInformation = {
        auditor: web3.utils.hexToUtf8(auditedInfo.auditor),
        isVerified: auditedInfo.isVerified,
        isWarning: auditedInfo.isWarning,
        linkAudit: auditedInfo?.linkAudit,
        verifiedHash: auditedInfo.verifiedHash,
        warningHash: auditedInfo.warningHash,
      };
      const accountInvestment = await presaleContract.methods
        .investments(account.address)
        .call({
          from: account.address,
        });

      const isWhitelistedAddress = await presaleContract.methods
        .whitelistedAddresses(account.address)
        .call({
          from: account.address,
        });

      const claimedPeriod = await presaleContract.methods
        .claimed(account.address)
        .call({
          from: account.address,
        });

      const totalDistributionPeriod =
        poolIndex == "seed" ||
        poolIndex == "private" ||
        poolIndex == "strategic"
          ? 4
          : poolIndex == "vestSeedSale" || poolIndex == "vestPrivateSale"
          ? 9
          : 1;

      const tokenAmount =
        tokenPriceInWei > 0
          ? poolIndex == "seed" ||
            poolIndex == "private" ||
            poolIndex == "strategic" ||
            poolIndex == "vestSeedSale" ||
            poolIndex == "vestPrivateSale"
            ? bigInt(accountInvestment)
                .divide(bigInt(tokenPriceInWei))
                .multiply(totalDistributionPeriod - claimedPeriod)
                .divide(totalDistributionPeriod)
            : bigInt(accountInvestment)
                .divide(bigInt(tokenPriceInWei))
                .multiply(1 - claimedPeriod)
          : 0;

      const description = presaleContract.methods.description
        ? await presaleContract.methods.description().call()
        : "";

      const guaranteedHours = presaleContract.methods.guaranteedHours
        ? await presaleContract.methods.guaranteedHours().call()
        : "";
      const updatedPresaleInfo = {
        ...presale,
        totalInvestorsCount,
        totalCollectedWei,
        totalTokens,
        tokensLeft,
        tokenPriceInWei,
        hardCapInWei,
        softCapInWei,
        maxInvestInWei,
        minInvestInWei,
        openTime,
        closeTime,
        cakeLiquidityAddingTime,
        saleTitle,
        linkTelegram,
        linkTwitter,
        linkGithub,
        linkWebsite,
        linkLogo,
        accountInvestment,
        isWhitelistedAddress,
        claimedPeriod,
        tokenAmount,
        auditInformation,
        claimAllowed,
        claimCycle,
        description,
        guaranteedHours,
      };

      store.setStore({
        presales: {
          ...presales,
          [poolIndex]: {
            ...updatedPresaleInfo,
          },
        },
      });
      return emitter.emit(GET_PRESALE_INFO_RETURNED);
    } catch (error) {
      console.error("GET_PRESALE_INFO", error);
      return emitter.emit(ERROR, error);
    }
  };

  getPublicPoolInfo = async ({ content: { poolIndex } }) => {
    try {
      const themeType = store.getStore("themeType");
      const account = store.getStore("account");
      const rpcUrl = await this.getRpcUrl();
      const web3 = new Web3(rpcUrl);
      const pools = store.getStore("pools");
      const orgPoolInfos = store.getStore("poolInfos");
      const vestingAbi = store.getStore("starterVestingAbi");
      const vestingAddress = store.getStore("starterVestingContract");
      const vestingContract = new web3.eth.Contract(vestingAbi, vestingAddress);

      if (!pools || Object.keys(pools).length === 0) {
        return emitter.emit(GET_PUBLIC_POOL_INFO_RETURNED);
      }
      const _factoredPools =
        Object.values(pools)?.reduce((orgPools, pool) => {
          if (orgPools[pool.tokenAddress]?.length > 0) {
            const newPools = {
              ...orgPools,
              [`${pool.tokenAddress}`]: [...orgPools[pool.tokenAddress], pool],
            };
            return newPools;
          } else {
            const newPools = {
              ...orgPools,
              [`${pool.tokenAddress}`]: [pool],
            };
            return newPools;
          }
        }, {}) || [];
      const _sortedPools = Object.keys(_factoredPools).reduce(
        (orgPools, pool) => {
          return {
            ...orgPools,
            [`${pool}`]: _factoredPools[pool].sort(
              (pool1, pool2) =>
                parseInt(pool1.unlockTime) - parseInt(pool2.unlockTime)
            ),
          };
        },
        {}
      );
      const tokenInfo = { ..._sortedPools[poolIndex][0] };
      const name = getTokenName(_sortedPools[poolIndex][0]);
      const tokenPrice = getTokenPrice(_sortedPools[poolIndex][0]);
      const tokenBalance = await _getErc20Balance(
        store,
        dispatcher,
        emitter,
        config[themeType].ERC20Abi,
        poolIndex,
        account
      );

      const lockedAmount = await vestingContract.methods
        .getTotalTokenBalance(poolIndex)
        .call();

      let pendingRewards = bigInt();
      let isClaimed = false;
      if (account && account.address) {
        isClaimed = await vestingContract.methods
          .isClaimed(poolIndex, account.address)
          .call();
        //pendingRewards = !isClaimed ? await vestingContract.methods.pendingRewards(account.address).call() : bigInt();
      }

      const poolInfo = {
        ...tokenInfo,
        tokenAddress: poolIndex,
        name,
        price: tokenPrice,
        locked: parseFloat(
          toFixed(bigInt(lockedAmount), tokenInfo.decimals, 4)
        ),
        tokenBalance,
        pools: [..._sortedPools[poolIndex]],
        pendingRewards: bigInt(pendingRewards),
        isClaimed,
      };
      store.setStore({
        poolInfos: {
          ...orgPoolInfos,
          [poolIndex]: poolInfo,
        },
      });
      return emitter.emit(GET_PUBLIC_POOL_INFO_RETURNED);
    } catch (error) {
      console.error("GET_PUBLIC_POOL_INFO", error);
      return emitter.emit(ERROR, error);
    }
  };

  getFarmingBalances = async () => {
    const provider = await this.getRpcUrl();
    const web3 = new Web3(provider);
    const account = store.getStore("account");

    try {
      async.parallel(
        [
          (callbackInnerInner) => {
            _getStartInfo(store, dispatcher, emitter, web3, callbackInnerInner);
          },
          (callbackInnerInner) => {
            _getFarmingInfo(
              store,
              dispatcher,
              emitter,
              web3,
              account,
              callbackInnerInner
            );
          },
        ],
        (err, data) => {
          if (err) {
            console.log(err);
            return emitter.emit(ERROR, err);
          }
          const farmingPool = store.getStore("farming");
          store.setStore({
            farming: {
              ...farmingPool,
              totalSupply: data[0].totalSupply,
              totalBurned: data[0].totalBurned,
              halvingTimestamp: data[1].halvingTimestamp,
              rewardAllocation: data[1].rewardAllocation,
              accountInfo: data[1].accountInfo,
              totalLpLocked: data[1].totalLocked,
              lpTotalSupply: data[1].lpTotalSupply,
              lpBnbBalance: data[1].lpBnbBalance,
              rewardEarned: data[1].rewardEarned,
              withdrawLimit: data[1].withdrawLimit,
              withdrawCycle: data[1].withdrawCycle,
              claimFee: data[1].claimFee,
              farmingStartTimestamp: data[1].farmingStartTimestamp,
            },
          });
          return emitter.emit(GET_FARMING_INFO_RETURNED);
        }
      );
    } catch (e) {
      console.log("Get Farming Balance Error", e);
    }
  };

  getPoolsSummary = async () => {
    const themeType = store.getStore("themeType");

    try {
      const url = `https://base.api.starter.xyz/vest/${themeType}/pools-list`;
      const res = await api.get(url);

      store.setStore({
        pools: res.data,
      });
      return emitter.emit(GET_POOLS_SUMMARY_RETURNED);
    } catch (e) {
      console.error("GET_PUBLIC_POOL_SUMMARY_ERROR - 1", e);
      return emitter.emit(ERROR, e);
    }
  };

  getTokenImage = async (tokenAddress) => {
    // const themeType = store.getStore("themeType");
    // const networkId = cgcNetworkIds[themeType];
    // try {
    // todo: get image from somewhere else
    // const url = `https://api.coingecko.com/api/v3/coins/${networkId}/contract/${tokenAddress}`;
    // const res = await api.get(url);

    // return res.data["image"]["thumb"];
    // } catch (e) {
    //   console.error("GET_MARKET_CHART", e);
    return "";
    // }
  };

  write = async ({ content: { poolIndex, method, args } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      if (poolIndex == -1) {
        const contractAbi = store.getStore("StakedContractAbi");
        const contractAddress = store.getStore("StakedContractAddress");

        let stakedContract = new web3.eth.Contract(
          contractAbi,
          contractAddress
        );

        _callWrite(
          store,
          dispatcher,
          emitter,
          web3,
          stakedContract,
          account,
          poolIndex,
          method,
          args,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }

            return emitter.emit(WRITE_RETURNED, res);
          }
        );
      } else if (poolIndex == -2) {
        const factory = store.getStore("factory");
        const contractAbi = factory.factoryV3Abi;
        const contractAddress = factory.factoryV3Address;

        let factoryContract = new web3.eth.Contract(
          contractAbi,
          contractAddress
        );

        _callWrite(
          store,
          dispatcher,
          emitter,
          web3,
          factoryContract,
          account,
          poolIndex,
          method,
          args,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }

            return emitter.emit(WRITE_RETURNED, res);
          }
        );
      } else {
        let presaleAddress;
        presaleAddress = await starterInfoContract.methods
          .getPresaleAddress(poolIndex)
          .call();
        const presaleContract = new web3.eth.Contract(
          _getPresaleAbiByBscsId(store, poolIndex),
          presaleAddress
        );
        _callWrite(
          store,
          dispatcher,
          emitter,
          web3,
          presaleContract,
          account,
          poolIndex,
          method,
          args,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }

            return emitter.emit(WRITE_RETURNED, res);
          }
        );
      }
    } catch (error) {
      console.error("WRITE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  customWrite = async ({ content: { address, abi, method, args } }) => {
    const account = store.getStore("account");
    const web3 = new Web3(store.getStore("web3context").library.provider);
    const presaleContract = new web3.eth.Contract(
      _getPresaleAbiByVersion(store, abi),
      address
    );
    console.log(address, abi, method, args);
    _callWrite(
      store,
      dispatcher,
      emitter,
      web3,
      presaleContract,
      account,
      0,
      method,
      args,
      (err, res) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }

        return emitter.emit(WRITE_RETURNED, res);
      }
    );
  };

  lockLiquidity = async ({ content: { poolIndex } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const presales = store.getStore("pools");
      const presale = presales[poolIndex];
      console.log("presale", presale);
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      let presaleAddress = await starterInfoContract.methods
        .getPresaleAddress(poolIndex)
        .call();
      const presaleContract = new web3.eth.Contract(
        _getPresaleAbiByBscsId(store, poolIndex),
        presaleAddress
      );
      _callLockLiquidity(
        store,
        dispatcher,
        emitter,
        web3,
        presaleContract,
        account,
        poolIndex,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(LOCK_LIQUIDITY_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("LOCK_LIQUIDITY ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  fetchTokenData = async (tokenAddress, account) => {
    try {
      const themeType = store.getStore("themeType");
      const rpcUrl = await this.getRpcUrl();
      const web3 = new Web3(rpcUrl);
      const tokenContract = new web3.eth.Contract(
        config[themeType].ERC20Abi,
        tokenAddress
      );
      if (tokenContract.methods.balanceOf) {
        const name = await tokenContract.methods.name().call();
        const symbol = await tokenContract.methods.symbol().call();
        const decimals = await tokenContract.methods.decimals().call();
        const tokenImage = await this.getTokenImage(tokenAddress);
        const tokenBalance =
          account && account.address
            ? await tokenContract.methods.balanceOf(account.address).call()
            : "0";
        return {
          name,
          symbol,
          decimals,
          tokenImage,
          tokenBalance: bigInt(tokenBalance),
        };
      }
      return {};
    } catch (error) {
      console.log("fetchTokenData error", error);
      return {};
    }
  };

  stake = async ({ content: { amount } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const contractAbi = store.getStore("StakedContractAbi");
      const contractAddress = store.getStore("StakedContractAddress");

      const tokenAbi = store.getStore("tokenAbi");
      const tokenAddress = store.getStore("tokenAddress");

      let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);
      const isApproved = await _checkApproval(
        store,
        dispatcher,
        emitter,
        tokenAbi,
        tokenAddress,
        account,
        amount,
        contractAddress
      );
      if (!isApproved) {
        return emitter.emit(ERROR, {
          message: "APPROVE failed",
        });
      }

      _callStake(
        store,
        dispatcher,
        emitter,
        web3,
        stakedContract,
        amount,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(STAKE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("STAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  unstake = async ({ content: { amount } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const contractAbi = store.getStore("StakedContractAbi");
      const contractAddress = store.getStore("StakedContractAddress");

      let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);

      _callUnstake(
        store,
        dispatcher,
        emitter,
        web3,
        stakedContract,
        amount,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }
          return emitter.emit(UNSTAKE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("UNSTAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  apply = async ({
    content: { bscPresaleInfo, presalePancakeSwapInfo, presaleStringInfo },
  }) => {
    try {
      const themeType = store.getStore("themeType");
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const factory = store.getStore("factory");

      const factoryContract = new web3.eth.Contract(
        factory.factoryV3Abi,
        factory.factoryV3Address
      );

      const amount = bigInt(bscPresaleInfo.hardCapInWei).divide(
        bigInt(bscPresaleInfo.tokenPriceInWei)
      );
      const isApproved = await _checkApproval(
        store,
        dispatcher,
        emitter,
        config[themeType].ERC20Abi,
        bscPresaleInfo.tokenAddress,
        account,
        amount,
        factory.factoryV3Address
      );
      if (!isApproved) {
        return emitter.emit(ERROR, {
          message: "APPROVE failed",
        });
      }
      _callApply(
        store,
        dispatcher,
        emitter,
        web3,
        factoryContract,
        account,
        bscPresaleInfo,
        presalePancakeSwapInfo,
        presaleStringInfo,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(APPLY_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("APPLY ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  claim = ({ content: { poolIndex } }) => {
    const account = store.getStore("account");

    _callClaimReward(
      store,
      dispatcher,
      emitter,
      poolIndex,
      account,
      (err, res) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }

        return emitter.emit(CLAIM_RETURNED, res);
      }
    );
  };

  withdraw = ({ content: { poolId } }) => {
    const account = store.getStore("account");

    _callWithdraw(store, dispatcher, emitter, poolId, account, (err, res) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      return emitter.emit(PUBLIC_WITHDRAW_RETURNED, res);
    });
  };

  lock = async ({ content: { tokenAddress, amount, unlockTime } }) => {
    const themeType = store.getStore("themeType");
    const account = store.getStore("account");
    const vestingAddress = store.getStore("starterVestingContract");

    const isApproved = await _checkApproval(
      store,
      dispatcher,
      emitter,
      config[themeType].ERC20Abi,
      tokenAddress,
      account,
      amount,
      vestingAddress
    );
    if (!isApproved) {
      return emitter.emit(ERROR, {
        message: "APPROVE failed",
      });
    }

    _callLock(
      store,
      dispatcher,
      emitter,
      tokenAddress,
      amount,
      unlockTime,
      account,
      (err, res) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }

        return emitter.emit(PUBLIC_LOCK_RETURNED, res);
      }
    );
  };

  claimHodler = (param) => {
    const account = store.getStore("account");

    _callClaimHodler(store, dispatcher, emitter, account, (err, res) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      return emitter.emit(CLAIM_HODLER_RETURNED, res);
    });
  };
}

const store = new Store();

export default {
  store: store,
  dispatcher: dispatcher,
  emitter: emitter,
};
