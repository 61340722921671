import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button, useTheme } from "@material-ui/core";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as TelegramIcon } from "../../assets/telegram.svg";
import { ReactComponent as MediumIcon } from "../../assets/medium.svg";
import { ReactComponent as GitbookIcon } from "../../assets/gitbook.svg";

import dexToolsIcon from "../../assets/icons/dextools.png";
import baseScanIcon from "../../assets/icons/basescan.png";

import BuyModalBox from "../buyBuidlModal";

import { withNamespaces } from "react-i18next";
import {
  GET_BALANCES_RETURNED,
  GET_SWAP_INFO_RETURNED,
  SWAP,
  defaultBuyUrls,
  toFixed,
} from "../../constants/constants";
import { useAccount } from "../../contexts/AccountContext";

import GlobalStore from "../../stores";

const styles = (theme) => ({
  footerContainer: {
    display: "flex",
    width: "100%",
    background: theme?.colors?.footer?.backgroundColor,
    // borderTop: "1px solid #393939",

    [theme.breakpoints.up("md")]: {
      height: "120px",
    },

    alignItems: "center",
    justifyContent: "center",
  },
  footerInnerContainer: {
    display: "flex",
    height: "98%",
    width: "100%",
    padding: "16px 6rem",
    backgroundSize: "cover",
    alignItems: "center",
    boxShadow: "0px -1px 2px #0B193B24",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "16px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  footerLinks: {
    flex: 1,
    display: "flex",
    width: "100%",

    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  footerLinksText: {
    lineHeight: "20px",
    // color: "#fff",
    color: theme?.colors?.footer?.brandName,
    fontWeight: "bold",
    letterSpacing: "0px",
    margin: "0 0 6px 0",
    textAlign: "center",
    fontSize: "16px",
    marginBottom: "6px",

    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginBottom: "0px",
      fontSize: "12px",
    },
  },
  footerLinksContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",

    [theme.breakpoints.up("md")]: {
      marginLeft: "38px",
      marginTop: "0px",
    },
  },
  footerRight: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: {
    color: theme?.colors.poolCard?.linkIcon,
  },
  link: {
    padding: "0 5px 0 5px",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  certifiedBadge: {
    width: "30px",
    height: "30px",
    position: "absolute",
    left: "3px",
    top: "3px",
  },
  buyTokenButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonTextSecondary,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  starterLtdText: {
    color: "#A0A0A0",
    fontSize: "10px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  oplsBalance: {
    color: theme?.colors?.footer?.tokenBalance,
    fontSize: "16px",
    margin: "0 32px 0 8px",
  },
  footerOplsContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const Store = GlobalStore.store;
const dispatcher = GlobalStore.dispatcher;
const emitter = GlobalStore.emitter;

const Footer = ({ classes }) => {
  const [isBuyModalShown, showBuyModal] = React.useState(false);
  const [buidlTokenPrice, setBuidlTokenPrice] = React.useState(0);
  const [swapTokenAmount, setSwapTokenAmount] = React.useState(0);
  const [swapPrice, setSwapPrice] = React.useState(0);
  const [buidlTokenAmount, setBuidlTokenAmount] = React.useState(0);

  const { account } = useAccount();

  const theme = useTheme();

  React.useEffect(() => {
    fetchSwapData();

    emitter.on(GET_BALANCES_RETURNED, () => {
      getBalancesReturned();
    });
    emitter.on(GET_SWAP_INFO_RETURNED, () => {
      fetchSwapData();
    });

    return () => {
      emitter.removeListener(GET_BALANCES_RETURNED, () => {
        getBalancesReturned();
      });
      emitter.removeListener(GET_SWAP_INFO_RETURNED, () => {
        fetchSwapData();
      });
    };
  }, []);

  const getBalancesReturned = () => {
    const buidlPrice = Store.getStore("buidlPrice");
    const buidlBalance = Store.getStore("buidlBalance");
    setBuidlTokenPrice(buidlPrice);
    console.log({ buidlBalance });

    setBuidlTokenAmount(toFixed(buidlBalance, 18, 3));
  };

  const fetchSwapData = () => {
    const swapData = Store.getStore("swapData");

    setSwapTokenAmount(
      toFixed(swapData.swapTokenBalance, swapData.swapTokenDecimals, 3)
    );
    setSwapPrice(toFixed(swapData.swapPrice, 18, 3));
  };

  const onSwap = (amount, amountOut, direction) => {
    const minAmountOut = (parseFloat(amountOut) * 995) / 1000;

    dispatcher.dispatch({
      type: SWAP,
      content: {
        amount: amount,
        amountOut: minAmountOut,
        direction,
      },
    });
  };

  return (
    <div className={classes.footerContainer}>
      <div className={classes.footerInnerContainer}>
        <div className={classes.footerLinks}>
          <div>
            <p className={classes.footerLinksText}>Starter Labs</p>
            <div className={classes.starterLtdText}>
              © 2021 - {new Date().getFullYear()}
            </div>
          </div>
          <div className={classes.footerLinksContainer}>
            <a
              href="https://t.me/starterlabshq"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            <a
              href="https://twitter.com/starterlabshq"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            <a
              href="https://starterlabs.medium.com/"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MediumIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>

            <a
              href="https://docs.starter.xyz"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitbookIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>

            <a
              href="https://www.dextools.io/app/en/base/pair-explorer/0xcdbe83ca99d76f47f69314860f4eb52c2abaab9c"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={classes.icon}
                src={dexToolsIcon}
                alt="DexTools Explorer"
                style={{ width: "24px", height: "24px" }}
              />
            </a>

            <a
              href="https://basescan.org/token/0xdadc4f8e9d8d37294de0e02a2ce8cc0c90a4f6c2"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={classes.icon}
                alt="BUIDL at Base Scan"
                src={baseScanIcon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
          </div>
        </div>

        <div className={classes.footerOplsContainer}>
          <img
            src={theme.logoImage}
            alt="logo"
            style={{ width: "27px", height: "24px" }}
          />
          {/* todo: get BUIDL balance */}
          <p className={classes.oplsBalance}>{buidlTokenPrice}</p>
          <Button
            classes={{
              root: classes.buyTokenButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              showBuyModal(true);
            }}
          >
            Get BUIDL
          </Button>
        </div>
      </div>

      <BuyModalBox
        modalOpen={isBuyModalShown}
        closeModal={() => {
          showBuyModal(false);
        }}
        bnbBalance={swapTokenAmount}
        buidlBalance={buidlTokenAmount}
        price={swapPrice}
        onSwap={onSwap}
        account={account}
        url={defaultBuyUrls[theme?.type]}
      />
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(Footer)));
