import config from "../config";
import bigInt from "big-integer";
import Web3 from "web3";

import {
  ERROR,
  GET_BALANCES,
  GET_PRESALE_INFO,
  GET_PUBLIC_POOL_INFO,
  GET_POOLS_SUMMARY,
  GET_FARMING_INFO,
  fundingTokens,
  ENVIRONMENT,
} from "../constants";

import { api } from "../constants/api";

export const _getBUIDLPrice = async (callback) => {
  try {
    const url =
      ENVIRONMENT === "mainnet"
        ? "https://base.api.starter.xyz/base/stats"
        : "https://test.base.api.starter.xyz/base/stats";
    const buidlStats = await api.get(url);
    console.log(buidlStats.data.buidlPrice);

    callback(null, buidlStats.data.buidlPrice);
  } catch (e) {
    console.error(e);
    // callback(null);
    // callback(e);
  }
};

export const _getBUIDLBalance = async (store, web3, account, callback) => {
  const tokenAbi = store.getStore("tokenAbi");
  const tokenAddress = store.getStore("tokenAddress");

  let walletContract = new web3.eth.Contract(tokenAbi, tokenAddress);

  try {
    if (account && account.address) {
      let balance = await walletContract.methods
        .balanceOf(account.address)
        .call();

      callback(null, {
        balance: bigInt(balance),
      });
    } else {
      let balance = "0";

      callback(null, {
        balance: bigInt(balance),
      });
    }
  } catch (ex) {
    return callback(ex);
  }
};

export const _getSwapData = async (store, web3, account) => {
  const themeType = store.getStore("themeType");
  const buidlTokenAddress = store.getStore("tokenAddress");
  const swapData = store.getStore("swapData");

  const swapTokenContract = new web3.eth.Contract(
    swapData.swapTokenAbi,
    swapData.swapTokenAddress
  );
  const swapRouterContract = new web3.eth.Contract(
    swapData.swapRouterAbi,
    swapData.swapRouterAddress
  );
  const path = [swapData.swapTokenAddress, buidlTokenAddress];
  if (account && account.address) {
    const swapTokenBalance =
      swapData.swapTokenAddress ===
      fundingTokens[themeType].find((token) => token.label === "ETH").value
        ? await web3.eth.getBalance(account.address)
        : await swapTokenContract.methods.balanceOf(account.address).call();

    const amountsOut = await swapRouterContract.methods
      .getAmountsOut("1000000", path) // todo: change the decimals accordinly (6 and 18)
      .call();

    const swapPrice = amountsOut[1];
    const info = {
      ...swapData,
      swapTokenBalance: bigInt(swapTokenBalance),
      swapPrice: bigInt(swapPrice),
      swapTokenDecimals: fundingTokens[themeType].find(
        (token) => token.label === swapData.swapSymbol
      ).decimals,
    };

    return info;
  } else {
    const swapTokenBalance = bigInt();

    const amountsOut = await swapRouterContract.methods
      .getAmountsOut("1000000", path)
      .call();
    const swapPrice = amountsOut[1];
    const info = {
      ...swapData,
      swapTokenBalance: bigInt(swapTokenBalance),
      swapPrice: bigInt(swapPrice),
    };
    return info;
  }
};

export const _getPoolInfo = async (web3, pool, account, callback) => {
  let BSCstarterContract = new web3.eth.Contract(pool.abi, pool.address);
  try {
    const withdrawLimit = await BSCstarterContract.methods
      .withdrawalLimitPercent()
      .call({
        from: account.address,
      });
    const poolStructure = await BSCstarterContract.methods
      .poolInfo(pool.poolIndex)
      .call({
        from: account.address,
      });
    const balanceOfPool = await BSCstarterContract.methods
      .balanceOfPool(pool.poolIndex)
      .call({
        from: account.address,
      });
    callback(null, {
      withdrawLimit,
      balanceOfPool,
      ...poolStructure,
    });
  } catch (err) {
    console.log("_getPoolInfo", err);
    return callback(err);
  }
};

export const _callSwap = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  swapRouterContract,
  path,
  inputToken,
  outputToken,
  amount,
  amountOut,
  callback
) => {
  try {
    const themeType = store.getStore("themeType");
    const timestamp = parseInt(Date.now() / 1000);
    const gasPrice = await web3.eth.getGasPrice();
    if (
      web3.utils.toChecksumAddress(inputToken) ===
      web3.utils.toChecksumAddress(
        fundingTokens[themeType].find((token) => token.label === "ETH").value
      )
    ) {
      if (swapRouterContract.methods.swapExactETHForTokens) {
        swapRouterContract.methods
          .swapExactETHForTokens(
            amountOut,
            path,
            account.address,
            timestamp + 600
          )
          .send({
            from: account.address,
            value: amount,
            gasPrice,
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
            }
          })
          .on("receipt", function (receipt) {
            callback(null, receipt.transactionHash);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      } else if (swapRouterContract.methods.swapExactAVAXForTokens) {
        swapRouterContract.methods
          .swapExactAVAXForTokens(
            amountOut,
            path,
            account.address,
            timestamp + 600
          )
          .send({
            from: account.address,
            value: amount,
            gasPrice,
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
            }
          })
          .on("receipt", function (receipt) {
            callback(null, receipt.transactionHash);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      }
    } else if (
      web3.utils.toChecksumAddress(outputToken) ===
      web3.utils.toChecksumAddress(
        fundingTokens[themeType].find((token) => token.label === "ETH").value
      )
    ) {
      if (swapRouterContract.methods.swapExactTokensForETH) {
        swapRouterContract.methods
          .swapExactTokensForETH(
            amount,
            amountOut,
            path,
            account.address,
            timestamp + 600
          )
          .send({
            from: account.address,
            gasPrice,
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
            }
          })
          .on("receipt", function (receipt) {
            callback(null, receipt.transactionHash);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      } else if (swapRouterContract.methods.swapExactTokensForAVAX) {
        swapRouterContract.methods
          .swapExactTokensForAVAX(
            amount,
            amountOut,
            path,
            account.address,
            timestamp + 600
          )
          .send({
            from: account.address,
            gasPrice,
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
            }
          })
          .on("receipt", function (receipt) {
            callback(null, receipt.transactionHash);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      }
    } else {
      swapRouterContract.methods
        .swapExactTokensForTokens(
          amount,
          amountOut,
          path,
          account.address,
          timestamp + 600
        )
        .send({
          from: account.address,
          gasPrice,
        })
        .on("transactionHash", function (hash) {
          console.log(hash);
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 3) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          callback(null, receipt.transactionHash);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    }
  } catch (error) {
    console.error("_callSwap", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callClaimReward = async (
  store,
  dispatcher,
  emitter,
  poolIndex,
  account,
  callback
) => {
  const web3 = new Web3(store.getStore("web3context").library.provider);
  const vestingAbi = store.getStore("starterVestingAbi");
  const vestingAddress = store.getStore("starterVestingContract");
  const vestingContract = new web3.eth.Contract(vestingAbi, vestingAddress);

  if (vestingContract.methods.claimFee) {
    vestingContract.methods
      .claimFee(poolIndex)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_POOL_INFO,
            content: {
              poolIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        callback(null, receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  }
};

export const _callWithdraw = async (
  store,
  dispatcher,
  emitter,
  poolId,
  account,
  callback
) => {
  const web3 = new Web3(store.getStore("web3context").library.provider);
  const vestingAbi = store.getStore("starterVestingAbi");
  const vestingAddress = store.getStore("starterVestingContract");
  const vestingContract = new web3.eth.Contract(vestingAbi, vestingAddress);

  if (vestingContract.methods.withdrawTokens) {
    vestingContract.methods
      .withdrawTokens(poolId)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  }
};

export const _callLock = async (
  store,
  dispatcher,
  emitter,
  tokenAddress,
  amount,
  unlockTime,
  account,
  callback
) => {
  const web3 = new Web3(store.getStore("web3context").library.provider);
  const vestingAbi = store.getStore("starterVestingAbi");
  const vestingAddress = store.getStore("starterVestingContract");
  const vestingContract = new web3.eth.Contract(vestingAbi, vestingAddress);

  if (vestingContract.methods.lockTokens && account && account.address) {
    vestingContract.methods
      .lockTokens(tokenAddress, account.address, amount, unlockTime)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        callback(null, receipt);
      })
      .on("error", function (error) {
        console.log("on error:", error);
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        console.log("catch error:", error);
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  }
};

export const _callClaimHodler = async (
  store,
  dispatcher,
  emitter,
  account,
  callback
) => {
  const web3 = new Web3(store.getStore("web3context").library.provider);
  const factory = store.getStore("factory");

  const factoryContract = new web3.eth.Contract(
    factory.factoryV3Abi,
    factory.factoryV3Address
  );

  if (factoryContract.methods.claimHodlerFund) {
    factoryContract.methods
      .claimHodlerFund()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        callback(null, receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  }
};

export const _getHodlerReward = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  const starterInfoAbi = store.getStore("StarterInfoAbi");
  const starterInfoAddress = store.getStore("StarterInfoAddress");

  const factory = store.getStore("factory");

  const starterInfoContract = new web3.eth.Contract(
    starterInfoAbi,
    starterInfoAddress
  );

  const factoryBalance = await web3.eth.getBalance(factory.factoryV3Address);

  try {
    let lockedBalance = await starterInfoContract.methods
      .getLockedBalance(account.address)
      .call();
    let stakedAndLockedBalance = await starterInfoContract.methods
      .getBscsStakedAndLocked(account.address)
      .call();
    const info = {
      factoryBalance: bigInt(factoryBalance),
      lockedBalance: bigInt(lockedBalance),
      stakedAndLockedBalance: bigInt(stakedAndLockedBalance),
    };
    callback(null, info);
  } catch (ex) {
    return callback(ex);
  }
};

export const _getStakingPool = async (
  store,
  dispatcher,
  emitter,
  web3,
  callback
) => {
  const contractAddress = store.getStore("StakedContractAddress");

  try {
    const info = {
      address: contractAddress,
    };
    callback(null, info);
  } catch (ex) {
    return callback(ex);
  }
};

export const _getFactoryPool = async (
  store,
  dispatcher,
  emitter,
  web3,
  callback
) => {
  const factory = store.getStore("factory");

  const contractAbi = factory.factoryV3Abi;
  const contractAddress = factory.factoryV3Address;

  let factoryContract = new web3.eth.Contract(contractAbi, contractAddress);
  try {
    const stakingPool = await factoryContract.methods.bscsStakingPool().call();
    const starterInfo = await factoryContract.methods.BSCS().call();
    const owner = await factoryContract.methods.owner().call();
    const pendingOwner = await factoryContract.methods.pendingOwner().call();
    const info = {
      address: contractAddress,
      stakingPool,
      starterInfo,
      owner,
      pendingOwner,
    };
    callback(null, info);
  } catch (ex) {
    return callback(ex);
  }
};

export const _callFarmClaim = async (
  store,
  dispatcher,
  emitter,
  web3,
  farmingContract,
  account,
  callback
) => {
  try {
    farmingContract.methods
      .claim()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_FARMING_INFO,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("FARM_CLAIM ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _getBnbBalance = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  if (!account || !account.address) {
    callback(null, bigInt());
  } else {
    try {
      const balance = await web3.eth.getBalance(account.address);
      callback(null, bigInt(balance));
    } catch (er) {
      return callback(er);
    }
  }
};

export const _getVESTBalance = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  const tokenAbi = store.getStore("tokenAbi");
  const tokenAddress = store.getStore("tokenAddress");

  let walletContract = new web3.eth.Contract(tokenAbi, tokenAddress);
  if (!account || !account.address) {
    callback(null, {
      balance: bigInt(),
      totalStakedLocked: bigInt(),
    });
  } else {
    try {
      let balance = await walletContract.methods
        .balanceOf(account.address)
        .call({
          from: account.address,
        });

      const stakingContracts = [store.getStore("StakedContractAddress")];

      let totalStakedLocked = bigInt(0);
      for (let i = 0; i < stakingContracts.length; i++) {
        const amount = await walletContract.methods
          .balanceOf(stakingContracts[i])
          .call();
        totalStakedLocked += bigInt(amount);
      }
      callback(null, {
        balance: bigInt(balance),
        totalStakedLocked,
      });
    } catch (ex) {
      return callback(ex);
    }
  }
};

export const _getStakedBalance = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  const contractAbi = store.getStore("StakedContractAbi");
  const contractAddress = store.getStore("StakedContractAddress");

  let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);

  const vestingAbi = store.getStore("starterVestingAbi");
  const vestingAddress = store.getStore("starterVestingContract");

  let vestingContract = new web3.eth.Contract(vestingAbi, vestingAddress);

  let minStakeAmount = await vestingContract.methods.minStakeAmount().call();

  if (!account || !account.address) {
    const info = {
      balance: bigInt(),
      lastStakedTime: 0,
      lastUnstakedTime: 0,
      burnFee: 0,
      minStakeAmount: parseInt(minStakeAmount) || 10000,
    };
    callback(null, info);
  } else {
    try {
      let stakedInfo = await stakedContract.methods
        .accountInfos(account.address)
        .call({
          from: account.address,
        });

      let burnFee = await stakedContract.methods
        .getBurnFee(account.address)
        .call({
          from: account.address,
        });

      const info = {
        balance: bigInt(stakedInfo.balance),
        lastStakedTime: parseInt(stakedInfo.lastStakedTimestamp) || 0,
        lastUnstakedTime: parseInt(stakedInfo.lastUnstakedTimestamp) || 0,
        burnFee: parseInt(burnFee) / 100,
        minStakeAmount: parseInt(minStakeAmount) || 10000,
      };
      callback(null, info);
    } catch (ex) {
      console.log("getStakedBalance Error", ex);
      return callback(ex);
    }
  }
};

export const _callFarmWithdraw = async (
  store,
  dispatcher,
  emitter,
  web3,
  farmingContract,
  account,
  callback
) => {
  try {
    farmingContract.methods
      .withdraw()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_FARMING_INFO,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("FARM_WITHDRAW ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callFarmStake = async (
  store,
  dispatcher,
  emitter,
  web3,
  farmingContract,
  amount,
  account,
  callback
) => {
  try {
    farmingContract.methods
      .stake()
      .send({
        from: account.address,
        value: amount,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_FARMING_INFO,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("FARM_STAKE ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callApply = async (
  store,
  dispatcher,
  emitter,
  web3,
  factoryContract,
  account,
  bscPresaleInfo,
  presalePancakeSwapInfo,
  presaleStringInfo,
  callback
) => {
  try {
    factoryContract.methods
      .createPresale(bscPresaleInfo, presalePancakeSwapInfo, presaleStringInfo)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({
            type: GET_POOLS_SUMMARY,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt, receipt.events.PresaleCreated.returnValues.bscsId);
        callback(null, receipt.events.PresaleCreated.returnValues.bscsId);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.log("Apply error", error);
    callback(error);
  }
};

export const _checkApproval = async (
  store,
  dispatcher,
  emitter,
  abi,
  address,
  account,
  amount,
  contract
) => {
  try {
    const web3 = new Web3(store.getStore("web3context").library.provider);
    const erc20Contract = new web3.eth.Contract(abi, address);

    if (!erc20Contract.methods.allowance) {
      return true; // Doesn't need approval
    }
    const allowance = await erc20Contract.methods
      .allowance(account.address, contract)
      .call({
        from: account.address,
      });
    if (bigInt(allowance).lesser(amount)) {
      await erc20Contract.methods
        .approve(contract, web3.utils.toWei("999999999999999999", "ether"))
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        });
    }
    return true;
  } catch (error) {
    console.log(error);
    if (error.message) {
      return false;
    }
    return false;
  }
};

export const _getErc20Balance = async (
  store,
  dispatcher,
  emitter,
  abi,
  address,
  account
) => {
  if (!store.getStore("web3context")) {
    return bigInt();
  }
  try {
    const web3 = new Web3(store.getStore("web3context").library.provider);
    const erc20Contract = new web3.eth.Contract(abi, address);

    if (!erc20Contract.methods.balanceOf || !account) {
      return bigInt(); // Doesn't need approval
    }
    const balance = await erc20Contract.methods
      .balanceOf(account.address)
      .call({
        from: account.address,
      });
    return bigInt(balance);
  } catch (error) {
    console.log(error);
    return bigInt();
  }
};

export const _callVote = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  poolIndex,
  voteType,
  callback
) => {
  try {
    presaleContract.methods
      .vote(voteType)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_POOL_INFO,
            content: {
              poolIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("VOTE ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callUnstake = async (
  store,
  dispatcher,
  emitter,
  web3,
  stakingContract,
  amount,
  account,
  callback
) => {
  try {
    stakingContract.methods
      .unstake(amount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        callback(null, receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.log("_callUnstake error", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callUnstakeV1 = async (
  store,
  dispatcher,
  emitter,
  web3,
  stakingContract,
  amount,
  account,
  callback
) => {
  try {
    stakingContract.methods
      .unstake(amount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        callback(null, receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.log("_callUnstake error", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callMigrate = async (
  store,
  dispatcher,
  emitter,
  web3,
  stakingContract,
  amount,
  account,
  callback
) => {
  try {
    stakingContract.methods
      .migrateFromV2(amount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        callback(null, receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.log("_callStake error", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callStake = async (
  store,
  dispatcher,
  emitter,
  web3,
  stakingContract,
  amount,
  account,
  callback
) => {
  try {
    stakingContract.methods
      .stake(amount)
      // .migrateFromV2(amount)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 2) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        callback(null, receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.log("_callStake error", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callLockLiquidity = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  poolIndex,
  callback
) => {
  try {
    presaleContract.methods
      .addLiquidityAndLockLPTokens()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_POOL_INFO,
            content: {
              poolIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("LOCK_LIQUIDITY ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callGetRefund = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  poolIndex,
  callback
) => {
  try {
    presaleContract.methods
      .getRefund()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_POOL_INFO,
            content: {
              poolIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("GET_REFUND ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callInvest = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  poolIndex,
  presaleType,
  amount,
  callback
) => {
  try {
    presaleContract.methods
      .invest()
      .send({
        from: account.address,
        value: amount,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          if (presaleType === "private") {
            dispatcher.dispatch({
              type: GET_PRESALE_INFO,
              content: {
                poolIndex,
              },
            });
          } else {
            dispatcher.dispatch({
              type: GET_PUBLIC_POOL_INFO,
              content: {
                poolIndex,
              },
            });
          }
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("INVEST ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callAllowClaim = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  poolIndex,
  callback
) => {
  try {
    presaleContract.methods
      .allowClaim()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_POOL_INFO,
            content: {
              poolIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("ALLOW_CLAIM ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callSendUnSoldTokens = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  poolIndex,
  callback
) => {
  try {
    presaleContract.methods
      .sendUnsoldTokens()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_POOL_INFO,
            content: {
              poolIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("COLLECT_FUND ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callCancelPresale = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  poolIndex,
  callback
) => {
  try {
    presaleContract.methods
      .cancelAndTransferTokensToPresaleCreator()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_POOL_INFO,
            content: {
              poolIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("COLLECT_FUND ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callCollectFunds = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  poolIndex,
  callback
) => {
  try {
    presaleContract.methods
      .collectFundsRaised()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_POOL_INFO,
            content: {
              poolIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("COLLECT_FUND ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callWrite = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  poolIndex,
  method,
  args,
  callback
) => {
  try {
    presaleContract.methods[method](...args)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        console.log(confirmationNumber, receipt);
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          if (poolIndex > 0) {
            dispatcher.dispatch({
              type: GET_PUBLIC_POOL_INFO,
              content: {
                poolIndex,
              },
            });
          }
          callback(null, receipt);
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("WRITE ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _getPublicPoolSummary = async (
  store,
  dispatcher,
  emitter,
  web3,
  starterInfoContract,
  bscsId,
  callback
) => {
  try {
    const presaleAddress = await starterInfoContract.methods
      .getPresaleAddress(bscsId)
      .call();
    const presaleVersion = _getPresaleTypeByBscsId(bscsId);
    const presaleContract = new web3.eth.Contract(
      _getPresaleAbiByBscsId(store, bscsId),
      presaleAddress
    );
    const totalInvestorsCount = await presaleContract.methods
      .totalInvestorsCount()
      .call();
    const totalCollectedWei = await presaleContract.methods
      .totalCollectedWei()
      .call();

    const totalTokens = await presaleContract.methods.totalTokens().call();
    const tokenAddress = await presaleContract.methods.token().call();
    const tokenPriceInWei = await presaleContract.methods
      .tokenPriceInWei()
      .call();
    const hardCapInWei = await presaleContract.methods.hardCapInWei().call();
    const softCapInWei = await presaleContract.methods.softCapInWei().call();
    const maxInvestInWei = await presaleContract.methods
      .maxInvestInWei()
      .call();
    const minInvestInWei = await presaleContract.methods
      .minInvestInWei()
      .call();
    const openTime = await presaleContract.methods.openTime().call();
    const closeTime = await presaleContract.methods.closeTime().call();

    const saleTitle = web3.utils.hexToUtf8(
      await presaleContract.methods.saleTitle().call()
    );
    const linkTelegram = web3.utils.hexToUtf8(
      await presaleContract.methods.linkTelegram().call()
    );
    const linkWebsite = web3.utils.hexToUtf8(
      await presaleContract.methods.linkWebsite().call()
    );

    const _linkLogo = await presaleContract.methods.linkLogo().call();
    const linkLogo =
      presaleVersion === "V1" ? web3.utils.hexToUtf8(_linkLogo) : _linkLogo;

    const url = `/pool/${bscsId}`;
    const yesVotes = await presaleContract.methods.yesVotes().call();
    const noVotes = await presaleContract.methods.noVotes().call();
    const minYesVotesThreshold =
      presaleVersion === "V3"
        ? await starterInfoContract.methods.getMinYesVotesThreshold().call()
        : await presaleContract.methods.minYesVotesThreshold().call();

    const cakeListingPriceInWei = await presaleContract.methods
      .cakeListingPriceInWei()
      .call();

    const cakeLiquidityAddingTime = await presaleContract.methods
      .cakeLiquidityAddingTime()
      .call();

    const cakeLPTokensLockDurationInDays = await presaleContract.methods
      .cakeLPTokensLockDurationInDays()
      .call();

    const cakeLiquidityPercentageAllocation = await presaleContract.methods
      .cakeLiquidityPercentageAllocation()
      .call();

    const presaleCancelled = await presaleContract.methods
      .presaleCancelled()
      .call();
    const presaleType = presaleContract.methods.presaleType
      ? await presaleContract.methods.presaleType().call()
      : 1;
    const updatedPresaleInfo = {
      totalInvestorsCount,
      totalCollectedWei,
      totalTokens,
      tokenPriceInWei,
      tokenAddress,
      hardCapInWei,
      softCapInWei,
      maxInvestInWei,
      minInvestInWei,
      openTime,
      closeTime,
      saleTitle,
      linkTelegram,
      linkWebsite,
      linkLogo,
      yesVotes,
      noVotes,
      minYesVotesThreshold,
      url,
      poolIndex: bscsId,
      cakeListingPriceInWei,
      cakeLiquidityAddingTime,
      cakeLPTokensLockDurationInDays,
      cakeLiquidityPercentageAllocation,
      presaleCancelled,
      presaleVersion,
      presaleType,
    };

    return callback(null, updatedPresaleInfo);
  } catch (error) {
    console.error("_GET_PUBLIC_POOL_SUMMARY", error, bscsId);
    return callback(null, {});
  }
};

export const _getPoolSummary = async (
  store,
  dispatcher,
  emitter,
  web3,
  poolIndex,
  callback
) => {
  try {
    const presales = store.getStore("presales");
    const presale = presales[poolIndex];
    const presaleContract = new web3.eth.Contract(
      presale.presaleAbi,
      presale.presaleAddress
    );
    const totalInvestorsCount = await presaleContract.methods
      .totalInvestorsCount()
      .call();
    const totalCollectedWei = await presaleContract.methods
      .totalCollectedWei()
      .call();
    const totalTokens = await presaleContract.methods.totalTokens().call();

    const tokenPriceInWei = await presaleContract.methods
      .tokenPriceInWei()
      .call();
    const hardCapInWei = await presaleContract.methods.hardCapInWei().call();
    const softCapInWei = await presaleContract.methods.softCapInWei().call();
    const maxInvestInWei = await presaleContract.methods
      .maxInvestInWei()
      .call();
    const minInvestInWei = await presaleContract.methods
      .minInvestInWei()
      .call();
    const openTime = await presaleContract.methods.openTime().call();
    const closeTime = await presaleContract.methods.closeTime().call();

    const saleTitle = web3.utils.hexToUtf8(
      await presaleContract.methods.saleTitle().call()
    );
    const linkTelegram = web3.utils.hexToUtf8(
      await presaleContract.methods.linkTelegram().call()
    );
    const linkWebsite = web3.utils.hexToUtf8(
      await presaleContract.methods.linkWebsite().call()
    );
    const linkLogo =
      poolIndex !== "wsbprivate" &&
      poolIndex !== "wisepublic" &&
      poolIndex !== "vestSeedSale" &&
      poolIndex !== "vestPrivateSale"
        ? web3.utils.hexToUtf8(await presaleContract.methods.linkLogo().call())
        : await presaleContract.methods.linkLogo().call();

    const updatedPresaleInfo = {
      ...presale,
      totalInvestorsCount,
      totalCollectedWei,
      totalTokens,
      tokenPriceInWei,
      hardCapInWei,
      softCapInWei,
      maxInvestInWei,
      minInvestInWei,
      openTime,
      closeTime,
      saleTitle,
      linkTelegram,
      linkWebsite,
      linkLogo,
    };
    return callback(null, updatedPresaleInfo);
  } catch (error) {
    console.error("GET_POOL_SUMMARY", error);
    return callback(null, {});
  }
};

export const _getPresaleTypeByBscsId = (id) => {
  if (id >= 87) {
    return "V3";
  }
  if (id >= 26) {
    return "V2";
  }
  return "V1";
};

export const _getPresaleAbiByBscsId = (store, id) => {
  const factory = store.getStore("factory");
  if (id >= 87) {
    return factory.presaleV3Abi;
  }
  if (id >= 26) {
    return factory.presaleV2Abi;
  }
  return factory.presaleAbi;
};

export const _getPresaleAbiByVersion = (store, version) => {
  const factory = store.getStore("factory");
  if (version == "V3") {
    return factory.presaleV3Abi;
  }
  if (version == "V2") {
    return factory.presaleV2Abi;
  }
  return factory.presaleAbi;
};

export const _getFarmingInfo = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  const farming = store.getStore("farming");
  let farmingContract = new web3.eth.Contract(farming.abi, farming.address);
  let farmingLpContract = new web3.eth.Contract(
    farming.lpAbi,
    farming.lpAddress
  );
  let wbnbContract = new web3.eth.Contract(config.ERC20Abi, config.wbnbAddress);

  try {
    let halvingTimestamp = await farmingContract.methods
      .halvingTimestamp()
      .call();
    let rewardAllocation = await farmingContract.methods
      .rewardAllocation()
      .call();
    let totalLocked = await farmingContract.methods.totalSupply().call();
    let accountInfo = await farmingContract.methods
      .accountInfos(account && account.address)
      .call();
    let rewardEarned = await farmingContract.methods
      .rewardEarned(account && account.address)
      .call();
    let withdrawLimit = await farmingContract.methods.withdrawLimit().call();
    let withdrawCycle = await farmingContract.methods.withdrawCycle().call();
    let claimFee = await farmingContract.methods.claimTaxDenominator().call();

    let farmingStartTimestamp = await farmingContract.methods
      .farmingStartTimestamp()
      .call();

    let lpTotalSupply = await farmingLpContract.methods.totalSupply().call();

    let lpBnbBalance = await wbnbContract.methods
      .balanceOf(farming.lpAddress)
      .call();
    callback(null, {
      halvingTimestamp,
      rewardAllocation: bigInt(rewardAllocation),
      totalLocked: bigInt(totalLocked),
      accountInfo,
      lpTotalSupply: bigInt(lpTotalSupply),
      lpBnbBalance: bigInt(lpBnbBalance),
      rewardEarned: bigInt(rewardEarned),
      withdrawLimit,
      withdrawCycle,
      claimFee: 100 / claimFee,
      farmingStartTimestamp,
    });
  } catch (ex) {
    console.log("_getFarmingInfo", farming, ex);
    return callback(ex);
  }
};

export const _getStartInfo = async (
  store,
  dispatcher,
  emitter,
  web3,
  callback
) => {
  const tokenAbi = store.getStore("tokenAbi");
  const tokenAddress = store.getStore("tokenAddress");

  let tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
  try {
    let totalSupply = await tokenContract.methods.totalSupply().call();
    let totalBurned = await tokenContract.methods
      .balanceOf("0x000000000000000000000000000000000000dEaD")
      .call();
    callback(null, {
      totalSupply: bigInt(totalSupply),
      totalBurned: bigInt(totalBurned),
    });
  } catch (ex) {
    return callback(ex);
  }
};

export const _getGasPrice = async (store) => {
  try {
    return store.getStore("universalGasPrice");
  } catch (e) {
    console.log(e);
    return store.getStore("universalGasPrice");
  }
};
