import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withNamespaces } from "react-i18next";

const styles = (theme) => ({
  logoImageContainerSm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50px",
    marginRight: "10px",
  },
  logoImageSm: {
    width: "20px",
    height: "20px",
    background: "white",
    borderRadius: "50%",
  },
  logoImageContainerLg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    marginRight: "10px",
  },
  logoImageLg: {
    width: "50px",
    height: "50px",
    background: "white",
    borderRadius: "50%",
  },
});

const PresaleLogo = ({
  classes,
  type,
  src,
  size = "sm",
  alt,
  defaultImage,
  ...props
}) => {
  const [logoImage, setLogoImage] = useState(src);
  useEffect(() => {
    setLogoImage(src);
  }, [type, src]);

  if (type === 0) {
    return (
      <div
        className={
          size === "sm"
            ? classes.logoImageContainerSm
            : classes.logoImageContainerLg
        }
      >
        <img
          className={size === "sm" ? classes.logoImageSm : classes.logoImageLg}
          src={logoImage}
          alt={alt}
          onError={(er) => {
            setLogoImage(defaultImage);
          }}
          {...props}
        />
      </div>
    );
  } else {
    return (
      <div
        className={
          size === "sm"
            ? classes.logoImageContainerSm
            : classes.logoImageContainerLg
        }
      >
        <img
          className={size === "sm" ? classes.logoImageSm : classes.logoImageLg}
          src={logoImage?.left}
          alt={alt}
          onError={(er) => {
            setLogoImage(defaultImage);
          }}
          {...props}
        />
        <img
          className={size === "sm" ? classes.logoImageSm : classes.logoImageLg}
          src={logoImage?.right}
          alt={alt}
          onError={(er) => {
            setLogoImage(defaultImage);
          }}
          {...props}
        />
      </div>
    );
  }
};

export default withNamespaces()(withRouter(withStyles(styles)(PresaleLogo)));
