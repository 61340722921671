import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Button,
  Typography,
  DialogTitle,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    borderRadius: "34px !important",
    padding: "32px 48px 24px 48px",
    position: "relative",
  },
  paper: {
    background: theme?.colors?.swap?.background,
    borderRadius: "30px",
    boxShadow:
      "0px 10px 60px " + theme?.colors?.containerShadow + " !important",
  },
  modalRoot: {
    maxWidth: "640px",
    [theme.breakpoints.up("md")]: {
      borderRadius: "30px",
    },
  },
  closeButton: {
    position: "absolute",
    right: "32px",
    paddingTop: "0px",
  },
  closeIcon: {
    color: theme?.colors?.header?.closeButton,
    width: "32px",
    height: "32px",
  },
  screenMask: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  modalContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  walletButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
    },

    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    height: "40px",
    width: "130px",
    padding: "8px",

    marginTop: "16px",

    fontFamily: "Poppins",
    letterSpace: "0px",
    whiteSpace: "nowrap",
  },
  secondaryWalletButton: {
    borderRadius: "0.375rem",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackgroundSecondary,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "200px",
    padding: "8px 16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  cancelButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackground,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },

  modalCloseIcon: {
    width: "28px",
    height: "28px",
    position: "absolute",
    top: "25px",
    right: "25px",
    color: theme?.colors?.presale?.cardTitle,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  modalHeadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "8px 24px 0px",
  },
  modalHeadingLogoName: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    width: "100%",
  },
  modalHeadingLg: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "25px",
    letterSpacing: "0px",
    color: theme?.colors?.presale?.cardTitle,
    textTransform: "capitalize",
    fontWeight: 600,
  },
  modalSubHeader: {
    color: "#A0A0A0",
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "20px",
  },
  modalBalanceClaimContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
  },
  bnbBalanceHeader: {
    color: theme?.colors?.presale?.cardText,
    fontSize: "15px",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  bnbBalanceText: {
    color: theme?.colors?.header?.text,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    marginBottom: "30px",
  },
  vestBalanceText: {
    // color: theme?.colors?.header?.text,
    // fontSize: "20px",
    // fontWeight: "normal",
    // lineHeight: "24px",
    marginBottom: "16px",
    // display: "flex",
    // alignItems: "center",

    color: theme?.colors?.presale?.cardTitle,
    fontSize: "16px",
    fontWeight: "600",
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px",
    },
  },
  stakingAmountLine: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginBottom: "10px",
  },
  textInput: {
    background: "transparent",
    border: "2px solid" + theme?.colors?.createPool?.inputBorder,
    color: theme?.colors?.createPool?.inputLabel,

    width: "100%",
    borderRadius: "20px",
    padding: "16px",
  },
  stakingMaxButton: {
    position: "absolute",
    right: "16px",
    fontFamily: "Poppins",
    fontSize: "18px",
    letterSpacing: "0px",
    color: "#A0A0A0",
    cursor: "pointer",
    "&:hover": {
      color: theme?.colors?.presale?.cardTitle,
    },
  },
  dateText: {
    fontWeight: "normal",
    lineHeight: "24px",
    color: theme?.colors?.header?.balanceValue,
  },
  bnbBalanceTextSecondary: {
    color: theme?.colors?.header?.balanceValue,
  },
  earnedText: {
    color: theme?.colors?.header?.textSecondary,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    textAlign: "center",
    marginBottom: "16px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "297px",
    position: "relative",
    marginBottom: "30px",
  },
  actionInput: {
    border: "solid 1px " + theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    paddingRight: "70px",
    color: theme?.colors?.presale.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.presale.cardTitleSecondary,
  },
  maxButton: {
    position: "absolute",
    right: "0px",
    borderRadius: "0px 10px 10px 0px",
    height: "50px",
    backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    width: "70px",
    padding: "16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackground,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
  },
  divider: {
    width: "100%",
    border: "solid 1px " + theme?.colors?.divider,
    height: "0px",
  },
  stakeUnstakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "16px",
    marginTop: "24px",
    [theme.breakpoints.up("ms")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  stakeContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("ms")]: {
      marginBottom: "0px",
    },
  },
  unstakeContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  stakeUnstakeAvailableTip: {
    marginTop: "8px",
    marginBottom: "8px",
  },
  tipText: {
    color: theme?.colors?.header?.tag,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "20px",
    textAlign: "center",
  },
  badge: {
    marginLeft: "10px",
    width: "25px",
    height: "25px",
  },
});

const StakeModal = ({
  classes,
  account,
  closeModal,
  modalOpen,
  vestBalance = 0,
  stakedBalance = 0,
  onStake,
  onUnstake,
  burnFee = 50,
  minStakeAmount = 10000,
}) => {
  const [stakeValue, setStakeValue] = useState(0);
  const [unstakeValue, setUnstakeValue] = useState(0);

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      keepMounted
      maxWidth={"md"}
      TransitionComponent={Transition}
      classes={{ paper: classes.paper, paperWidthSm: classes.modalRoot }}
    >
      <DialogTitle disableTypography className={classes.modalHeader}>
        <div className={classes.modalHeadingContainer}>
          <div className={classes.modalHeadingLogoName}>
            <span className={classes.modalHeadingLg}>Your Wallet</span>
          </div>
        </div>
        <IconButton
          aria-label="close"
          className={classes.modalCloseIcon}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.root}>
        <div className={classes.modalContainer}>
          <Typography variant="h5" className={classes.modalSubHeader}>
            Stake a minimum of <b>{minStakeAmount}</b> <strong>BUIDL</strong>{" "}
            tokens to receive no-fee access to Locker to safely store your
            tokens during vesting periods or to lock your liquidity tokens.
            Depositing tokens into Locker without staking <strong>BUIDL</strong>{" "}
            will incur a small 0.5% tax on the deposit.
          </Typography>
          <div className={classes.stakeUnstakeContainer}>
            <div className={classes.stakeContainer}>
              <Typography variant="h4" className={classes.bnbBalanceHeader}>
                Liquid Balance
              </Typography>
              <Typography variant="h3" className={classes.vestBalanceText}>
                {vestBalance.toFixed(3)} BUIDL
              </Typography>
              <div className={classes.stakingAmountLine}>
                <input
                  className={classes.textInput}
                  label=""
                  type="text"
                  value={stakeValue}
                  onChange={(ev) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(ev.target.value)) {
                      setStakeValue(ev.target.value);
                    }
                  }}
                />

                <div
                  className={classes.stakingMaxButton}
                  onClick={() => {
                    setStakeValue(vestBalance.toString());
                  }}
                  aria-hidden="true"
                >
                  Max
                </div>
              </div>

              <div className={classes.buttonContainer}>
                <Button
                  classes={{
                    root: classes.walletButton,
                    label: classes.buttonLabel,
                  }}
                  onClick={(ev) => {
                    if (0 < stakeValue && vestBalance >= stakeValue) {
                      if (onStake) {
                        onStake(stakeValue);
                      }
                      closeModal();
                    } else {
                      setStakeValue(vestBalance.toString());
                    }
                  }}
                  disabled={vestBalance == 0}
                >
                  Stake
                </Button>
              </div>
            </div>
            <div className={classes.unstakeContainer}>
              <Typography variant="h4" className={classes.bnbBalanceHeader}>
                Staked Balance
              </Typography>
              <Typography variant="h3" className={classes.vestBalanceText}>
                {stakedBalance.toFixed(3)} BUIDL
              </Typography>

              <div className={classes.stakingAmountLine}>
                <input
                  className={classes.textInput}
                  label=""
                  type="text"
                  value={unstakeValue}
                  onChange={(ev) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(ev.target.value)) {
                      setUnstakeValue(ev.target.value);
                    }
                  }}
                />

                <div
                  className={classes.stakingMaxButton}
                  onClick={() => {
                    setUnstakeValue(stakedBalance.toString());
                  }}
                  aria-hidden="true"
                >
                  Max
                </div>
              </div>

              <Button
                classes={{
                  root: classes.walletButton,
                  label: classes.buttonLabel,
                }}
                onClick={(ev) => {
                  if (0 < unstakeValue && stakedBalance >= unstakeValue) {
                    if (onUnstake) {
                      onUnstake(unstakeValue);
                    }
                    closeModal();
                  } else {
                    setStakeValue(0);
                  }
                }}
                disabled={stakedBalance === 0}
              >
                Unstake
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.stakeUnstakeAvailableTip}>
          {burnFee > 1000 &&
            stakedBalance > 0 && ( // TODO
              <Typography variant="h4" className={classes.tipText}>
                *Unstaking will incur a burn penalty of&nbsp;
                <span className={classes.dateText}>{burnFee}</span>%
              </Typography>
            )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(StakeModal));
