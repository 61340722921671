import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import { useWeb3React } from "@web3-react/core";

import { CONNECTION_DISCONNECTED, CONNECTION_CONNECTED } from "../../constants";

import Store from "../../stores";
import { useAccount } from "../../contexts/AccountContext";
const emitter = Store.emitter;
const store = Store.store;

const styles = (theme) => ({
  root: {
    flex: 1,
    height: "auto",
    display: "flex",
    position: "relative",
  },
  contentContainer: {
    margin: "auto",
    textAlign: "center",
    display: "flex",
    width: "100%",
  },
  cardContainer: {
    marginTop: "60px",
    minHeight: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  unlockCard: {
    padding: "24px",
  },
  buttonText: {
    marginLeft: "12px",
    fontWeight: "normal",
  },
  instruction: {
    maxWidth: "400px",
    marginBottom: "32px",
    marginTop: "32px",
  },
  actionButton: {
    padding: "12px",
    backgroundColor: "white",
    borderRadius: "3rem",
    border: "1px solid #E1E1E1",
    fontWeight: "normal",
    [theme.breakpoints.up("md")]: {
      padding: "15px",
    },
  },
  connect: {
    width: "100%",
  },
  closeIcon: {
    position: "fixed",
    right: "12px",
    top: "12px",
    cursor: "pointer",
  },
  walletWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  walletItem: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "&+&": {
      paddingTop: "16px",
    },
  },
  walletButton: {
    backgroundColor: "transparent",
    borderRadius: "1rem",
    border: "1px solid" + theme?.colors?.wallet?.buttonBorder,
    fontWeight: "normal",
    display: "flex",
    justifyContent: "flex-start",
    padding: "22px",
    width: "475px",

    [theme.breakpoints.down("sm")]: {
      padding: "14px",
    },
  },
  connectWithText: {
    margin: "0px 4px 0px 16px",
    color: theme?.colors?.wallet?.text,
    fontWeight: "normal",
    fontSize: "20px",
    textTransform: "initial",

    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      margin: "0px 4px 0px 6px",
    },
  },
  walletButtonText: {
    margin: "0px",
    color: theme?.colors?.wallet?.text,
    fontWeight: "bold",
    fontSize: "20px",
    textTransform: "initial",

    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  walletIcon: {
    width: "30px",
    height: "30px",

    [theme.breakpoints.down("sm")]: {
      width: "20px",
      height: "20px",
    },
  },
  walletConnectedMark: {
    background: "#4caf50",
    borderRadius: "10px",
    width: "10px",
    height: "10px",
    marginLeft: "10px",
  },
  disconnectButton: {
    padding: "12px",
    backgroundColor: "white",
    borderRadius: "20px",
    border: "1px solid #E1E1E1",
    fontWeight: "normal",
    minWidth: "250px",
  },
  disconnectButtonText: {
    marginLeft: "12px",
    fontWeight: "lighter",
    fontSize: "1rem",
    color: "#DC6BE5",
    textTransform: "capitalize",
  },
});

const Unlock = ({ classes, closeModal }) => {
  const connectionConnected = useCallback(() => {
    if (closeModal) {
      closeModal();
    }
  }, [closeModal]);

  const connectionDisconnected = useCallback(() => {
    if (closeModal) {
      closeModal();
    }
  }, [closeModal]);

  React.useEffect(() => {
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    return () => {
      emitter.removeListener(CONNECTION_CONNECTED, connectionConnected);
      emitter.removeListener(CONNECTION_DISCONNECTED, connectionDisconnected);
    };
  }, [connectionConnected, connectionDisconnected]);

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <WalletContainer classes={classes} />
      </div>
    </div>
  );
};

const WalletContainer = ({ classes }) => {
  const context = useWeb3React();
  const theme = useTheme();
  const localContext = store.getStore("web3context");
  const localConnector = localContext ? localContext.connector : null;

  const connectorsByName = store.getStore("connectorsByName");

  const [activatingConnector, setActivatingConnector] = React.useState();

  const { connector, error, chainId } = context;
  const { connectWallet } = useAccount();

  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const getUrlByName = (name) => {
    if (name === "MetaMask") {
      return require("../../assets/icn-metamask.svg");
    } else if (name === "WalletConnect") {
      return require("../../assets/walletConnectIcon.svg");
    } else if (name === "TrustWallet") {
      return require("../../assets/trustWallet.png");
    } else if (name === "Portis") {
      return require("../../assets/portisIcon.png");
    } else if (name === "Fortmatic") {
      return require("../../assets/fortmaticIcon.png");
    } else if (name === "Ledger") {
      return require("../../assets/icn-ledger.svg");
    } else if (name === "Squarelink") {
      return require("../../assets/squarelink.png");
    } else if (name === "Trezor") {
      return require("../../assets/trezor.png");
    } else if (name === "Torus") {
      return require("../../assets/torus.jpg");
    } else if (name === "Authereum") {
      return require("../../assets/icn-aethereum.svg");
    } else if (name === "Coinbase") {
      return require("../../assets/coinbaseWalletIcon.svg");
    }
    return "";
  };

  return (
    <div className={classes.walletWrapper}>
      {Object.keys(connectorsByName).map((name) => {
        const currentConnector = connectorsByName[name];
        const activating = currentConnector === activatingConnector;
        const connected =
          (currentConnector === connector ||
            currentConnector === localConnector) &&
          chainId === theme.chainId;
        const disabled = !!activatingConnector || !!error;
        const url = getUrlByName(name);

        return (
          <div key={name} className={classes.walletItem}>
            <Button
              className={classes.walletButton}
              variant="outlined"
              color="primary"
              onClick={() => {
                setActivatingConnector(currentConnector);
                connectWallet(name);
              }}
              disabled={disabled}
            >
              <img className={classes.walletIcon} src={url} alt="logo" />
              <Typography className={classes.connectWithText} variant={"p"}>
                Connect with{" "}
              </Typography>
              <Typography className={classes.walletButtonText} variant={"h3"}>
                {name}
              </Typography>
              {activating && (
                <CircularProgress size={15} style={{ marginRight: "10px" }} />
              )}
              {!activating && connected && (
                <div className={classes.walletConnectedMark} />
              )}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(Unlock)));
