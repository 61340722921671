import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { withNamespaces } from "react-i18next";
import LogoImage from "./logoImage";
import { formatCurrency } from "../../../utils/formatCurrency";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: "30px",
    fontFamily: "Poppins",
    background: theme?.colors.poolCard?.backgroundColor,
    boxShadow: "0px 3px 20px " + theme?.colors?.containerShadow,

    padding: "28px",
  },
  launchIcon: {
    width: "20px",
    height: "20px",
    fill: "#fff",
  },
  titleName: {
    position: "absolute",
    top: "10px",
    left: "0.8rem",
    color: theme?.colors.poolCard?.titleSecondary,
    cursor: "pointer",
    fontSize: "1.2rem",
    fontWeight: "600",
    textShadow: "#474747 2px 2px 2px",
    "&:hover": {
      opacity: "0.8",
    },
  },
  topPoolType: {
    minWidth: "80px",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "24px",
    borderRadius: "16px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.colors.poolCard?.poolTypeText,
    whiteSpace: "nowrap",
  },

  poolHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "25px",
    position: "relative",
  },

  poolContent: {
    display: "flex",
    flexDirection: "column",
    padding: "24px",
  },
  poolBgImage: {
    zIndex: 3,
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0px",
    right: "0px",
    top: "0px",
    bottom: "0px",
    opacity: "0.3",
  },
  poolDefaultImage: {
    width: "100%",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    height: "300px",
    objectFit: "cover",
    boxShadow: "0px 3px 20px " + theme?.colors?.containerShadow,
  },
  avatarLogo: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "0.6rem",
  },
  poolTitle: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    fontSize: "20px",
    color: theme?.colors.poolCard?.textSecondary,
    cursor: "pointer",
    fontWeight: "600",
    width: "100%",
  },
  poolDescription: {
    fontWeight: "normal",
    fontSize: "16px",
    color: "#A0A0A0",
  },
  per1BNB: {
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors.poolCard?.title,
    marginBottom: "8px",
  },
  saleValue: {
    fontSize: "25px",
    fontWeight: "normal",
    lineHeight: "30px",
    letterSpacing: "0px",
    textAlign: "left",
    minHeight: "30px",
    color: theme?.colors.poolCard?.textSecondary,
    marginBottom: "15px",
  },
  saleStatusContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "25px",
  },
  salesStatusBar: {
    marginRight: "10px",
    width: "100%",
    height: "16px",
    borderRadius: "12px",
    backgroundColor: theme?.colors.poolCard?.statusBarBackground,
    marginBottom: "8px",
  },
  salesStatusSubBar: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  salesStatusBarFilled: {
    height: "16px",
    borderRadius: "12px",
  },
  salesStatusText: {
    fontSize: "15px",
    color: theme?.colors.poolCard?.statusBarLabel,
    whiteSpace: "nowrap",
    marginRight: "8px",
  },
  salesRequiredText: {
    fontSize: "15px",
    color: theme?.colors.poolCard?.statusBarLabel,
    marginBottom: "25px",
  },
  maximumConnectContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginRight: "20px",
  },
  maximumWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  connectWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  maximumConnectLabel: {
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors.poolCard?.title,
    marginBottom: "8px",
  },
  maximumValue: {
    fontSize: "20px",
    lineHeight: "24px",
    color: theme?.colors.poolCard?.textSecondary,
  },
  connectIconsWrapper: {
    display: "flex",
    "& > *": {
      width: "24px",
      height: "24px",
      color: theme?.colors.poolCard?.linkIcon,
      marginRight: "20px",
      "&:hover": {
        color: theme?.colors.poolCard?.linkIconHover,
      },
      cursor: "pointer",
    },
  },
  poolPercentageText: {
    color: theme?.colors?.poolCard?.statusBarLabel,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "30px",
    boxShadow: "0px 3px 20px " + theme?.colors?.containerShadow,
    justifyContent: "center",
    width: "100%",
    height: "100%",
    background: theme?.colors.poolCard?.backgroundColor,
    zIndex: 4,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: theme?.colors?.presale?.loadingIcon,
    position: "absolute",
    top: "100px",
    left: "50%",
  },
  badge: {
    marginLeft: "0.6rem",
    width: "30px",
    height: "30px",
  },

  stakingIncomeTableImgSymbol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    marginBottom: "22px",
    "&:hover": {
      opacity: 0.8,
    },
  },
  stakingIncomeTableTdImg: {
    width: "45px",

    marginRight: "10px",
    objectFit: "contain",
  },
  stakingIncomeTableTdText: {
    fontSize: "25px",
    fontWeight: "600",
    fontFamily: "Poppins",
    color: theme?.colors?.presale?.cardTitle,
  },
  stakingIncomeArrow: {
    width: "15px",
    height: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },

  poolDetailsIndex: {
    fontFamily: "Poppins",
    fontSize: "15px",
    letterSpacing: "0px",
    color: "#A0A0A0",
  },
  poolDetailsValue: {
    fontFamily: "Poppins",
    fontSize: "15px",
    letterSpacing: "0px",
    color: theme?.colors?.presale?.cardTitle,
  },
  buttonsContainer: {
    margin: "16px auto 0px",
  },

  poolDetailsInfoList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  poolDetailsLine: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  viewButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    minWidth: "150px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonTextSecondary,
    },
    "&:disabled": {
      opacity: "0.7",
      color: theme?.colors?.wallet?.buttonTextSecondary,
      cursor: "not-allowed",
    },
  },
});

const StakingIncomeMobileCard = ({
  classes,
  onView,

  tokenInfo,
  name,
  tokenPrice,
  lockedAmount,
  lockedValue,
  tokenLogo,
  remainingTime,
}) => {
  return (
    <div className={classes.root}>
      {!name && (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingIcon} size={60} />
        </div>
      )}

      <div className={classes.stakingIncomeTableImgSymbol}>
        <LogoImage
          type={tokenInfo.type}
          src={tokenLogo}
          size="lg"
          alt="."
          defaultImage={"pools/default.png"}
        />

        <span className={classes.stakingIncomeTableTdText}>{name}</span>
      </div>

      <div className={classes.poolDetailsInfoList}>
        <div className={classes.poolDetailsLine}>
          <div className={classes.poolDetailsIndex}>Price($)</div>
          <div className={classes.poolDetailsValue}>
            {formatCurrency(tokenPrice || 0, 0)}
          </div>
        </div>
        <div className={classes.poolDetailsLine}>
          <div className={classes.poolDetailsIndex}>Tokens Locked</div>
          <div className={classes.poolDetailsValue}>
            {lockedAmount.toFixed(2)}
          </div>
        </div>
        <div className={classes.poolDetailsLine}>
          <div className={classes.poolDetailsIndex}>Value Locked($)</div>
          <div className={classes.poolDetailsValue}>
            {formatCurrency(lockedValue || 0, 0)}
          </div>
        </div>
        <div className={classes.poolDetailsLine}>
          <div className={classes.poolDetailsIndex}>Next Unlock</div>
          <div className={classes.poolDetailsValue}>{remainingTime}</div>
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          classes={{
            root: classes.viewButton,
            label: classes.buttonLabel,
          }}
          onClick={onView}
        >
          View
        </Button>
      </div>
    </div>
  );
};

export default withNamespaces()(
  withRouter(withStyles(styles)(StakingIncomeMobileCard))
);
