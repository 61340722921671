import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  loader: {
    width: (props) => (props.width ? props.width : "94px"),
    height: (props) => (props.height ? props.height : "94px"),
    border: (props) =>
      props.strokeSize
        ? `${props.strokeSize} solid #cfcfcf`
        : "8px solid #cfcfcf",
    borderBottomColor: (props) =>
      props.color || theme?.colors?.wallet?.buttonBackground,
    borderRadius: "50%",
    display: "inline-block",
    boxSizing: "border-box",
    animation: "$rotation 1s linear infinite",
  },
  "@keyframes rotation": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

const Spinner = withStyles(styles)(
  ({ classes, width, height, color, strokeSize }) => (
    <div className={classes.loader} />
  )
);

export default withStyles(styles)(Spinner);
