export function formatCurrency(value, decimals = 3) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals > 2 ? decimals : 2,
  });

  let formatted = formatter.format(value);

  return formatted;
}
