import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button, Tooltip } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import { toFixed } from "../../../constants/constants";
import bigInt from "big-integer";

import { ReactComponent as LockIcon } from "../../../assets/lock-blue.svg";
import { ReactComponent as WithdrawIcon } from "../../../assets/withdraw.svg";

const styles = (theme) => ({
  poolLockWithdrawCard: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    boxShadow: "0px 10px 60px " + theme?.colors?.containerShadow,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "50px",
      borderRadius: "30px",
      padding: "32px",
    },
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  poolInformationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  lockWithdrawEventsList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  divideLine: {
    width: "100%",
    height: "0px",
    borderBottom: "2px solid " + theme?.colors?.presale?.divider,
  },
  lockLiqContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  yourTokenInfoItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "0px",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
      marginBottom: "0px",
      marginRight: "40px",
    },
  },
  infoHeading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "4px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8px",
    },
  },
  infoValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "25px",
    },
  },
  actionButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "170px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackground,
    },
    /*"&:disabled": {
      border: "solid 2px" + theme?.colors?.presale?.buttonBgDisabled,
      backgroundColor: theme?.colors?.presale?.buttonBgDisabled,
      cursor: "not-allowed",
    },*/
    fontFamily: "SFPro",
    letterSpacing: "0px",
  },
  buttonLabel: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  tokenTooltip: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  rewardsClaimContainer: {
    marginTop: "16px",
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  poolContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    margin: "16px 0px",

    flexDirection: "column",
    gap: "16px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      gap: "0px",
    },
  },
  poolLeftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  poolName: {
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    color: theme?.colors?.presale?.cardText,

    textTransform: "capitalize",
    marginRight: "auto",
  },
  lockWithdrawMark: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  lockedMark: {
    borderRadius: "24px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    lineHeight: "18px",
    backgroundColor: theme?.colors?.presale?.locked,
    color: theme?.colors?.presale?.cardText,
    fontWeight: "600",

    marginRight: "24px",
  },
  withdrawnMark: {
    borderRadius: "24px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    lineHeight: "18px",
    backgroundColor: theme?.colors?.presale?.locked,
    color: theme?.colors?.presale?.cardText,
    fontWeight: "600",

    marginRight: "24px",
  },
  unlockTime: {
    fontSize: "17px",
    lineHeight: "20px",
    color: theme?.colors?.presale?.cardText,
    fontWeight: "600",
  },
  poolMiddleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  remainingTime: {
    fontSize: "17px",
    lineHeight: "20px",
    color: theme?.colors?.presale?.cardText,
    fontWeight: "600",
  },
  poolRightContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  withdrawButton: {
    "&:disabled": {
      opacity: "0.7",
      color: theme?.colors?.wallet?.buttonTextSecondary,
      cursor: "not-allowed",
    },
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
    },
    fontFamily: "Poppins",

    width: "100%",

    padding: "12px",
    [theme.breakpoints.up("md")]: {
      padding: "16px",

      width: "200px",
    },
  },
});

const LockWithdrawCard = ({
  classes,
  poolInfo,
  account,
  onClaim,
  onWithdraw,
}) => {
  const [currentTimestamp, setCurrentTimestamp] = useState(Date.now() / 1000);

  const lockedPools = poolInfo?.pools?.filter((pool) => !pool?.withdrawn) || [];
  const withdrawnPools =
    poolInfo?.pools?.filter((pool) => pool?.withdrawn) || [];
  const pendingRewards =
    parseFloat(
      toFixed(bigInt(poolInfo?.pendingRewards), poolInfo?.decimals, 4)
    ) || 0;

  const getUnlockTime = (timestamp) => {
    const unlockTime = new Date(parseInt(timestamp) * 1000);
    return (
      unlockTime.toLocaleDateString() + " " + unlockTime.toLocaleTimeString()
    );
  };

  const getRemainingTime = (unlockTime) => {
    if (parseInt(unlockTime) <= currentTimestamp) return "expired";
    const timestamp = parseInt(unlockTime) - currentTimestamp;
    if (timestamp >= 3600 * 24) {
      return parseInt(timestamp / (3600 * 24)) + " days";
    }
    if (timestamp >= 3600) {
      return (
        parseInt(timestamp / 3600) +
        " hours " +
        parseInt((timestamp % 3600) / 60) +
        " minutes " +
        parseInt(timestamp % 60) +
        " seconds "
      );
    }
    if (timestamp >= 60) {
      return (
        parseInt((timestamp % 3600) / 60) +
        " minutes " +
        parseInt(timestamp % 60) +
        " seconds "
      );
    }
    return (timestamp % 60) + " seconds";
  };

  const withdraw = (pool) => {
    console.log("withdraw", pool);
    onWithdraw(pool?.poolId);
  };

  const renderPool = (pool) => {
    return (
      <>
        <Typography variant="h3" className={classes.poolName}>
          Locked Tokens -{" "}
          {toFixed(bigInt(pool?.lockedAmount), pool?.decimals, 2)}{" "}
          {pool?.symbol}
        </Typography>
        <div className={classes.poolContainer}>
          <div className={classes.poolLeftContainer}>
            <div className={classes.lockWithdrawMark}>
              {!pool?.withdrawn && (
                <div className={classes.lockedMark}>
                  <LockIcon style={{ marginRight: "5px" }} />
                  Locked
                </div>
              )}
              {pool?.withdrawn && (
                <div className={classes.withdrawnMark}>
                  <WithdrawIcon style={{ marginRight: "5px" }} />
                  Withdrawn
                </div>
              )}
              <Typography variant="h5" className={classes.unlockTime}>
                Unlock time: {getUnlockTime(pool?.unlockTime)}
              </Typography>
            </div>
          </div>
          <div className={classes.poolMiddleContainer}>
            {!pool.withdrawn && (
              <Typography variant="h5" className={classes.remainingTime}>
                Unlocks in {getRemainingTime(pool?.unlockTime)}
              </Typography>
            )}
          </div>
          <div className={classes.poolRightContainer}>
            <Button
              classes={{
                root: classes.withdrawButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                withdraw(pool);
              }}
              disabled={!account || pool?.withdrawAddress !== account.address}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={classes.poolLockWithdrawCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        Lock & Withdraw
      </Typography>
      <div className={classes.poolInformationContainer}>
        <div className={classes.lockWithdrawEventsList}>
          {lockedPools.length > 0 &&
            lockedPools.map((pool) => renderPool(pool))}
          {lockedPools.length > 0 && withdrawnPools.length > 0 && (
            <div className={classes.divideLine} />
          )}
          {withdrawnPools.length > 0 &&
            withdrawnPools.map((pool) => renderPool(pool))}
        </div>
        <div className={classes.rewardsClaimContainer}>
          <Typography className={classes.infoHeading}>
            Pending Rewards -{" "}
            <span className={classes.infoValue}>
              {pendingRewards} {poolInfo?.symbol}
            </span>
          </Typography>
          <Button
            classes={{
              root: classes.actionButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              console.log("Claim Rewards");
              onClaim(poolInfo?.tokenAddress);
            }}
            disabled={pendingRewards == 0}
          >
            Claim Rewards
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(
  withRouter(withStyles(styles)(LockWithdrawCard))
);
