import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Button,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import HeaderLink from "../components/headerLink";
import ConnectWalletButton from "../../connectWalletButton";
import ThemeSwitcher from "../components/ThemeSwitcher";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: theme?.colors?.stake?.background,
    minWidth: "100%",
    minHeight: "100%",
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px",
  },
  closeButton: {
    width: "30px",
    height: "30px",
    position: "absolute",
    right: "2rem",
    top: "18px",
    color: theme?.colors?.presale?.cardTitle,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  linkContainer: {
    marginTop: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "& > div": {
      marginTop: "10px",
    },
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  walletButton: {
    borderRadius: "20px",
    marginTop: "14px",

    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "2px solid " + theme?.colors?.wallet?.buttonBackground,
    textTransform: "capitalize",
    height: "40px",
    width: "160px",
    color: theme?.colors?.wallet?.buttonText,
    padding: "8px 16px",
    "&:hover": {
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonBackgroundHover,
    },
    fontFamily: "Poppins",
    letterSpace: "0px",
    whiteSpace: "nowrap",
  },

  createButton: {
    borderRadius: "20px",
    marginTop: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    padding: "16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
    },

    border: "2px solid " + theme?.colors?.wallet?.buttonBackground,
    height: "40px",
    width: "160px",

    fontFamily: "Poppins",
    letterSpace: "0px",
    whiteSpace: "nowrap",
  },
  applyButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "2px solid " + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "240px",
    padding: "8px 16px",
    marginBottom: "16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  balanceInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors?.header?.balanceText,
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
  },
  closeIcon: {
    color: theme?.colors?.header?.closeButton,
    width: "32px",
    height: "32px",
  },
});

const RedirectModal = ({
  classes,
  closeModal,
  modalOpen,
  account,
  balance,
  onConnect,
  setStakeModalOpen,
  setLockModalOpen,
}) => {
  const history = useHistory();
  const navUrl = history?.location?.pathname?.toLowerCase() || "";
  const fullScreen = window.innerWidth < 900;

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
    >
      <DialogContent classes={{ root: classes.root }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.linkContainer}>
          <HeaderLink
            screenType="MOBILE"
            text="All Locks"
            to="/home"
            onClick={() => {
              history.push("/home");
              closeModal();
            }}
            selected={navUrl.includes("home")}
          />

          <HeaderLink
            screenType="MOBILE"
            text="Launchpad"
            to="https://starter.xyz"
            externalLink={true}
          />

          {/* <Button
            classes={{
              root: classes.ctaButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              window.open("https://starter.xyz");
            }}
          >
            Launchpad
          </Button> */}
          {/* <div className={classes.actionContainer}>
            <Button
              classes={{
                root: classes.walletButton,
                label: classes.buttonLabel,
              }}
              onClick={(ev) => {
                if (onConnect) {
                  onConnect();
                }
              }}
            >
              {account && account.address
                ? FORMATE_ADDRESS(account.address)
                : "Connect"}
            </Button>
          </div> */}

          {/* <HeaderLink
            screenType="MOBILE"
            text="Staking"
            onClick={() => {
              setStakeModalOpen(true);
            }}
          /> */}

          <Button
            classes={{
              root: classes.walletButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              setStakeModalOpen(true);
              closeModal();
            }}
          >
            Staking
          </Button>

          <Button
            classes={{
              root: classes.createButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              setLockModalOpen(true);
              closeModal();
            }}
          >
            New Lock
          </Button>

          <ConnectWalletButton style={{ width: "160px", marginTop: "14px" }} />

          <ThemeSwitcher />
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(RedirectModal));
