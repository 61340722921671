const factoryV3Abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_bscsInfoAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_bscsToken",
        type: "address",
      },
      {
        internalType: "address",
        name: "_bscsStakingPool",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "_buyBackBurnAddress",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "LogOwnerShipTransferInitiated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "LogOwnerShipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "title",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bscsId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "creator",
        type: "address",
      },
    ],
    name: "PresaleCreated",
    type: "event",
  },
  {
    inputs: [],
    name: "BSCS",
    outputs: [
      {
        internalType: "contract BSCstarterInfoV3",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bscsStakingPool",
    outputs: [
      {
        internalType: "contract BSCstarterStakingV3",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bscsToken",
    outputs: [
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "buyBackBurnAddress",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "lastClaimedTimestamp",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingOwner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenAddress",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "tokenDecimals",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "unsoldTokensDumpAddress",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "whitelistedAddresses",
            type: "address[]",
          },
          {
            internalType: "uint256",
            name: "tokenPriceInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "hardCapInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "softCapInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxInvestInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "minInvestInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "openTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "closeTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "presaleType",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "guaranteedHours",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "releasePerCycle",
            type: "uint256",
          },
        ],
        internalType: "struct BSCstarterFactoryV3.PresaleInfo",
        name: "_info",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "listingPriceInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidityAddingTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lpTokensLockDurationInDays",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidityPercentageAllocation",
            type: "uint256",
          },
        ],
        internalType: "struct BSCstarterFactoryV3.PresalePancakeSwapInfo",
        name: "_cakeInfo",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "saleTitle",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "linkTelegram",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "linkGithub",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "linkTwitter",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "linkWebsite",
            type: "bytes32",
          },
          {
            internalType: "string",
            name: "linkLogo",
            type: "string",
          },
          {
            internalType: "string",
            name: "kycInformation",
            type: "string",
          },
          {
            internalType: "string",
            name: "description",
            type: "string",
          },
          {
            internalType: "string",
            name: "whitepaper",
            type: "string",
          },
          {
            internalType: "uint256",
            name: "categoryId",
            type: "uint256",
          },
        ],
        internalType: "struct BSCstarterFactoryV3.PresaleStringInfo",
        name: "_stringInfo",
        type: "tuple",
      },
    ],
    name: "createPresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "claimHodlerFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_newFactoryAddress",
        type: "address",
      },
    ],
    name: "migrate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export default factoryV3Abi;
