import React, { useCallback, useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Switch, HashRouter, Route, Redirect } from "react-router-dom";

import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import "./i18n";
import { baseThemeLight, baseThemeDark } from "./theme";

import TopSidebar from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home";
import PoolDetail from "./components/pool";

import {
  CONNECTION_CONNECTED,
  CONFIGURE,
  CONFIGURE_RETURNED,
  GET_BALANCES,
  GET_BALANCES_RETURNED,
  GET_POOLS_SUMMARY,
  GET_POOLS_SUMMARY_RETURNED,
  GET_VEST_STATS,
  GET_VEST_STATS_RETURNED,
  THEME_CHANGE_RETURNED,
  THEME_MODE_CHANGE_RETURNED,
  DEFAULT_DATA_REFRESH_INTERVAL,
  GET_SWAP_INFO,
} from "./constants";

import Store from "./stores";
import { usePreferedTheme } from "./hooks/usePreferedTheme";
import AccountProvider from "./contexts/AccountContext";

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

function App() {
  const themes = {
    base: {
      light: baseThemeLight,
      dark: baseThemeDark,
    },
    // pulse: {
    //   light: pulseThemeLight,
    //   dark: pulseThemeDark,
    // },
  };

  // const themes = {
  //   bsc: bscTheme,
  //   mtc: mtcTheme,
  //   pls: plsTheme,
  //   base: baseTheme,
  // };

  const { preferedThemeMode } = usePreferedTheme();

  const [defaultTheme, setDefaultTheme] = useState(
    localStorage.getItem("VEST_THEME_TYPE") || "base"
  );
  const [themeMode, setThemeMode] = useState(preferedThemeMode);

  const themeModeChanged = useCallback((newThemeMode) => {
    setThemeMode(newThemeMode);
  }, []);

  const themeChanged = useCallback(() => {
    setDefaultTheme(store?.getStore("themeType") || "base");
  }, []);

  const connectionConnected = useCallback(() => {
    console.log("ConnectionConnected", store.getStore("account"));
    dispatcher.dispatch({ type: CONFIGURE, content: {} });
  }, []);

  const configureReturned = useCallback(() => {
    console.log("CONFIGURE_RETURNED");
    dispatcher.dispatch({ type: GET_BALANCES, content: {} });
  }, []);

  useEffect(() => {
    setThemeMode(preferedThemeMode);

    emitter.on(CONFIGURE_RETURNED, () => {
      configureReturned();
    });

    emitter.on(CONNECTION_CONNECTED, () => {
      connectionConnected();
    });

    emitter.on(THEME_CHANGE_RETURNED, () => {
      themeChanged();
    });

    emitter.on(THEME_MODE_CHANGE_RETURNED, (e) => {
      themeModeChanged(e);
    });
    return () => {
      emitter.removeListener(CONFIGURE_RETURNED, () => {
        configureReturned();
      });

      emitter.removeListener(CONNECTION_CONNECTED, () => {
        connectionConnected();
      });
      emitter.removeListener(THEME_CHANGE_RETURNED, () => {
        themeChanged();
      });
      emitter.removeListener(THEME_MODE_CHANGE_RETURNED, (e) => {
        themeModeChanged(e);
      });
    };
  }, [
    themeChanged,
    themeModeChanged,
    connectionConnected,
    preferedThemeMode,
    configureReturned,
  ]);

  useEffect(() => {
    dispatcher.dispatch({ type: GET_BALANCES, content: {} });
    dispatcher.dispatch({ type: GET_POOLS_SUMMARY, content: {} });
    dispatcher.dispatch({ type: GET_VEST_STATS, content: {} });
    dispatcher.dispatch({ type: GET_SWAP_INFO, content: {} });

    const getSwapInfoTimeoutId = setInterval(() => {
      dispatcher.dispatch({ type: GET_SWAP_INFO, content: {} });
    }, DEFAULT_DATA_REFRESH_INTERVAL);
    const getBalancesTimeoutId = setInterval(() => {
      dispatcher.dispatch({ type: GET_BALANCES, content: {} });
    }, DEFAULT_DATA_REFRESH_INTERVAL);
    const getPoolsSummaryTimeoutId = setInterval(() => {
      dispatcher.dispatch({ type: GET_POOLS_SUMMARY, content: {} });
    }, DEFAULT_DATA_REFRESH_INTERVAL);
    const getVestStatsTimeoutId = setInterval(() => {
      dispatcher.dispatch({ type: GET_VEST_STATS, content: {} });
    }, DEFAULT_DATA_REFRESH_INTERVAL);

    return () => {
      clearInterval(getSwapInfoTimeoutId);
      clearInterval(getBalancesTimeoutId);
      clearInterval(getPoolsSummaryTimeoutId);
      clearInterval(getVestStatsTimeoutId);
    };
  }, []);

  const getLibrary = (provider) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
  };

  return (
    <HashRouter>
      <MuiThemeProvider theme={createMuiTheme(themes[defaultTheme][themeMode])}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <AccountProvider>
            <CssBaseline />
            <TopSidebar />
            <Switch>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/pool/:tokenAddress">
                <PoolDetail />
              </Route>
              <Redirect to="/home" />
            </Switch>
            <Footer />
          </AccountProvider>
        </Web3ReactProvider>
      </MuiThemeProvider>
    </HashRouter>
  );
}

export default App;
