/** MultilineChart.js */
import React from "react";
import { Line, Axis, Area } from "../components";
import useController from "./MultilineChart.controller";
import Spinner from "../../spinner";

const MultilineChart = ({ data = [], dimensions = {} }) => {
  const { width, height, margin = {} } = dimensions;
  const svgWidth = width + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;
  const controller = useController({ data, width, height });
  const { yTickFormat, xTickFormat, xScale, yScale, yScaleForAxis } =
    controller;

  if (!width || !height) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "7.5rem",
          alignItems: "flex-end",
        }}
      >
        <Spinner strokeSize="5px" width="50px" height="50px" />;
      </div>
    );
  }

  return (
    <svg width={svgWidth} height={svgHeight}>
      <g transform={`translate(${margin.left},${margin.top})`}>
        {/* <GridLine
          type="vertical"
          scale={xScale}
          ticks={5}
          size={height}
          transform={`translate(0, ${height})`}
        /> */}
        {data.map(({ name, items = [], color }) => (
          <Line
            key={name}
            data={items}
            xScale={xScale}
            yScale={yScale}
            color={color}
          />
        ))}
        <Area
          data={data[0].items}
          color={data[0].color}
          xScale={xScale}
          yScale={yScale}
        />
        <Axis
          type="right"
          scale={yScaleForAxis}
          transform={`translate(${width - 80}, 1)`}
          ticks={5}
          tickFormat={yTickFormat}
        />
        <Axis
          type="bottom"
          className="axisX"
          scale={xScale}
          transform={`translate(10, ${height - height / 8})`}
          ticks={5}
          tickFormat={xTickFormat}
        />
      </g>
    </svg>
  );
};

export default MultilineChart;
