import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { Fade, Snackbar } from "@material-ui/core";

import { withNamespaces } from "react-i18next";

import PoolMainInfoCard from "./components/mainInfoCard";
import Spinner from "../spinner";
import LockWithdrawCard from "./components/lockWithdrawCard";
import LockModal from "./components/modal";

import Web3 from "web3";

import Store from "../../stores/store";

import {
  PUBLIC_CLAIM,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  GET_PUBLIC_POOL_INFO,
  GET_PUBLIC_POOL_INFO_RETURNED,
  PUBLIC_WITHDRAW,
  GET_POOLS_SUMMARY_RETURNED,
  PUBLIC_LOCK,
  toFixed,
} from "../../constants/constants";

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    background: theme?.colors?.header?.bodyContainer,

    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
    backgroundImage: theme?.backgroundImage,
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "1140px",
  },

  warningText: {
    color: "#ededed",
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "62px",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // width: "100%",
    // height: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.9)",
    // zIndex: 4,
    // position: "absolute",
    // top: "0px",
    // left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: "white",
    position: "absolute",
    top: "100px",
    left: "50%",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PoolPage = ({ classes, location }) => {
  const history = useHistory();
  const { tokenAddress } = useParams();
  const [whitelisted, setWhitelisted] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [poolInfo, setPoolInfo] = useState({});
  const [account, setAccount] = useState(null);
  const [openLockModal, showLockModal] = useState(false);

  useEffect(() => {
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    emitter.on(GET_PUBLIC_POOL_INFO_RETURNED, getPoolInfoReturned);
    emitter.on(GET_POOLS_SUMMARY_RETURNED, getPoolsSummaryReturned);
    setPoolInfo(store.getStore("poolInfos")[tokenAddress] || {});
    window.scrollTo(0, 0);
    /*
    dispatcher.dispatch({
      type: GET_PUBLIC_POOL_INFO,
      content: { poolIndex: tokenAddress },
    });*/

    const account = store.getStore("account");
    if (account) {
      setAccount(account);
    }
    return () => {
      emitter.removeListener(
        GET_PUBLIC_POOL_INFO_RETURNED,
        getPoolInfoReturned
      );
    };
  }, []);

  const getPoolsSummaryReturned = () => {
    dispatcher.dispatch({
      type: GET_PUBLIC_POOL_INFO,
      content: { poolIndex: tokenAddress },
    });
  };

  const connectionConnected = () => {
    setAccount(store.getStore("account"));
    dispatcher.dispatch({
      type: GET_PUBLIC_POOL_INFO,
      content: { poolIndex: tokenAddress },
    });
  };

  const connectionDisconnected = () => {
    setAccount(null);
  };

  const getPoolInfoReturned = () => {
    setPoolInfo(store.getStore("poolInfos")[tokenAddress] || {});
    if (account) {
      window.setTimeout(() => {
        dispatcher.dispatch({
          type: GET_PUBLIC_POOL_INFO,
          content: { poolIndex: tokenAddress },
        });
      }, 5000);
    }
  };

  const onClaim = () => {
    dispatcher.dispatch({
      type: PUBLIC_CLAIM,
      content: {
        poolIndex: tokenAddress,
      },
    });
  };

  const onWithdraw = (poolId) => {
    dispatcher.dispatch({
      type: PUBLIC_WITHDRAW,
      content: {
        poolId,
      },
    });
  };

  const onLock = (inputAmount, unlockDate) => {
    console.log("onLock", inputAmount, unlockDate);
    dispatcher.dispatch({
      type: PUBLIC_LOCK,
      content: {
        tokenAddress: tokenAddress,
        amount: Web3.utils.toWei(inputAmount),
        unlockTime: unlockDate,
      },
    });
  };
  return (
    <div className={classes.root}>
      {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={3000}
          onClick={() => {
            setErrorMessage(false);
          }}
          onClose={() => {
            setErrorMessage(false);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      )}
      <Fade in={true} timeout={{ enter: 1000 }}>
        <>
          {!poolInfo.tokenAddress ? (
            <div className={classes.mainContainer}>
              <div className={classes.loadingContainer}>
                <Spinner strokeSize="5px" width="60px" height="60px" />
                {/* <CircularProgress className={classes.loadingIcon} size={60} /> */}
              </div>
            </div>
          ) : (
            <div className={classes.mainContainer}>
              <PoolMainInfoCard
                poolInfo={poolInfo}
                onNewLock={() => {
                  showLockModal(true);
                }}
              />
              <LockWithdrawCard
                poolInfo={poolInfo}
                onClaim={onClaim}
                onWithdraw={onWithdraw}
                account={account}
              />
            </div>
          )}

          {openLockModal && (
            <LockModal
              poolInfo={poolInfo}
              onLock={onLock}
              modalOpen={openLockModal}
              closeModal={() => {
                showLockModal(false);
              }}
            />
          )}
        </>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(PoolPage)));
