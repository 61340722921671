import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import bigInt from "big-integer";

import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";

import { withNamespaces } from "react-i18next";
import { ReactComponent as UnlockSvg } from "../../../assets/unlocked.svg";
import { toFixed, getTokenLogo } from "../../../constants/constants";

import LogoImage from "../../pool/components/logoImage";

import MarketChart from "../../chart";

const styles = (theme) => ({
  poolInfoCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    boxShadow: "0px 10px 60px " + theme?.colors?.containerShadow,
    borderRadius: "30px",
    padding: "30px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "50px",
    },
  },
  poolMainInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: "25px",
    gap: "16px",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      marginBottom: "45px",
      gap: "0px",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  poolTitleAddressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "16px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      gap: "0px",
    },
  },
  poolImage: {
    width: "90px",
    height: "90px",
    marginRight: "15px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "35px",
    },
  },
  poolTitleAddress: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "24px",
    marginBottom: "5px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "30px",
      lineHeight: "36px",
      marginBottom: "10px",
    },
    position: "relative",
  },
  poolDescription: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },

  poolTokenLabel: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: 600,

    lineHeight: "18px",
    marginBottom: "8px",
  },
  poolTokenAddress: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "12px",
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
    },
  },
  tokenLinkContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  copyIcon: {
    width: "16px",
    height: "16px",
    cursor: "pointer",

    color: theme?.colors?.presale?.addressValueText,
  },
  poolStatusContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  unlockSvg: {
    "& .a": {
      fill: "var(--svg-fill-color, " + theme?.colors?.presale?.cardText + ")",
    },
  },
  poolStatusBox: {
    minWidth: "140px",
    width: "fit-content",
    paddingLeft: "8px",
    paddingRight: "8px",
    height: "32px",
    borderRadius: "24px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.colors.poolCard?.poolTypeText,
    whiteSpace: "nowrap",
  },
  poolListingInfo: {
    marginTop: "25px",
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  poolStatusInfo: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
    },
  },
  poolSaleInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginBottom: "20px",
    },
  },
  poolSaleText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  poolSaleHeader: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  poolSaleValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "25px",
    fontWeight: "normal",
    lineHeight: "30px",
  },
  poolParticipantsInfo: {
    display: "flex",
    alignItems: "flex-end",
  },
  poolParticipantText: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "25px",
    fontWeight: "bold",
    lineHeight: "30px",
  },
  poolPercentageBox: {
    display: "flex",
    height: "12px",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "12px",
    marginBottom: "4px",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      height: "16px",
      marginBottom: "8px",
    },
  },
  poolFilledBox: {
    height: "12px",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.addressValueText,
    borderRadius: "12px",
    position: "absolute",
    left: "0px",
    top: "0px",
    [theme.breakpoints.up("sm")]: {
      height: "16px",
    },
  },
  poolPercentageInfo: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  poolPercentageText: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  poolSubInfoSecondaryContainer: {
    display: "grid",
    width: "100%",
    gridGap: "15px",
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      gridGap: "20px",
    },
    [theme.breakpoints.up("ms")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: "25px",
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridGap: "30px",
    },
  },
  poolSubInfoContainer: {
    display: "flex",
    width: "100%",
    gap: "24px",
    marginBottom: "24px",
  },

  poolGraphContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "374px",
    borderRadius: "30px",
    border: "solid 2px " + theme?.colors?.pools?.border,
    [theme.breakpoints.up("md")]: {
      height: "374px",
    },
  },
  poolSubInfoCard: {
    display: "flex",
    flexDirection: "column",
  },
  poolSubInfoHeading: {
    color: theme?.colors?.presale?.cardText,

    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "4px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8px",
    },
  },
  poolSubInfoValue: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "16px",
    fontWeight: "600",
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px",
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
  },
  applyButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "100%",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackground,
      color: theme?.colors?.wallet?.buttonText,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionButton: {
    "&:disabled": {
      opacity: "0.7",
      color: theme?.colors?.wallet?.buttonTextSecondary,
      cursor: "not-allowed",
    },
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
    },
    fontFamily: "Poppins",

    width: "100%",
    marginTop: "12px",

    padding: "12px",
    [theme.breakpoints.up("md")]: {
      marginTop: "15px",
      padding: "16px",
    },
  },
  buttonLabel: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
  },
  graphTopBar: {
    position: "absolute",
    top: "30px",
    left: "30px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "calc(100% - 60px)",
    zIndex: 2,
  },
  graphTopLeftBar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  graphTopRightBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  graphTopText: {
    color: theme?.colors?.presale?.cardTitle,
    fontWeight: "600",
    fontSize: "25px",
    lineHeight: "30px",
    marginBottom: "5px",
  },
  graphTopSubText: {
    color: theme?.colors?.presale?.commentText,
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "20px",
    marginBottom: "5px",
  },
  rangeButton: {
    borderRadius: "8px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    color: "#A0A0A0",
    textTransform: "capitalize",
    height: "25px",
    width: "35px",
    minWidth: "35px",
    padding: "6px 0px",
    "&:hover": {
      backgroundColor: theme?.colors?.presale?.graphButtonHover,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "5px",
  },
  rangeButtonSelected: {
    borderRadius: "8px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.presale?.graphButton,
    color: theme?.colors?.presale?.cardTitle,
    fontWeight: "bold",

    "&:hover": {
      backgroundColor: theme?.colors?.presale?.graphButton,
    },
    textTransform: "capitalize",
    height: "25px",
    width: "35px",
    minWidth: "35px",
    padding: "6px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "5px",
  },
});
const MainInfoCard = ({ classes, poolInfo, onNewLock }) => {
  const graphRef = useRef();

  useEffect(() => {
    const interval = setInterval(() => {}, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [poolInfo]);
  return (
    <div className={classes.poolInfoCard}>
      <div className={classes.poolMainInfo}>
        <div className={classes.poolTitleAddressContainer}>
          <LogoImage
            type={poolInfo?.type}
            src={getTokenLogo(poolInfo)}
            alt="."
            defaultImage={"pools/default.png"}
          />
          <div className={classes.poolTitleAddress}>
            <Typography variant="h1" className={classes.poolTitle}>
              {poolInfo.name || "..."}
            </Typography>
            <Typography variant="h5" className={classes.poolTokenLabel}>
              Token Address
            </Typography>
            <div className={classes.tokenLinkContainer}>
              <a
                className={classes.link}
                href={"https://basescan.org/address/" + poolInfo.tokenAddress}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography variant="h5" className={classes.poolTokenAddress}>
                  {poolInfo.tokenAddress}
                </Typography>
              </a>
              <FileCopyOutlined
                onClick={() => {
                  navigator.clipboard.writeText(poolInfo.tokenAddress);
                }}
                className={classes.copyIcon}
              />
            </div>
          </div>
        </div>
        <div className={classes.poolStatusContainer}>
          <UnlockSvg
            className={classes.unlockSvg}
            style={{ width: "35px", height: "35px", fill: "#000000" }}
          />

          <Button
            classes={{
              root: classes.actionButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              onNewLock();
            }}
          >
            Lock More {poolInfo?.symbol}
          </Button>
        </div>
      </div>

      <div className={classes.poolSubInfoContainer}>
        <div className={classes.poolSubInfoCard}>
          <Typography variant="h5" className={classes.poolSubInfoHeading}>
            Token Locked
          </Typography>
          <Typography variant="h5" className={classes.poolSubInfoValue}>
            {poolInfo?.locked || 0} {poolInfo?.symbol}
          </Typography>
        </div>
        <div className={classes.poolSubInfoCard}>
          <Typography variant="h5" className={classes.poolSubInfoHeading}>
            Total Supply
          </Typography>
          <Typography variant="h5" className={classes.poolSubInfoValue}>
            {toFixed(bigInt(poolInfo?.totalSupply || 0), poolInfo?.decimals, 4)}{" "}
            {poolInfo?.symbol}
          </Typography>
        </div>
        <div className={classes.poolSubInfoCard}>
          <Typography variant="h5" className={classes.poolSubInfoHeading}>
            Price
          </Typography>
          <Typography variant="h5" className={classes.poolSubInfoValue}>
            {poolInfo?.type === 0 && poolInfo?.price + "$"}
            {poolInfo?.type === 1 && (
              <>
                <Typography variant="h5" className={classes.poolSubInfoValue}>
                  {poolInfo?.token0Info?.symbol?.toUpperCase()} -{" "}
                  {poolInfo?.token0Info?.price}$
                </Typography>
                <Typography variant="h5" className={classes.poolSubInfoValue}>
                  {poolInfo?.token1Info?.symbol?.toUpperCase()} -{" "}
                  {poolInfo?.token1Info?.price}$
                </Typography>
              </>
            )}
          </Typography>
        </div>
      </div>
      <div className={classes.poolGraphContainer} ref={graphRef}>
        {poolInfo?.marketChart && (
          <div className={classes.graphTopBar}>
            <div className={classes.graphTopLeftBar}>
              <Typography variant="h2" className={classes.graphTopText}>
                Locked Value
              </Typography>
              <Typography variant="h2" className={classes.graphTopText}>
                $
                {poolInfo?.type === 1
                  ? "..."
                  : parseFloat(
                      poolInfo?.locked * poolInfo?.price
                    ).toLocaleString() || 0}
              </Typography>
              <Typography variant="h2" className={classes.graphTopSubText}>
                {parseFloat(poolInfo?.locked?.toFixed(2)).toLocaleString() || 0}{" "}
                {poolInfo?.symbol}
              </Typography>
            </div>
          </div>
        )}
        {poolInfo?.prices.length > 0 && (
          <MarketChart
            marketData={poolInfo || []}
            width={graphRef?.current?.offsetWidth}
            height={graphRef?.current?.offsetHeight}
          />
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(MainInfoCard)));
