import bscConfig from "./bsc/production.config";
import mtcConfig from "./polygon/production.config";
import plsConfig from "./pulsechain/production.config";
import baseConfig from "./base/production.config";

const config = {
  bsc: bscConfig,
  mtc: mtcConfig,
  pls: plsConfig,
  base: baseConfig,
};

export default config;
