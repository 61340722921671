import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

const styles = (theme) => ({
  totalProjectsCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: theme?.colors.poolCard?.backgroundColor,
    boxShadow: "0px 3px 20px " + theme?.colors?.containerShadow,
    borderRadius: "30px",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "60px",
    },
  },
  totalProjectsCardTitle: {
    color: theme?.colors?.home?.text,

    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },

  totalProjectsCardLogo: {
    width: "62px",
    height: "62px",
    marginBottom: "30px",
  },

  text: {
    color: theme?.colors?.home?.textSecondary,

    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
});

const TotalProjectsCard = ({ classes, value }) => {
  const theme = useTheme();
  return (
    <div className={classes.totalProjectsCard}>
      <Typography variant="h1" className={classes.totalProjectsCardTitle}>
        Total Locks
      </Typography>
      <img
        className={classes.totalProjectsCardLogo}
        src={theme?.idoImage || require("../../../assets/timelock.png")}
        alt="."
      />
      <Typography variant="h1" className={classes.text}>
        {value.toLocaleString()}
      </Typography>
    </div>
  );
};

export default withNamespaces()(
  withRouter(withStyles(styles)(TotalProjectsCard))
);
