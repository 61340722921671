import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withNamespaces } from "react-i18next";

const styles = (theme) => ({
  logoImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    marginRight: "10px",
  },
  logoImage: {
    width: "90px",
    height: "90px",
    marginRight: "15px",
    borderRadius: "50px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "35px",
    },
  },
  logoImageLeft: {
    width: "65px",
    height: "65px",
    marginLeft: "35px",
    borderRadius: "50px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "5px",
    },
  },
  logoImageRight: {
    width: "65px",
    height: "65px",
    marginRight: "10px",
    borderRadius: "50px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "35px",
    },
  },
});

const PoolLogo = ({ classes, type, src, alt, defaultImage, ...props }) => {
  const [logoImage, setLogoImage] = useState(src);
  useEffect(() => {
    setLogoImage(src);
  }, [type, src]);

  if (type === 0) {
    return (
      <div className={classes.logoImageContainer}>
        <img
          className={classes.logoImage}
          src={logoImage}
          alt={alt}
          onError={(er) => {
            setLogoImage(defaultImage);
          }}
          {...props}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.logoImageContainer}>
        <img
          className={classes.logoImageLeft}
          src={logoImage?.left}
          alt={alt}
          onError={(er) => {
            setLogoImage(defaultImage);
          }}
          {...props}
        />
        <img
          className={classes.logoImageRight}
          src={logoImage?.right}
          alt={alt}
          onError={(er) => {
            setLogoImage(defaultImage);
          }}
          {...props}
        />
      </div>
    );
  }
};

export default withNamespaces()(withRouter(withStyles(styles)(PoolLogo)));
