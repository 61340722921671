import { useEffect, useState } from "react";

export const usePreferedTheme = () => {
  const prefersDarkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  // Set the initial theme based on the user's preference
  // If prefersDarkMode is true, set isLightTheme to false, and vice versa
  const [isLightTheme, setIsLightTheme] = useState(
    localStorage.getItem("THEME_MODE") === "light"
      ? localStorage.getItem("THEME_MODE") === "light"
      : !prefersDarkMode
  );

  // Add a useEffect hook to listen for changes in the preference
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => {
      setIsLightTheme(!e.matches);
    };

    mediaQuery.addEventListener("change", handleChange);

    // Clean up listener on component unmount
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  return { preferedThemeMode: isLightTheme ? "light" : "dark" };
};
