import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import UnlockModal from "../unlock/unlockModal";

import { FORMATE_ADDRESS } from "../../constants/constants";
import { useAccount } from "../../contexts/AccountContext";

const styles = (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    // borderBottom: "solid 2px " + theme?.colors?.header?.border,
    [theme.breakpoints.up("md")]: {
      maxWidth: "100vw",
      overflow: "hidden",
    },
  },

  bodyContainer: {
    width: "100%",
    minHeight: "calc(100vh - 90px)",
    height: "auto",
    background: theme?.colors?.header?.bodyContainer,
    // backgroundImage: theme?.backgroundImage,
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      overflow: "auto",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  walletContainer: {
    height: "50px",
    maxWidth: "1280px",
    display: "none",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gap: "0.7rem",
    },
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "0px",
  },

  actionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
  },
  logoText: {
    color: theme?.colors?.header?.text,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  logoBoldText: {
    color: theme?.colors?.header?.boldBrownText,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  walletButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    textTransform: "capitalize",
    height: "40px",
    color: theme?.colors?.wallet?.buttonText,
    "&:hover": {
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonBackgroundHover,
    },
    fontFamily: "Poppins",
    letterSpace: "0px",

    width: "130px",
    padding: "8px",
    whiteSpace: "nowrap",
  },
  createButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
    },

    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    height: "40px",
    width: "130px",
    padding: "8px",

    fontFamily: "Poppins",
    letterSpace: "0px",
    whiteSpace: "nowrap",
  },
  buyTokenButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonTextSecondary,
    },
  },
  applyButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "240px",
    padding: "8px 16px",
    marginBottom: "16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  certifiedButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    textTransform: "capitalize",
    height: "40px",
    width: "130px",
    color: theme?.colors?.wallet?.buttonText,
    padding: "8px",
    "&:hover": {
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonBackgroundHover,
    },
    fontFamily: "Poppins",
    letterSpace: "0px",
    whiteSpace: "nowrap",
  },
  footerOplsContainer: {
    display: "flex",
    alignItems: "center",
  },
  oplsBalance: {
    color: theme?.colors?.footer?.tokenBalance,
    fontSize: "16px",
    margin: "0 32px 0 8px",
  },

  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  menuButton: {
    padding: "6px",
  },
  menuIcon: {
    width: "30px",
    height: "30px",
    color: theme?.colors?.header?.menuButton,
  },
  balanceInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors?.header?.balanceText,
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
    cursor: "pointer",
  },
  footerLinks: {
    flex: 1,
    display: "flex",
    width: "100%",

    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  footerLinksText: {
    lineHeight: "20px",
    // color: "#fff",
    color: theme?.colors?.footer?.brandName,
    fontWeight: "bold",
    letterSpacing: "0px",
    margin: "0 0 6px 0",
    textAlign: "center",
    fontSize: "16px",
    marginBottom: "6px",

    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginBottom: "0px",
      fontSize: "12px",
    },
  },
  footerLinksContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",

    [theme.breakpoints.up("md")]: {
      marginLeft: "38px",
      marginTop: "0px",
    },
  },
  footerRight: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: {
    color: theme?.colors.poolCard?.linkIcon,
  },
  link: {
    padding: "0 5px 0 5px",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  certifiedBadge: {
    width: "30px",
    height: "30px",
    position: "absolute",
    left: "3px",
    top: "3px",
  },
  starterLtdText: {
    color: "#A0A0A0",
    fontSize: "10px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
});

function ConnectWalletButton({ classes, style }) {
  const [accountModalOpen, setAccountModalOpen] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);

  const { account, disconnectWallet } = useAccount();

  return (
    <>
      <Button
        style={style}
        classes={{
          root: classes.walletButton,
          label: classes.buttonLabel,
        }}
        onClick={() => {
          if (account && account.address) {
            // disconnect
            disconnectWallet();
            return;
          }

          setAccountModalOpen(true);
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {account && account.address
          ? hovering
            ? "Disconnect"
            : FORMATE_ADDRESS(account.address)
          : "Connect"}
      </Button>

      <UnlockModal
        closeModal={() => {
          setAccountModalOpen(false);
        }}
        modalOpen={accountModalOpen}
      />
    </>
  );
}

export default withRouter(withStyles(styles)(ConnectWalletButton));
