import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Button,
  Typography,
  Input,
  useTheme,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ConnectWalletButton from "../connectWalletButton";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: theme?.colors?.swap?.background,
    boxShadow: "0px 10px 60px " + theme?.colors?.containerShadow,
    minWidth: "100%",
    minHeight: "100%",
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "32px !important",
  },
  paper: {
    background: theme?.colors?.swap?.background,
    borderRadius: "30px",
    boxShadow:
      "0px 10px 60px " + theme?.colors?.containerShadow + " !important",
  },
  modalRoot: {
    maxWidth: "640px",
    [theme.breakpoints.up("md")]: {
      borderRadius: "30px",
    },
  },
  closeButton: {
    position: "absolute",
    padding: "0px !important",
    top: "8px",
    right: "8px",
  },
  swapButton: {
    borderRadius: "0.375rem",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "100%",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonTextSecondary,
    },
  },
  swapButtonDisabled: {
    opacity: "0.7",
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  closeIcon: {
    color: theme?.colors?.header?.closeButton,
    width: "32px",
    height: "32px",
    fill: theme?.colors?.modal?.title,
  },
  swapContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  swapCurrencyInput: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    borderRadius: "20px",
    border: "1px solid " + theme?.colors?.swap?.border,
  },
  swapCurrencyOutput: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    borderRadius: "20px",
    border: "1px solid " + theme?.colors?.swap?.border,
  },
  swapCurrencyInfo: {
    display: "flex",
    flexFlow: "row nowrap",
    "-webkit-box-align": "center",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color: theme?.colors?.swap?.header,
    fontSize: "0.75rem",
    padding: "0.75rem 1rem 0px",
  },
  swapTokenAmount: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    padding: "0.75rem 0.75rem 0.75rem 1rem",
  },
  swapInputBox: {
    color: theme?.colors?.swap?.inputBox,
    width: "0px",
    position: "relative",
    fontWeight: 500,
    outline: "none",
    border: "none",
    flex: "1 1 auto",
    fontSize: "24px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0px",
    appearance: "textfield",
  },
  maxButton: {
    height: "28px",
    backgroundColor: theme?.colors?.swap?.maxBg,
    border: "1px solid " + theme?.colors?.swap?.maxBorder,
    borderRadius: "0.5rem",
    cursor: "pointer",
    marginRight: "0.5rem",
    "&:hover": {
      border: "1px solid " + theme?.colors?.swap?.maxHover,
    },
  },
  maxButtonLabel: {
    color: theme?.colors?.swap?.maxText,
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  tokenButton: {
    alignItems: "center",
    height: "2.2rem",
    borderRadius: "12px",
    boxShadow: "none",
    outline: "none",
    cursor: "pointer",
    userSelect: "none",
    border: "none",
    padding: "0px 0.5rem",
    width: "140px",
  },
  tokenButtonLabel: {
    color: theme?.colors?.swap?.inputBox,
    fontSize: "20px",
    fontWeight: "500",
  },
  tokenImage: {
    width: "24px",
    height: "24px",
    borderRadius: "24px",
    boxShadow: "rgb(0 0 0 / 8%) 0px 6px 10px",
    marginRight: "10px",
  },
  swapExchange: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  exchangeButton: {
    color: theme?.colors?.swap?.exchangeButton,
  },
  swapCurrencyPrice: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 15px",
    margin: "15px 0px",
  },
  swapCurrencyText: {
    boxSizing: "border-box",
    margin: "0px",
    minWidth: "0px",
    fontWeight: "500",
    fontSize: "14px",
    color: theme?.colors?.swap?.header,
  },
  externalUrl: {
    marginTop: "10px",
    color: theme?.colors?.swap?.header,
    "&:hover": {
      opacity: "0.8",
    },
  },
  externalLink: {
    textDecoration: "none",
  },
});

const BuyBuidlModal = ({
  classes,
  closeModal,
  modalOpen,
  bnbBalance,
  buidlBalance,
  price,
  onSwap,
  url,
  account,
}) => {
  const theme = useTheme();
  const [swapDirection, setSwapDirection] = useState(0);
  const [inputAmount, setInputAmount] = useState(0);
  const [outputAmount, setOutputAmount] = useState(0);

  const updateOutputAmount = (inputAmount) => {
    if (inputAmount > 0 && price > 0) {
      if (swapDirection === 0) {
        setOutputAmount(parseFloat(inputAmount) * price);
      } else {
        setOutputAmount(parseFloat(inputAmount) / price);
      }
    } else {
      setOutputAmount(0);
    }
  };

  const updateInputAmount = (outputAmount) => {
    if (outputAmount > 0 && price > 0) {
      if (swapDirection === 0) {
        setInputAmount(parseFloat(outputAmount) / price);
      } else {
        setInputAmount(parseFloat(outputAmount) * price);
      }
    } else {
      setInputAmount(0);
    }
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"xs"}
      TransitionComponent={Transition}
      classes={{ paper: classes.paper, paperWidthSm: classes.modalRoot }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.swapContainer}>
          <div className={classes.swapCurrencyInput}>
            <div className={classes.swapCurrencyInfo}>
              <Typography className={classes.swapCurrencyText}>From</Typography>
              <Typography className={classes.swapCurrencyText}>
                Balance: {swapDirection === 0 ? bnbBalance : buidlBalance}
              </Typography>
            </div>
            <div className={classes.swapTokenAmount}>
              <Input
                placeholder="0.0"
                minLength="1"
                maxLength="79"
                autocomplete="off"
                autocorrect="off"
                type="text"
                pattern="^[0-9]*[.,]?[0-9]*$"
                value={inputAmount}
                onChange={(ev) => {
                  setInputAmount(ev.target.value);
                  updateOutputAmount(ev.target.value);
                }}
                classes={{ root: classes.swapInputBox }}
              />
              <Button
                classes={{
                  root: classes.maxButton,
                  label: classes.maxButtonLabel,
                }}
                onClick={(ev) => {
                  setInputAmount(
                    swapDirection === 0 ? bnbBalance : buidlBalance
                  );
                  updateOutputAmount(
                    swapDirection === 0 ? bnbBalance : buidlBalance
                  );
                }}
              >
                MAX
              </Button>
              <Button
                classes={{
                  root: classes.tokenButton,
                  label: classes.tokenButtonLabel,
                }}
                onClick={(ev) => {}}
              >
                <img
                  src={
                    swapDirection === 0
                      ? theme?.swapSymbolImage
                      : theme?.logoImage
                  }
                  className={classes.tokenImage}
                  alt="logo"
                />
                {swapDirection === 0 ? theme?.swapSymbol : "BUIDL"}
              </Button>
            </div>
          </div>
          <div className={classes.swapExchange}>
            <IconButton
              classes={{ root: classes.exchangeButton }}
              onClick={(ev) => {
                setSwapDirection(swapDirection === 0 ? 1 : 0);
                setInputAmount(outputAmount);
                updateOutputAmount(outputAmount);
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </div>
          <div className={classes.swapCurrencyOutput}>
            <div className={classes.swapCurrencyInfo}>
              <Typography className={classes.swapCurrencyText}>
                To (estimated)
              </Typography>
              <Typography className={classes.swapCurrencyText}>
                Balance: {swapDirection === 1 ? bnbBalance : buidlBalance}
              </Typography>
            </div>
            <div className={classes.swapTokenAmount}>
              <Input
                placeholder="0.0"
                minLength="1"
                maxLength="79"
                autocomplete="off"
                autocorrect="off"
                type="text"
                pattern="^[0-9]*[.,]?[0-9]*$"
                value={outputAmount}
                onChange={(ev) => {
                  setOutputAmount(ev.target.value);
                  updateInputAmount(ev.target.value);
                }}
                classes={{ root: classes.swapInputBox }}
              />
              <Button
                classes={{
                  root: classes.maxButton,
                  label: classes.maxButtonLabel,
                }}
                onClick={(ev) => {
                  setOutputAmount(
                    swapDirection === 1 ? bnbBalance : buidlBalance
                  );
                }}
              >
                MAX
              </Button>
              <Button
                classes={{
                  root: classes.tokenButton,
                  label: classes.tokenButtonLabel,
                }}
                onClick={(ev) => {}}
              >
                <img
                  src={
                    swapDirection === 1
                      ? theme?.swapSymbolImage
                      : theme?.logoImage
                  }
                  className={classes.tokenImage}
                  alt="logo"
                />
                {swapDirection === 1 ? theme?.swapSymbol : "BUIDL"}
              </Button>
            </div>
          </div>
          <div className={classes.swapCurrencyPrice}>
            <Typography variant="h5" className={classes.swapCurrencyText}>
              Price
            </Typography>
            <Typography variant="h5" className={classes.swapCurrencyText}>
              {swapDirection === 0
                ? price > 0
                  ? (1 / price).toFixed(6)
                  : 0
                : price}{" "}
              {swapDirection === 0 ? theme?.swapSymbol : "BUIDL"} per{" "}
              {swapDirection === 1 ? theme?.swapSymbol : "BUIDL"}
            </Typography>
          </div>
          {account && account.address ? (
            <Button
              classes={{
                root: classes.swapButton,
                disabled: classes.swapButtonDisabled,
                label: classes.buttonLabel,
              }}
              disabled={!account || !account.address}
              onClick={(ev) => {
                if (account && account.address) {
                  onSwap(inputAmount, outputAmount, swapDirection);
                }
              }}
            >
              Swap
            </Button>
          ) : (
            <ConnectWalletButton />
          )}

          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.externalLink}
          >
            <Typography className={classes.externalUrl}>
              Go to {theme?.exchangeUrl}
            </Typography>
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(BuyBuidlModal));
