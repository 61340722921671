import React, {
  useCallback,
  useEffect,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

import { injected } from "../stores/connectors";
import Store from "../stores/store";
import { useTheme } from "@material-ui/core";
import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  ENVIRONMENT,
  ERROR,
} from "../constants/constants";

const AccountContext = createContext({});

const store = Store.store;
const emitter = Store.emitter;

const IS_WALLET_CONNECTED_KEY =
  ENVIRONMENT === "mainnet"
    ? "isWalletConnected@mainnet"
    : "isWalletConnected@testnet";

const AccountProvider = ({ children }) => {
  const web3Context = useWeb3React();
  const [web3, setWeb3] = useState(undefined);
  const {
    connector,
    error,
    library,
    account,
    activate,
    active,
    chainId,
    deactivate,
  } = web3Context;
  const theme = useTheme();

  const blockchainParams = useMemo(
    () => ({
      chainId: "0x" + theme.chainId.toString(16),
      chainName: theme.chainName,
      nativeCurrency: {
        name: theme.chainSymbol,
        symbol: theme.symbol,
        decimals: 18,
      },
      rpcUrls: [theme.rpcUrl],
      blockExplorerUrls: [theme.blockExplorer],
    }),
    [theme]
  );

  const requestChangeNetworkAndActivate = useCallback(
    async (name = "MetaMask") => {
      const { ethereum } = window;

      if (!ethereum) {
        emitter.emit(ERROR, "Metamask is not installed, please install!");
        console.error("Metamask is not installed, please install!");
      }

      console.info(chainId, parseInt(blockchainParams.chainId, 16));

      if (chainId !== parseInt(blockchainParams.chainId, 16)) {
        try {
          await library?.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: blockchainParams.chainId }],
          });

          // const connectorsByName = store.getStore("connectorsByName");

          // await activate(connectorsByName[name]);
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError?.code === 4902) {
            try {
              await library?.provider.request({
                method: "wallet_addEthereumChain",
                params: [blockchainParams],
              });

              // metamask (only known implementer) automatically switches after a network is added
              // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
              // metamask's behavior when switching to the current network is just to return null (a no-op)
              try {
                await library?.provider.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: blockchainParams.chainId }],
                });
              } catch (switchErrorTwo) {
                emitter.emit(ERROR, "Could not switch chains");
              }
            } catch (addError) {
              console.log(addError);
              console.error(addError);
              emitter.emit(ERROR, addError);
            }
          } else if (switchError?.code === 4001) {
            console.error(
              "To connect your wallet you must switch to the right network!"
            );
            emitter.emit(
              ERROR,
              "To connect your wallet you must switch to the right network!"
            );
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockchainParams, chainId, library?.provider]
  );

  useEffect(() => {
    if (error) emitter.emit(ERROR, error);
  }, [error]);

  const connectWallet = useCallback(
    async (name = "MetaMask") => {
      console.log("Connect Wallet -> ", name);
      try {
        const connectorsByName = store.getStore("connectorsByName");
        await activate(connectorsByName[name]);
        localStorage.setItem(IS_WALLET_CONNECTED_KEY, "true");
      } catch (err) {
        console.log(err);
        console.error(err);
      }

      try {
        await requestChangeNetworkAndActivate(name);
      } catch (err) {
        console.log(err);
        console.error(err);
        emitter.emit(ERROR, err);
      }
    },
    [activate, requestChangeNetworkAndActivate]
  );

  useEffect(() => {
    const connectToWallet = async () => {
      try {
        const isAuthorized = await injected.isAuthorized();

        if (isAuthorized) {
          console.info("injecting...");
          // await requestChangeNetworkAndActivate();
          connectWallet();
        }
      } catch (err) {
        let errorMessage = err;

        if (err?.message.includes("wallet_switchEthereumChain")) {
          errorMessage = "Please switch your wallet network.";
        }

        console.error(errorMessage);
        console.error("WALLET_ERROR", err);
        emitter.emit(ERROR, errorMessage);
      }
    };

    if (localStorage?.getItem(IS_WALLET_CONNECTED_KEY) === "true") {
      connectToWallet();
    }
  }, [connectWallet]);

  useEffect(() => {
    if (
      active &&
      blockchainParams?.chainId &&
      chainId === parseInt(blockchainParams?.chainId, 16)
    ) {
      if (account && library) {
        localStorage.setItem(IS_WALLET_CONNECTED_KEY, "true");
        console.info("setting account...");
        store.setStore({
          account: { address: account },
          web3context: web3Context,
        });
        emitter.emit(CONNECTION_CONNECTED);
      }
    } else {
      setWeb3(undefined);
    }
  }, [active, account, library, chainId, blockchainParams, web3Context]);

  useEffect(() => {
    if (active && !account) {
      setWeb3(undefined);
      deactivate();
      store.setStore({ account: {}, web3context: null });
      emitter.emit(CONNECTION_DISCONNECTED);
      localStorage.setItem(IS_WALLET_CONNECTED_KEY, "false");
    }
  }, [active, account, deactivate]);

  useEffect(() => {
    if (library?.provider) {
      console.info("setting web3 provider...");
      setWeb3(new Web3(library.provider));
    } else {
      setWeb3(undefined);
      deactivate();
      store.setStore({ account: {}, web3context: null });
      emitter.emit(CONNECTION_DISCONNECTED);
      localStorage.setItem(IS_WALLET_CONNECTED_KEY, "false");
    }
  }, [library, deactivate]);

  const disconnectWallet = useCallback(() => {
    console.log("Disconnect Wallet");
    try {
      if (connector && connector.close) {
        connector.close();
      }

      deactivate();

      setWeb3(undefined);

      store.setStore({ account: {}, web3context: null });

      localStorage.setItem(IS_WALLET_CONNECTED_KEY, "false");
      emitter.emit(CONNECTION_DISCONNECTED);
    } catch (err) {
      console.log(err);
      console.error(err);
      emitter.emit(ERROR, err);
    }
  }, [deactivate, connector]);

  const networkChanged = useCallback(
    async (newChainId) => {
      if (
        localStorage?.getItem(IS_WALLET_CONNECTED_KEY) === "true" &&
        (newChainId === blockchainParams?.chainId ||
          parseInt(newChainId) === parseInt(blockchainParams?.chainId, 16))
      ) {
        const isAuthorized = await injected.isAuthorized();

        if (isAuthorized) {
          console.info("injecting...");
          await activate(injected);
          injected.on("Web3ReactDeactivate", () => {
            store.setStore({
              account: {},
              web3context: null,
            });
            emitter.emit(CONNECTION_DISCONNECTED);
            localStorage.setItem(IS_WALLET_CONNECTED_KEY, "false");
          });
        }
      } else if (
        blockchainParams.chainId &&
        newChainId !== blockchainParams.chainId
      ) {
        deactivate();
        setWeb3(undefined);
        store.setStore({ account: {}, web3context: null });
        localStorage.setItem(IS_WALLET_CONNECTED_KEY, "false");
        emitter.emit(CONNECTION_DISCONNECTED);
      }
    },
    [blockchainParams, activate, deactivate]
  );

  useEffect(() => {
    const { ethereum } = window;
    if (ethereum && !active && !error) {
      const handleAccountsChanged = async (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts.length > 0) {
          await activate(injected);
        }
      };

      ethereum.on("chainChanged", networkChanged);
      ethereum.on("networkChanged", networkChanged);
      ethereum.on("accountsChanged", (accounts) => {
        handleAccountsChanged(accounts);
      });

      return () => {
        ethereum.removeListener("chainChanged", networkChanged);
        ethereum.removeListener("networkChanged", networkChanged);
        ethereum.removeListener("accountsChanged", (accounts) => {
          handleAccountsChanged(accounts);
        });
      };
    }
  }, [active, error, networkChanged, activate]);

  const contextValue = useMemo(
    () => ({
      account: { address: account },
      provider: library,
      web3,
      connectWallet,
      disconnectWallet,
    }),
    [account, connectWallet, web3, disconnectWallet, library]
  );

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);

  return context;
};

export default AccountProvider;
