import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";

import { withNamespaces } from "react-i18next";
import MultilineChart from "./MultilineChart";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
});

const MarketChart = ({ classes, marketData, width, height }) => {
  const theme = useTheme();
  const dimensions = {
    width: width,
    height: height,
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

  return (
    <div className={classes.root}>
      <MultilineChart
        data={[
          {
            name: "Prices",
            color: theme?.colors?.wallet?.buttonBackground,
            items:
              marketData.prices?.map((data) => ({
                value: data.value,
                date: data.date,
              })) || [],
          },
        ]}
        dimensions={dimensions}
      />
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(MarketChart)));
