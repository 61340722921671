import STARTokenAbi from "./abis/startToken";
import SeedPresaleAbi from "./abis/seedPresale";
import PrivatePresaleAbi from "./abis/privatePresale";
import StrategicPresaleAbi from "./abis/strategicPresale";
import ERC20Abi from "./abis/erc20";
import factoryAbi from "./abis/factory";
import factoryV2Abi from "./abis/factoryV2";
import factoryV3Abi from "./abis/factoryV3";
import starterInfoAbi from "./abis/starterInfo";
import starterInfoV3Abi from "./abis/starterInfoV3";
import vestStakingAbi from "./abis/vestStaking";
import starterPresaleAbi from "./abis/starterPresale";
import starterPresaleV2Abi from "./abis/starterPresaleV2";
import starterPresaleV3Abi from "./abis/starterPresaleV3";

import wsbTokenAbi from "./abis/wsb";
import wsbPrivatePresaleAbi from "./abis/wsbPrivatePresale";
import wiseTokenAbi from "./abis/wise";
import wisePublicPresaleAbi from "./abis/wisePublicPresale";
import vestTokenAbi from "./abis/vest";
import vestPrivateSaleAbi from "./abis/vestPrivateSale";
import vestSeedSaleAbi from "./abis/vestSeed";

import farmingAbi from "./abis/farming";
import farmingPairAbi from "./abis/farmingPair";

import lockerAbi from "./abis/Locker";
import lockerStakingAbi from "./abis/LockerStaking";

import swapRouterAbi from "./abis/swapRouter";

import { ENVIRONMENT } from "../../constants/constants";

const config = {
  STARTokenAddress: "0xac74f925B25928a95A0159067B64B6C4e33C41fa",
  STARTokenAbi,
  SeedPresaleAddress: "0x2019459B04E37436E7EdC3D5d6346453d08606Fe",
  SeedPresaleAbi,
  PrivatePresaleAddress: "0x3b447f280D03761De72AE1d1640C4E3D4e6D0909",
  PrivatePresaleAbi,
  StrategicPresaleAddress: "0x58DAB443fc4E7977dD09EA4cD83944a60f17C283",
  StrategicPresaleAbi,
  ERC20Abi,
  factoryAbi,
  factoryAddress: "0x34Cc2A333d4A6086726B3d84220eA15d78Ca1962",
  factoryV2Abi,
  factoryV2Address: "0x43a953884f7C2bB63f74F921b266f5174195afe3",
  factoryV3Abi,
  factoryV3Address: "0xC4Bc9098C6a5ec1Ed12C3C8beB201ff7Cd271290",

  starterInfoAddress: "0x2593624e8d4bD91ef12F45f0324e09f2cd340907", // StarterInfo
  starterInfoAbi,

  starterInfoV3Address: "0x9AB4268495934F3C64896E817d137F969023296f",
  starterInfoV3Abi,

  vestStaking: "0xDd4841851A08e6F05d64d540BbFF0D94A61A4293", // LockerStaking
  vestStakingAbi: lockerStakingAbi,

  starterPresaleAbi,
  starterPresaleV2Abi,
  starterPresaleV3Abi,

  wsbToken: "0x22168882276e5D5e1da694343b41DD7726eeb288",
  wsbTokenAbi,
  wsbPrivatePresaleAddress: "0x84E736a4cCC7FB032d671B2877d6210E3B64F105",
  wsbPrivatePresaleAbi,

  wiseToken: "0x0000000000000000000000000000000000000000",
  wiseTokenAbi,
  wisePublicPresaleAddress: "0xbB064642C34C3FDE27fba69940B0de5FE5445609",
  wisePublicPresaleAbi,

  vestTokenAddress: "0xac74f925B25928a95A0159067B64B6C4e33C41fa", // BUIDL token
  vestTokenAbi: STARTokenAbi,

  vestPrivateSale: "0x79E9042C67E1672869D0D745080A239c8FED8c38",
  vestPrivateSaleAbi,
  vestSeedSale: "0xF9A761254481796F6B8b0DE711A5029215775f93",
  vestSeedSaleAbi,

  starterVestingAddress: "0xE8759dC246eF451d763a12d4e79244627507BD7F", // Locker
  starterVestingAbi: lockerAbi,
  farmingAddress: "0x38E85adcaBdFe9f07AD155147EB5F55582011FD0",
  farmingAbi,
  farmingPairAddress: "0x67b78318db88eeb6c9271816114d3ca6423fd66d",
  farmingPairAbi,
  wbnbAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",

  swapSymbol: "ETH",
  swapToken: "0x0000000000000000000000000000000000000000",
  swapTokenAbi: ERC20Abi,
  swapRouter: "0x0000000000000000000000000000000000000000",
  swapRouterAbi,
};

if (ENVIRONMENT === "mainnet") {
  // config.factoryV3Address = "0xC4Bc9098C6a5ec1Ed12C3C8beB201ff7Cd271290";
  // config.starterInfoV3Address = "0x9AB4268495934F3C64896E817d137F969023296f";
  // config.vestStaking = "0xDd4841851A08e6F05d64d540BbFF0D94A61A4293"; // LockerStaking
  // config.wsbToken = "0x22168882276e5D5e1da694343b41DD7726eeb288";
  // config.wsbPrivatePresaleAddress =
  //   "0x84E736a4cCC7FB032d671B2877d6210E3B64F105";
  // config.wiseToken = "0x0000000000000000000000000000000000000000";
  // config.wisePublicPresaleAddress =
  //   "0xbB064642C34C3FDE27fba69940B0de5FE5445609";
  // config.vestPrivateSale = "0x79E9042C67E1672869D0D745080A239c8FED8c38";
  // config.vestSeedSale = "0xF9A761254481796F6B8b0DE711A5029215775f93";
  // config.farmingAddress = "0x38E85adcaBdFe9f07AD155147EB5F55582011FD0";
  // config.farmingPairAddress = "0x67b78318db88eeb6c9271816114d3ca6423fd66d";
  // config.wbnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";

  config.BUIDLTokenAddress = "0xdADc4F8E9d8d37294de0E02A2Ce8Cc0c90A4F6c2";
  config.vestTokenAddress = "0xdADc4F8E9d8d37294de0E02A2Ce8Cc0c90A4F6c2"; // BUIDL token

  config.factoryAddress = "0xf1629ee40bdd6Af176221924F8Aeb899E23240a4";
  config.starterInfoAddress = "0xdfd485f29BA0e726e87065f313e0b2458eAE1506";
  config.starterStaking = "0xb3BC23ceD4fae30152870012d659267CEcaedD35";
  config.vestingAddress = "0xE8759dC246eF451d763a12d4e79244627507BD7F";
  config.starterVestingAddress = "0xE8759dC246eF451d763a12d4e79244627507BD7F";
  config.wethAddress = "0x4200000000000000000000000000000000000006";
  config.usdcAddress = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";
  config.stakingToken = "0xdADc4F8E9d8d37294de0E02A2Ce8Cc0c90A4F6c2";
  config.stakingContracts = ["0xb3BC23ceD4fae30152870012d659267CEcaedD35"];
  config.swapToken = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";
  config.swapSymbol = "USDC";
  config.swapRouter = "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24"; // uniswap v2 router on Base
} else if (ENVIRONMENT === "testnet") {
  config.BUIDLTokenAddress = "0x1fc09bC5355C77Fa9925c614223360b1d8cDE673";
  config.factoryAddress = "0xb7a4B9E2C2877EeD2E306D8A200692f120daE931";
  config.starterInfoAddress = "0x4c575528E32b2D639Def5408839144dff7091009";
  config.starterStaking = "0xE3dB3b7c0e83799d63B56640adea950C34dc5643";
  config.vestingAddress = "0x3C24256B2cE30c88c29cc57e273958038B62222a";
  config.vestTokenAddress = "0x1fc09bC5355C77Fa9925c614223360b1d8cDE673"; // BUIDL token
  config.starterVestingAddress = "0x0e7b582003de0E541548cF02a1F00725Df6E6E6f";
  config.wethAddress = "0x4200000000000000000000000000000000000006";
  config.usdcAddress = "0x0000000000000000000000000000000000000000";
  config.stakingToken = "0x1fc09bC5355C77Fa9925c614223360b1d8cDE673";
  config.stakingContracts = ["0xE3dB3b7c0e83799d63B56640adea950C34dc5643"];
  config.swapToken = "0x4200000000000000000000000000000000000006";
  config.swapRouter = "0x327Df1E6de05895d2ab08513aaDD9313Fe505d86";
}

export default config;
