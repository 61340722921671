import React, { useCallback, useEffect, useState } from "react";
import { Expand } from "@theme-toggles/react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import store from "../../../stores/store";
import { THEME_MODE_CHANGE } from "../../../constants/constants";
import { usePreferedTheme } from "../../../hooks/usePreferedTheme";

const styles = (theme) => ({
  switcherContainer: {
    display: "flex",
    alignItems: "center",
  },
  switcher: {
    color: theme?.colors?.linkText,
    backgroundColor: "transparent",
    fontSize: "1.5rem",
    padding: "0",
    display: "flex",
    cursor: "pointer",
  },
  switcherMobileText: {
    color: theme?.colors?.linkText,
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    marginLeft: "0.5rem",
    cursor: "pointer",

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

const dispatcher = store.dispatcher;

function ThemeSwitcher({ classes }) {
  const { preferedThemeMode } = usePreferedTheme();

  const [isLightTheme, setIsLightTheme] = useState(
    preferedThemeMode === "light"
  );

  useEffect(() => {
    setIsLightTheme(preferedThemeMode === "light");
  }, [preferedThemeMode]);

  const handleToggle = useCallback(() => {
    setIsLightTheme((prev) => !prev);

    dispatcher.dispatch({
      type: THEME_MODE_CHANGE,
      content: { themeMode: isLightTheme ? "dark" : "light" },
    });
  }, [isLightTheme]);

  return (
    <div className={classes.switcherContainer} onClick={handleToggle}>
      <Expand
        className={classes.switcher}
        toggled={!isLightTheme}
        height={300}
        width={300}
      />
      <p className={classes.switcherMobileText}>
        {isLightTheme ? "Light" : "Dark"}
      </p>
    </div>
  );
}

export default withRouter(withStyles(styles)(ThemeSwitcher));
