import React from "react";
import { DialogContent, Dialog, Slide, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Unlock from "./unlock.jsx";

import { useTheme } from "@material-ui/core";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function UnlockModalTitle({ theme, closeModal }) {
  return (
    <div
      style={{
        display: "flex",
        padding: "30px 35px 0px 35px",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "1rem",
        width: "100%",
      }}
    >
      <p
        style={{
          color: theme?.colors?.modal?.title,
          fontSize: "25px",
          fontWeight: "bold",
          margin: "0px",
        }}
      >
        Connect wallet
      </p>
      <IconButton
        onClick={() => {
          closeModal();
        }}
      >
        <CloseIcon
          style={{
            fill: theme?.colors?.modal?.title,
          }}
        />
      </IconButton>
    </div>
  );
}

const UnlockModal = ({ closeModal, modalOpen }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={modalOpen}
      fullWidth={false}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={closeModal}
      PaperProps={{
        style: {
          background: theme?.colors?.modalBackground,
          boxShadow: "0px 10px 20px " + theme?.colors?.containerShadow,
          borderRadius: "32px",
        },
      }}
    >
      <UnlockModalTitle closeModal={closeModal} theme={theme} />
      <DialogContent
        style={{
          padding: "35px",
        }}
      >
        <Unlock closeModal={closeModal} />
      </DialogContent>
    </Dialog>
  );
};

export default UnlockModal;
