/** Axis.js */
import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { useTheme } from "@material-ui/core";

const Axis = ({
  type,
  scale,
  ticks,
  transform,
  tickFormat,
  disableAnimation,
  ...props
}) => {
  const theme = useTheme();
  const ref = React.useRef(null);
  React.useEffect(() => {
    const axisGenerator = type === "right" ? d3.axisRight : d3.axisBottom;
    const axis = axisGenerator(scale).ticks(ticks).tickFormat(tickFormat);
    const axisGroup = d3.select(ref.current);
    if (disableAnimation) {
      axisGroup.call(axis);
    } else {
      axisGroup.transition().duration(750).ease(d3.easeLinear).call(axis);
    }
    axisGroup.select(".domain").remove();
    axisGroup.selectAll("line").remove();
    axisGroup
      .selectAll("text")
      .attr("opacity", 0.8)
      .attr("color", theme?.colors?.presale?.cardTitle)
      .attr("font-size", "0.8rem");
  }, [theme, type, scale, ticks, tickFormat, disableAnimation]);

  return <g ref={ref} transform={transform} {...props} />;
};

Axis.propTypes = {
  type: PropTypes.oneOf(["right", "bottom"]).isRequired,
};

export default Axis;
