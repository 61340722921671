import bigInt from "big-integer";

import EmptyTokenImage from "../assets/base-logo.svg";

import ETHLogo from "../assets/ETH-icon.png";
import USDCLogo from "../assets/USDC-icon.png";

export const CONNECTION_CONNECTED = "CONNECTION_CONNECTED";
export const CONNECTION_DISCONNECTED = "CONNECTION_DISCONNECTED";

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT; // set to testnet if we are testing

export const ERROR = "ERROR";

export const CONFIGURE = "CONFIGURE";
export const CONFIGURE_RETURNED = "CONFIGURE_RETURNED";

export const STAKE = "STAKE";
export const STAKE_V1 = "STAKE_V1";
export const STAKE_RETURNED = "STAKE_RETURNED";

export const UNSTAKE = "UNSTAKE";
export const UNSTAKE_V1 = "UNSTAKE_V1";
export const UNSTAKE_RETURNED = "UNSTAKE_RETURNED";

export const CLAIM_HODLER = "CLAIM_HODLER";
export const CLAIM_HODLER_RETURNED = "CLAIM_HODLER_RETURNED";

export const CLAIM_LP = "CLAIM_LP";
export const CLAIM_LP_RETURNED = "CLAIM_LP_RETURNED";

export const GET_REWARDS = "GET_REWARDS";
export const GET_REWARDS_RETURNED = "GET_REWARDS_RETURNED";

export const EXIT = "EXIT";
export const EXIT_RETURNED = "EXIT_RETURNED";

export const WITHDRAW = "WITHDRAW";
export const WITHDRAW_RETURNED = "WITHDRAW_RETURNED";

export const GET_BALANCES = "GET_BALANCES";
export const GET_BALANCES_RETURNED = "GET_BALANCES_RETURNED";

export const GET_SWAP_INFO = "GET_SWAP_INFO";
export const GET_SWAP_INFO_RETURNED = "GET_SWAP_INFO_RETURNED";

export const GET_CLAIMABLE_ASSET = "GET_CLAIMABLE_ASSET";
export const GET_CLAIMABLE_ASSET_RETURNED = "GET_CLAIMABLE_ASSET_RETURNED";

export const CLAIM = "CLAIM";
export const CLAIM_RETURNED = "CLAIM_RETURNED";

export const PUBLIC_CLAIM = "PUBLIC_CLAIM";
export const PUBLIC_CLAIM_RETURNED = "PUBLIC_CLAIM_RETURNED";

export const CONNECT_WALLET = "CONNECT_WALLET";
export const GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO";

export const UPDATE_REWARDS = "UPDATE_REWARDS";
export const UPDATE_REWARDS_RETURNED = "UPDATE_REWARDS_RETURNED";

export const GET_PRESALE_INFO = "GET_PRESALE_INFO";
export const GET_PRESALE_INFO_RETURNED = "GET_PRESALE_INFO_RETURNED";

export const GET_PUBLIC_POOL_INFO = "GET_PUBLIC_POOL_INFO";
export const GET_PUBLIC_POOL_INFO_RETURNED = "GET_PUBLIC_POOL_INFO_RETURNED";

export const INVEST = "INVEST";
export const INVEST_RETURNED = "INVEST_RETURNED";

export const PUBLIC_INVEST = "PUBLIC_INVEST";
export const PUBLIC_INVEST_RETURNED = "PUBLIC_INVEST_RETURNED";

export const LOCK_LIQUIDITY = "LOCK_LIQUIDITY";
export const LOCK_LIQUIDITY_RETURNED = "LOCK_LIQUIDITY_RETURNED";

export const GET_POOLS_SUMMARY = "GET_POOLS_SUMMARY";
export const GET_POOLS_SUMMARY_RETURNED = "GET_POOLS_SUMMARY";

export const APPLY = "APPLY";
export const APPLY_RETURNED = "APPLY_RETURNED";

export const VOTE = "VOTE";
export const VOTE_RETURNED = "VOTE_RETURNED";

export const GET_VEST_STATS = "GET_VEST_STATS";
export const GET_VEST_STATS_RETURNED = "GET_VEST_STATS_RETURNED";

export const COLLECT_FUND = "COLLECT_FUND";
export const COLLECT_FUND_RETURNED = "COLLECT_FUND_RETURNED";

export const CANCEL_PRESALE = "CANCEL_PRESALE";
export const CANCEL_PRESALE_RETURNED = "CANCEL_PRESALE_RETURNED";

export const SEND_UNSOLD_TOKENS = "SEND_UNSOLD_TOKENS";
export const SEND_UNSOLD_TOKENS_RETURNED = "SEND_UNSOLD_TOKENS_RETURNED";

export const MOVE_STAKE = "MOVE_STAKE";
export const MOVE_STAKE_RETURNED = "MOVE_STAKE_RETURNED";

export const WRITE = "WRITE";
export const WRITE_RETURNED = "WRITE_RETURNED";

export const CUSTOM_WRITE = "CUSTOM_WRITE";
export const CUSTOM_WRITE_RETURNED = "CUSTOM_WRITE_RETURNED";

export const GET_FARMING_INFO = "GET_FARMING_INFO";
export const GET_FARMING_INFO_RETURNED = "GET_FARMING_INFO_RETURNED";

export const FARMING_STAKE = "FARMING_STAKE";
export const FARMING_STAKE_RETURNED = "FARMING_STAKE_RETURNED";

export const FARMING_WITHDRAW = "FARMING_WITHDRAW";
export const FARMING_WITHDRAW_RETURNED = "FARMING_WITHDRAW_RETURNED";

export const FARMING_CLAIM = "FARMING_CLAIM";
export const FARMING_CLAIM_RETURNED = "FARMING_CLAIM_RETURNED";

export const ALLOW_CLAIM = "ALLOW_CLAIM";
export const ALLOW_CLAIM_RETURNED = "ALLOW_CLAIM_RETURNED";

export const GET_REFUND = "GET_REFUND";
export const GET_REFUND_RETURNED = "GET_REFUND_RETURNED";

export const PUBLIC_WITHDRAW = "PUBLIC_WITHDRAW";
export const PUBLIC_WITHDRAW_RETURNED = "PUBLIC_WITHDRAW_RETURNED";

export const PUBLIC_LOCK = "PUBLIC_LOCK";
export const PUBLIC_LOCK_RETURNED = "PUBLIC_LOCK_RETURNED";

export const THEME_CHANGE = "THEME_CHANGE";
export const THEME_CHANGE_RETURNED = "THEME_CHANGE_RETURNED";

export const THEME_MODE_CHANGE = "THEME_MODE_CHANGE";
export const THEME_MODE_CHANGE_RETURNED = "THEME_MODE_CHANGE_RETURNED";

export const SWAP = "SWAP";
export const SWAP_RETURNED = "SWAP_RETURNED";

export const DEFAULT_DATA_REFRESH_INTERVAL = 10000;

export const fundingTokens = {
  base: [
    {
      value: "0x4200000000000000000000000000000000000006",
      label: "ETH",
      decimals: 18,
      logo: ETHLogo,
    },
    {
      value: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      label: "USDC",
      decimals: 6,
      logo: USDCLogo,
    },
  ],
};

export const defaultBuyUrls = {
  base: "https://app.uniswap.org/swap?inputCurrency=0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913&outputCurrency=0xdadc4f8e9d8d37294de0e02a2ce8cc0c90a4f6c2&chain=base",
  pulse:
    "https://pancakeswap.finance/#/swap?inputCurrency=0x31D0a7AdA4d4c131Eb612DB48861211F63e57610",
};

export const expandFixed = (value, decimals = 18) => {
  const valueStr = value.toString();
  const parts = valueStr.split(".");

  const integerPart = parts[0];
  let decimalPart = parts[1] || "";

  if (decimalPart.length > decimals) {
    decimalPart = decimalPart.substring(0, decimals);
  }
  while (decimalPart.length < decimals) {
    decimalPart += "0";
  }

  const combinedValue = bigInt(integerPart + decimalPart);

  if (!parts[1]) {
    return bigInt(integerPart).multiply(bigInt(10).pow(decimals));
  }

  return combinedValue;
};

export const FORMATE_ADDRESS = (address) => {
  if (!address) {
    return "";
  }
  return address.slice(0, 6) + "..." + address.slice(address.length - 4);
};

export const toFixed = (bi, decimals = 18, desired = 0) => {
  const trunc = decimals - desired;
  const shift = decimals - trunc;
  return (
    bigInt(bi)
      .divide(10 ** trunc)
      .toJSNumber() /
    10 ** shift
  ).toFixed(desired);
};

// export const expandFixed = (value, decimals = 18) => {
//   return value.multiply(10 ** decimals);
// };

export const isVotePassed = (presale) => {
  const yesVotes = parseFloat(toFixed(bigInt(presale?.yesVotes || "0"), 18, 4));
  const noVotes = parseFloat(toFixed(bigInt(presale?.noVotes || "0"), 18, 4));
  const minYesVotesThreshold = parseFloat(
    toFixed(bigInt(presale?.minYesVotesThreshold || "0"), 18, 4)
  );
  if (presale && presale.presaleVersion === "V1") {
    return yesVotes > noVotes && yesVotes >= minYesVotesThreshold;
  }
  if (presale && presale.presaleVersion === "V2") {
    return yesVotes > noVotes + minYesVotesThreshold;
  }
  if (presale && presale.presaleVersion === "V3") {
    if (presale.presaleType != "1") {
      return true;
    }
    return yesVotes > noVotes + minYesVotesThreshold;
  }
  return false;
};

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const getRemainingTime = (remainingTime) => {
  const remainingDay = Math.floor(remainingTime / 3600 / 24);
  const remainingHours = Math.floor(remainingTime / 3600);
  const remainingMinutes = Math.floor(remainingTime / 60);
  const remainingSeconds = Math.floor(remainingTime);

  if (remainingDay > 0) {
    return `${remainingDay} days`;
  }
  const _remainingHours =
    remainingHours < 10 ? `0${remainingHours}` : `${remainingHours}`;
  const _remainingMins =
    remainingMinutes % 60 < 10
      ? `0${remainingMinutes % 60}`
      : `${remainingMinutes % 60}`;
  const _remainingSecs =
    remainingSeconds % 60 < 10
      ? `0${remainingSeconds % 60}`
      : `${remainingSeconds % 60}`;
  return `${_remainingHours}:${_remainingMins}:${_remainingSecs}`;
};

export const getTokenLogo = (pool) => {
  if (pool?.type === 1) {
    const leftTokenImage = pool.token0Info?.image || EmptyTokenImage;
    const rightTokenImage = pool.token1Info?.image || EmptyTokenImage;
    return { left: leftTokenImage, right: rightTokenImage };
  } else if (pool?.type === 0) {
    return pool?.image || EmptyTokenImage;
  }
};

export const getTokenName = (pool) => {
  if (pool?.type === 1) {
    return (
      pool.token0Info.symbol.toUpperCase() +
      "-" +
      pool.token1Info.symbol.toUpperCase() +
      " LP"
    );
  } else if (pool?.type === 0) {
    return pool.symbol;
  }
};

export const getTokenPrice = (pool) => {
  if (pool?.type === 1) {
    return 0;
  } else if (pool?.type === 0) {
    return pool.price;
  }
};
