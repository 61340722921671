import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import BscIcon from "../assets/bsc.svg";
import oplsLogoImg from "../assets/logos/onpulse.png";
import baseLogoImg from "../assets/logos/onbase.png";
import buidlLogoImg from "../assets/logos/buidl-logo.png";

import BscBackgroundImage from "../assets/background.png";
import MaticBackgroundImage from "../assets/polygon-background.jpeg";
import PlsBackgroundImage from "../assets/pls-background.png";
import PolygonIcon from "../assets/polygon.svg";
import bscIdoImg from "../assets/ido.svg";
import mtcIdoImg from "../assets/ido-mtc.svg";
import baseIdoImg from "../assets/timelock.png";
import bscLockImg from "../assets/lock.svg";
import mtcLockImg from "../assets/lock-mtc.svg";
import UsdcIcon from "../assets/USDC-icon.png";
import baseLockImg from "../assets/vault.png";
import PlsIcon from "../assets/pulsechain.svg";
import BaseIcon from "../assets/base-logo.svg";
import { ENVIRONMENT } from "../constants/constants";

export const bscColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#1E232C",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",
  darkRedThird: "#1d1214cc",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#F0B900",
  lightYellowPrimaryHover: "#ae8501",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#707070",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#184539",
};

export const mtcColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#1E232C",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",
  darkRedThird: "#1d1214cc",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#F0B900",
  lightYellowPrimaryHover: "#ae8501",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#707070",
  lightGraySixth: "#909090",
  lightGrayTransparent: "rgba(255, 255, 255, 0.9)",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#cde6fe",
  lightBlueSixth: "#2891f9",
  lightBlueSeventh: "#0a1647",
  lightBlueEighth: "#b189f3",

  lightSkyPrimary: "#DEEFFC",
  lightSkySecondary: "#64A3FF",
  lightSkySecondaryHover: "#dff4fa",
  lightSkyThird: "#5281F7",
  lightSkyFourth: "#8148e570",
  lightSkySeventh: "#dfedfd",
  darkBluePrimary: "#8247e5",
  darkBlueSecondary: "#81A8F8",

  lightWhitePrimary: "#FFFCFB",
  lightSkyFifth: "#A5FCF6",
  lightSkySixth: "#5f00ff",
  lightSkyEighth: "#f1f7fe",

  lightPinkPrimary: "#CEB0FA",
  lightPinkSecondary: "#F5BBB2",
};

export const plsColors = {
  white: "#fff",
  black: "#000",
  blue: "#0080ff",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#aa00ff",

  darkGreen: "#27652f",
  darkBlack: "#1E232C",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",
  darkRedThird: "#1d1214cc",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#F0B900",
  lightYellowPrimaryHover: "#ae8501",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#707070",
  lightGraySixth: "#909090",
  lightGrayTransparent: "rgba(255, 255, 255, 0.9)",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#cde6fe",
  lightBlueSixth: "#2891f9",
  lightBlueSeventh: "#0a1647",
  lightBlueEighth: "#b189f3",

  lightSkyPrimary: "#fff1",
  lightSkySecondary: "#64A3FF",
  lightSkySecondaryHover: "#dff4fa",
  lightSkyThird: "#5281F7",
  lightSkyFourth: "#8148e570",
  lightSkySeventh: "#dfedfd",
  darkBluePrimary: "#8247e5",
  darkBlueSecondary: "#81A8F8",

  lightWhitePrimary: "#FFFCFB",
  lightSkyFifth: "#A5FCF6",
  lightSkySixth: "#5f00ff",
  lightSkyEighth: "#f1f7fe",

  lightPinkPrimary: "#CEB0FA",
  lightPinkSecondary: "#F5BBB2",
};

export const baseColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#242424",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",
  darkBlackTransparent: "rgba(0, 0, 0, 0.8)",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#617CBF",
  lightYellowPrimaryBackground: "#617CBF",
  lightYellowSecondary: "#FBC915",
  lightYellowPrimaryHover: "#0080ff",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",
  lightYellowFourth: "#4c3b03",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#2c1143",

  lightGrayPrimary: "#30354B",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#fc284c",
  lightGrayFifth: "#2c2f36",
  lightGraySixth: "#909090",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#153d6f70",
  lightBlueSixth: "#2172e5",
  lightBlueSeventh: "#6da8ff",
  lightBlueEighth: "#b189f3",

  lightWhitePrimary: "#FFFCFB",
  lightSkySeventh: "#dfedfd",
  lightSkyEighth: "#f1f7fe",
  statusBarGreen: "#00C76B",
  statusBarBackgroundGray: "#A0A0A0",
};

const breakpoints = createBreakpoints({
  keys: ["xs", "sm", "ms", "md", "lg", "xl"],
  values: {
    xs: 0,
    sm: 600,
    ms: 768,
    md: 900,
    lg: 1200,
    xl: 1800,
  },
});

const bscTheme = {
  type: "bsc",
  chain: "Binance Smart Chain",
  chainName: "Binance Smart Chain",
  chainSymbol: "BSC",
  chainLogo: BscIcon,
  chainId: 56,
  blockExplorer: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed.binance.org/",
  symbol: "BNB",
  swapSymbol: "BNB",
  stakingToken: "START",
  stakingTokenImage: oplsLogoImg,
  enabled: true,
  colors: {
    header: {
      background: bscColors.darkBlack,
      text: bscColors.white,
      link: bscColors.white,
      linkHover: bscColors.lightPumpkin,
      menuButton: bscColors.white,
      boldBrownText: bscColors.lightYellowPrimary,
      balanceText: bscColors.lightGraySecondary,
      balanceValue: bscColors.lightYellowPrimary,
      heading: bscColors.lightGrayPrimary,
      textSecondary: bscColors.lightGrayPrimary,
      closeButton: bscColors.white,
    },
    footer: {
      background: bscColors.blackPrimary,
      text: bscColors.lightGrayPrimary,
      textSecondary: bscColors.lightGraySecondary,
      icon: bscColors.lightYellowPrimary,
    },
    wallet: {
      buttonBackground: bscColors.lightYellowPrimary,
      buttonBackgroundSecondary: bscColors.lightYellowPrimaryHover,
      buttonBackgroundHover: bscColors.lightYellowPrimaryHover,
      buttonText: bscColors.lightBlackPrimary,
      buttonTextSecondary: bscColors.lightYellowPrimary,
    },
    stake: {
      background: bscColors.lightBlackPrimary,
    },
    home: {
      heading: bscColors.white,
      headingSecondary: bscColors.lightYellowPrimary,
      text: bscColors.lightGrayPrimary,
      textSecondary: bscColors.lightGraySecondary,
      background: bscColors.blackPrimary,
      buttonText: bscColors.white,
    },
    voting: {
      heading: bscColors.white,
      tableHeaderText: bscColors.lightGraySecondary,
      text: bscColors.lightGrayPrimary,
      secondaryText: bscColors.lightYellowPrimary,
      searchText: bscColors.lightGrayPrimary,
      border: bscColors.lightGrayPrimaryTrans,
      searchMagnifier: bscColors.lightGraySecondary,
      tableHeader: bscColors.lightGrayThird,
      tableBorder: bscColors.lightGrayFourth,
      tableCell: bscColors.lightBlackPrimary,
      tableRowHover: bscColors.lightBlueFourth,
    },
    pools: {
      border: bscColors.lightGrayPrimaryTrans,
      searchMagnifier: bscColors.lightGraySecondary,
      searchText: bscColors.lightGrayPrimary,
      text: bscColors.lightGrayPrimary,
      menuBackground: bscColors.lightBlackPrimary,
      menuBorder: bscColors.blackPrimary,
      textSelected: bscColors.lightYellowPrimary,
      paginationText: bscColors.lightYellowPrimary,
      paginationItemSelectedBkg: bscColors.lightYellowPrimary,
      paginationItemSelectedText: bscColors.lightBlackPrimary,
      paginationItemBorder: bscColors.lightYellowPrimary,
    },
    poolCard: {
      background: bscColors.blackPrimary,
      title: bscColors.lightGrayPrimary,
      textSecondary: bscColors.lightYellowPrimary,
      statusBarBackground: bscColors.lightGrayPrimary,
      statusBarLabel: bscColors.lightGraySecondary,
      statusBarFilled: {
        GREEN: bscColors.lightGreenPrimary,
        RED: bscColors.lightRedPrimary,
        YELLOW: bscColors.lightYellowPrimary,
      },
      poolType: {
        NOTOPENED: bscColors.lightBluePrimary,
        ONGOING: bscColors.lightYellowPrimary,
        FILLED: bscColors.lightGreenPrimary,
        CANCELLED: bscColors.lightRedSecondary,
        EXPIRED: bscColors.lightRedSecondary,
        NOT_PASSED: bscColors.lightRedSecondary,
      },
      statusBarLabelSecondary: bscColors.white,
      poolTypeText: bscColors.lightGrayPrimary,
      linkIcon: bscColors.lightYellowPrimary,
      linkIconHover: bscColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: bscColors.white,
      headingSecondary: bscColors.lightYellowPrimary,
      text: bscColors.lightGrayPrimary,
      textSecondary: bscColors.lightGraySecondary,
      background: bscColors.blackPrimary,
      buttonText: bscColors.white,
      bgBreakpoint: [bscColors.lightGreenBg1, bscColors.lightGreenBg2],
      disclaimerBackground: bscColors.darkBlack,
    },
    presale: {
      cardBackground: bscColors.darkBlack,
      cardTitle: bscColors.white,
      cardTitleSecondary: bscColors.lightGrayPrimary,
      cardText: bscColors.white,
      addressValueText: bscColors.lightYellowPrimary,
      buttonBg: bscColors.lightYellowPrimary,
      buttonBgHover: bscColors.lightYellowPrimaryHover,
      buttonBgDisabled: bscColors.lightGraySecondary,

      buttonText: bscColors.lightBlackPrimary,
      verifiedBg: bscColors.lightGreenSecondary,
      warningBg: bscColors.darkRedSecondary,
      commentText: bscColors.lightGraySecondary,
      icon: bscColors.lightYellowPrimary,
      border: bscColors.lightGraySecondary,
      graph: bscColors.lightYellowPrimary,
      graphButton: bscColors.lightYellowPrimary,
      graphButtonHover: bscColors.lightYellowPrimaryHover,
      graphButtonText: bscColors.lightYellowPrimary,
      graphButtonTextSelected: bscColors.lightBlackPrimary,
      locked: bscColors.lightBlueFifth,
      withdrawn: bscColors.lightGrayPrimary,
      unlockTime: bscColors.lightGraySecondary,
    },
    apply: {
      cardBackground: bscColors.darkBlack,
      cardTitle: bscColors.white,
      cardTitleSecondary: bscColors.lightGrayPrimary,
      cardText: bscColors.white,
      addressValueText: bscColors.lightYellowPrimary,
      buttonBg: bscColors.lightYellowPrimary,
      buttonBgHover: bscColors.lightYellowPrimaryHover,
      buttonBgDisabled: bscColors.lightGraySecondary,

      buttonText: bscColors.lightBlackPrimary,
      verifiedBg: bscColors.lightGreenSecondary,
      warningBg: bscColors.darkRedPrimary,
      commentText: bscColors.lightGraySecondary,

      icon: bscColors.lightYellowPrimary,
    },
    admin: {
      cardBackground: bscColors.darkBlack,
      cardTitle: bscColors.white,
      cardTitleSecondary: bscColors.lightGrayPrimary,
      cardText: bscColors.white,
      addressValueText: bscColors.lightYellowPrimary,
      buttonBg: bscColors.lightYellowPrimary,
      buttonBgHover: bscColors.lightYellowPrimaryHover,
      buttonBgDisabled: bscColors.lightGraySecondary,

      buttonText: bscColors.lightBlackPrimary,
      verifiedBg: bscColors.lightGreenSecondary,
      warningBg: bscColors.darkRedPrimary,
      commentText: bscColors.lightGraySecondary,

      icon: bscColors.lightYellowPrimary,
    },
    dollarHighlight: bscColors.lightBlueSecondary,
    divider: bscColors.lightGraySecondary,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${BscBackgroundImage})`,
  logoImage: oplsLogoImg,
  idoImage: bscIdoImg,
  lockImage: bscLockImg,
  swapSymbolImage: BscIcon,
  exchangeUrl: "PancakeSwap",
};

const mtcTheme = {
  type: "mtc",
  chain: "Polygon Network",
  chainName: "Polygon Network",
  chainSymbol: "Polygon",
  chainLogo: PolygonIcon,
  chainId: 137,
  blockExplorer: "https://polygonscan.com/",
  rpcUrl: "https://rpc-mainnet.matic.network",
  symbol: "MATIC",
  swapSymbol: "VEST",
  stakingToken: "VEST",
  stakingTokenImage: oplsLogoImg,
  enabled: true,
  colors: {
    header: {
      background: mtcColors.lightSkyPrimary,
      text: mtcColors.darkBluePrimary,
      link: mtcColors.darkBluePrimary,
      linkHover: mtcColors.darkBlueSecondary,
      menuButton: mtcColors.darkBluePrimary,
      boldBrownText: mtcColors.darkBluePrimary,
      balanceText: mtcColors.darkBluePrimary,
      balanceUsdText: mtcColors.lightGraySixth,
      balanceValue: mtcColors.darkBluePrimary,
      heading: mtcColors.darkBluePrimary,
      textSecondary: mtcColors.lightSkyThird,
      closeButton: mtcColors.darkBluePrimary,
      tag: mtcColors.darkBlueSecondary,
      linkSelected: mtcColors.darkBlueSecondary,
      dropdownIcon: mtcColors.darkBluePrimary,
      border: mtcColors.darkBluePrimary,
      comingSoon: mtcColors.lightGreenPrimary,
      comingSoonText: mtcColors.white,
      borderColor: mtcColors.darkBluePrimary,
      details: mtcColors.lightBlueEighth,
      headerBackground:
        "linear-gradient(0deg, " +
        mtcColors.lightSkySeventh +
        " 0%, " +
        mtcColors.white +
        " 100%)",
      balanceBackground: mtcColors.white,
      stakeBackground: mtcColors.lightSkyEighth,
      tierBackground: mtcColors.lightGrayPrimary,
      tierCircle: mtcColors.lightGrayPrimary,
      tierCircleFilled: mtcColors.darkBlueSecondary,
      tooltip: mtcColors.darkBlack,
    },
    footer: {
      background: mtcColors.lightSkyPrimary,
      text: mtcColors.darkBluePrimary,
      textSecondary: mtcColors.lightSkySecondary,
      icon: mtcColors.darkBluePrimary,
      border: mtcColors.darkBluePrimary,
    },
    wallet: {
      buttonBackground: mtcColors.darkBluePrimary,
      buttonBackgroundSecondary: mtcColors.darkBlueSecondary,
      buttonBackgroundHover: mtcColors.darkBlueSecondary,
      buttonText: mtcColors.lightSkyPrimary,
      buttonTextSecondary: mtcColors.darkBluePrimary,
    },
    home: {
      heading: mtcColors.lightSkyThird,
      headingSecondary: mtcColors.darkBluePrimary,
      text: mtcColors.lightSkyThird,
      textSecondary: mtcColors.lightSkySecondary,
      background: mtcColors.lightSkyPrimary,
      buttonText: mtcColors.lightSkyThird,
    },
    voting: {
      heading: mtcColors.lightSkyThird,
      tableHeaderText: mtcColors.lightWhitePrimary,
      text: mtcColors.lightSkyThird,
      secondaryText: mtcColors.darkBluePrimary,
      searchText: mtcColors.lightSkyThird,
      border: mtcColors.lightSkySecondary,
      searchMagnifier: mtcColors.lightSkySecondary,
      tableHeader: mtcColors.lightSkySecondary,
      tableBorder: mtcColors.lightSkySecondary,
      tableCell: mtcColors.lightWhitePrimary,
      tableRowHover: mtcColors.lightSkySecondaryHover,
    },
    pools: {
      border: mtcColors.lightSkySecondary,
      searchMagnifier: mtcColors.lightSkySecondary,
      searchText: mtcColors.lightSkyThird,
      text: mtcColors.lightSkyThird,
      menuBackground: mtcColors.lightSkyPrimary,
      menuBorder: mtcColors.lightSkyPrimary,
      textSelected: mtcColors.darkBluePrimary,
      paginationText: mtcColors.darkBluePrimary,
      paginationItemSelectedBkg: mtcColors.darkBluePrimary,
      paginationItemSelectedText: mtcColors.lightSkyPrimary,
      paginationItemBorder: mtcColors.darkBluePrimary,
    },
    poolCard: {
      background: mtcColors.lightSkyPrimary,
      border: mtcColors.darkBluePrimary,
      title: mtcColors.lightSkyThird,
      textSecondary: mtcColors.darkBluePrimary,
      statusBarBackground: mtcColors.lightSkyThird,
      statusBarLabel: mtcColors.lightSkySecondary,
      statusBarFilled: {
        GREEN: mtcColors.darkBluePrimary,
        RED: mtcColors.lightRedPrimary,
        YELLOW: mtcColors.darkBluePrimary,
        ORANGE: mtcColors.lightSkySixth,
      },
      poolType: {
        NOTOPENED: mtcColors.darkBluePrimary,
        ONGOING: mtcColors.darkBluePrimary,
        FILLED: mtcColors.darkBluePrimary,
        CANCELLED: mtcColors.lightRedSecondary,
        EXPIRED: mtcColors.lightRedSecondary,
      },
      statusBarLabelSecondary: mtcColors.lightSkyThird,
      poolTypeText: mtcColors.white,
      linkIcon: mtcColors.darkBluePrimary,
      linkIconHover: mtcColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: mtcColors.lightSkyThird,
      headingSecondary: mtcColors.darkBluePrimary,
      text: mtcColors.lightSkyThird,
      textSecondary: mtcColors.lightSkySecondary,
      background: mtcColors.lightSkyPrimary,
      buttonText: mtcColors.lightSkyThird,
      bgBreakpoint: [mtcColors.lightGreenBg1, mtcColors.lightGreenBg2],
      disclaimerBackground: mtcColors.lightSkyPrimary,
    },
    presale: {
      cardBackground: mtcColors.lightSkyPrimary,
      cardTitle: mtcColors.lightSkyThird,
      cardTitleSecondary: mtcColors.darkBlueSecondary,
      cardText: mtcColors.darkBluePrimary,
      addressValueText: mtcColors.darkBluePrimary,
      buttonBg: mtcColors.darkBluePrimary,
      buttonBgHover: mtcColors.lightYellowPrimaryHover,
      buttonBgDisabled: mtcColors.lightSkySecondary,

      buttonText: mtcColors.lightSkyPrimary,
      verifiedBg: mtcColors.lightGreenSecondary,
      warningBg: mtcColors.darkRedSecondary,
      commentText: mtcColors.lightSkySecondary,

      icon: mtcColors.darkBluePrimary,
      loadingBackground: mtcColors.lightWhitePrimary,
      loadingIcon: mtcColors.darkBluePrimary,

      border: mtcColors.darkBluePrimary,
      graph: mtcColors.darkBluePrimary,
      graphButton: mtcColors.darkBluePrimary,
      graphButtonHover: mtcColors.darkBlueSecondary,
      graphButtonText: mtcColors.darkBluePrimary,
      graphButtonTextSelected: mtcColors.white,
      locked: mtcColors.lightBlueFifth,
      withdrawn: mtcColors.lightBluePrimary,
      unlockTime: mtcColors.lightGraySecondary,
    },
    apply: {
      cardBackground: mtcColors.lightSkyPrimary,
      cardTitle: mtcColors.darkBluePrimary,
      cardTitleSecondary: mtcColors.darkBluePrimary,
      cardText: mtcColors.darkBluePrimary,
      addressValueText: mtcColors.darkBluePrimary,
      buttonBg: mtcColors.darkBluePrimary,
      buttonBgHover: mtcColors.lightYellowPrimaryHover,
      buttonBgDisabled: mtcColors.lightSkySecondary,

      buttonText: mtcColors.lightSkyPrimary,
      verifiedBg: mtcColors.lightGreenSecondary,
      warningBg: mtcColors.darkRedPrimary,
      commentText: mtcColors.lightSkySecondary,

      icon: mtcColors.darkBluePrimary,
    },
    admin: {
      cardBackground: mtcColors.lightSkyPrimary,
      cardTitle: mtcColors.lightSkyThird,
      cardTitleSecondary: mtcColors.lightSkyThird,
      cardText: mtcColors.lightSkyThird,
      addressValueText: mtcColors.darkBluePrimary,
      buttonBg: mtcColors.darkBluePrimary,
      buttonBgHover: mtcColors.lightYellowPrimaryHover,
      buttonBgDisabled: mtcColors.lightSkySecondary,

      buttonText: mtcColors.lightSkyPrimary,
      verifiedBg: mtcColors.lightGreenSecondary,
      warningBg: mtcColors.darkRedPrimary,
      commentText: mtcColors.lightSkySecondary,

      icon: mtcColors.darkBluePrimary,
    },
    stake: {
      background: mtcColors.lightSkyPrimary,
    },
    partner: {
      logo: mtcColors.lightSkyFourth,
    },
    swap: {
      background: mtcColors.lightSkyPrimary,
      border: mtcColors.lightSkyThird,
      inputBox: mtcColors.darkBluePrimary,
      maxBg: mtcColors.lightBlueFifth,
      maxHover: mtcColors.lightBlueSixth,
      maxText: mtcColors.lightBlueSeventh,
      header: mtcColors.darkBluePrimary,
      exchangeButton: mtcColors.lightBlueSixth,
    },
    dollarHighlight: mtcColors.lightBlueSecondary,
    divider: mtcColors.lightSkySecondary,
    backgroundColor: mtcColors.lightWhitePrimary,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${MaticBackgroundImage})`,
  logoImage: oplsLogoImg,
  idoImage: mtcIdoImg,
  lockImage: mtcLockImg,
  exchangeUrl: "QuickSwap",
};

const plsTheme = {
  type: "pls",
  chain: "PulseChain",
  chainName: "PulseChain Testnet V4",
  chainSymbol: "PLS",
  chainLogo: PlsIcon,
  chainId: 943,
  blockExplorer: "https://scan.v4.testnet.pulsechain.com/",
  rpcUrl: "https://rpc.v4.testnet.pulsechain.com",
  symbol: "PLS",
  swapSymbol: "OPLS",
  stakingToken: "OPLS",
  stakingTokenImage: oplsLogoImg,
  enabled: true,
  colors: {
    header: {
      background: plsColors.lightSkyPrimary,
      text: plsColors.darkBluePrimary,
      link: plsColors.blue,
      linkHover: plsColors.darkBlueSecondary,
      menuButton: plsColors.darkBluePrimary,
      boldBrownText: plsColors.darkBluePrimary,
      balanceText: plsColors.darkBluePrimary,
      balanceUsdText: plsColors.lightGraySixth,
      balanceValue: plsColors.darkBluePrimary,
      heading: plsColors.darkBluePrimary,
      textSecondary: plsColors.lightSkyThird,
      closeButton: plsColors.darkBluePrimary,
      tag: plsColors.darkBlueSecondary,
      linkSelected: plsColors.darkBlueSecondary,
      dropdownIcon: plsColors.darkBluePrimary,
      border: plsColors.darkBluePrimary,
      comingSoon: plsColors.lightGreenPrimary,
      comingSoonText: plsColors.white,
      borderColor: plsColors.darkBluePrimary,
      details: plsColors.lightBlueEighth,
      headerBackground:
        "linear-gradient(0deg, " +
        plsColors.lightSkySeventh +
        " 0%, " +
        plsColors.white +
        " 100%)",
      balanceBackground: plsColors.white,
      stakeBackground: plsColors.lightSkyEighth,
      tierBackground: plsColors.lightGrayPrimary,
      tierCircle: plsColors.lightGrayPrimary,
      tierCircleFilled: plsColors.darkBlueSecondary,
      tooltip: plsColors.darkBlack,
      callout: plsColors.purple,
    },
    footer: {
      background: plsColors.lightSkyPrimary,
      text: plsColors.white,
      textSecondary: plsColors.blue,
      icon: plsColors.white,
      border: plsColors.darkBluePrimary,
    },
    wallet: {
      buttonBackground: plsColors.darkBluePrimary,
      buttonBackgroundSecondary: plsColors.darkBlueSecondary,
      buttonBackgroundHover: plsColors.darkBlueSecondary,
      buttonText: plsColors.white,
      buttonTextSecondary: plsColors.darkBluePrimary,
    },
    home: {
      heading: plsColors.lightSkyThird,
      headingSecondary: plsColors.darkBluePrimary,
      text: plsColors.lightSkyThird,
      textSecondary: plsColors.lightSkySecondary,
      background: plsColors.lightSkyPrimary,
      buttonText: plsColors.lightSkyThird,
    },
    voting: {
      heading: plsColors.lightSkyThird,
      tableHeaderText: plsColors.lightWhitePrimary,
      text: plsColors.lightSkyThird,
      secondaryText: plsColors.darkBluePrimary,
      searchText: plsColors.lightSkyThird,
      border: plsColors.lightSkySecondary,
      searchMagnifier: plsColors.lightSkySecondary,
      tableHeader: plsColors.purple,
      tableBorder: plsColors.purple,
      tableCell: plsColors.lightWhitePrimary,
      tableRowHover: plsColors.lightSkySecondaryHover,
    },
    pools: {
      border: plsColors.purple,
      searchMagnifier: plsColors.purple,
      searchText: plsColors.white,
      text: plsColors.lightSkyThird,
      menuBackground: plsColors.lightSkyPrimary,
      menuBorder: plsColors.lightSkyPrimary,
      textSelected: plsColors.darkBluePrimary,
      paginationText: plsColors.darkBluePrimary,
      paginationItemSelectedBkg: plsColors.darkBluePrimary,
      paginationItemSelectedText: plsColors.lightSkyPrimary,
      paginationItemBorder: plsColors.darkBluePrimary,
    },
    poolCard: {
      background: plsColors.lightSkyThird,
      border: plsColors.darkBluePrimary,
      title: plsColors.lightSkyThird,
      textSecondary: plsColors.darkBluePrimary,
      statusBarBackground: plsColors.lightSkyThird,
      statusBarLabel: plsColors.lightSkySecondary,
      statusBarFilled: {
        GREEN: plsColors.darkBluePrimary,
        RED: plsColors.lightRedPrimary,
        YELLOW: plsColors.darkBluePrimary,
        ORANGE: plsColors.lightSkySixth,
      },
      poolType: {
        NOTOPENED: plsColors.darkBluePrimary,
        ONGOING: plsColors.darkBluePrimary,
        FILLED: plsColors.darkBluePrimary,
        CANCELLED: plsColors.lightRedSecondary,
        EXPIRED: plsColors.lightRedSecondary,
      },
      statusBarLabelSecondary: plsColors.lightSkyThird,
      poolTypeText: plsColors.white,
      linkIcon: plsColors.darkBluePrimary,
      linkIconHover: plsColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: plsColors.lightSkyThird,
      headingSecondary: plsColors.darkBluePrimary,
      text: plsColors.lightSkyThird,
      textSecondary: plsColors.lightSkySecondary,
      background: plsColors.lightSkyPrimary,
      buttonText: plsColors.lightSkyThird,
      bgBreakpoint: [plsColors.lightGreenBg1, plsColors.lightGreenBg2],
      disclaimerBackground: plsColors.lightSkyPrimary,
    },
    presale: {
      cardBackground: plsColors.darkRedThird,
      cardTitle: plsColors.lightSkyThird,
      cardTitleSecondary: plsColors.darkBlueSecondary,
      cardText: plsColors.darkBluePrimary,
      addressValueText: plsColors.darkBluePrimary,
      buttonBg: plsColors.darkBluePrimary,
      buttonBgHover: plsColors.lightYellowPrimaryHover,
      buttonBgDisabled: plsColors.lightSkySecondary,

      buttonText: plsColors.lightSkyPrimary,
      verifiedBg: plsColors.lightGreenSecondary,
      warningBg: plsColors.darkRedSecondary,
      commentText: plsColors.lightSkySecondary,

      icon: plsColors.darkBluePrimary,
      loadingBackground: plsColors.lightWhitePrimary,
      loadingIcon: plsColors.darkBluePrimary,

      border: plsColors.darkBluePrimary,
      graph: plsColors.darkBluePrimary,
      graphButton: plsColors.darkBluePrimary,
      graphButtonHover: plsColors.darkBlueSecondary,
      graphButtonText: plsColors.darkBluePrimary,
      graphButtonTextSelected: plsColors.white,
      locked: plsColors.lightBlueFifth,
      withdrawn: plsColors.lightBluePrimary,
      unlockTime: plsColors.lightGraySecondary,
    },
    apply: {
      cardBackground: plsColors.lightSkyPrimary,
      cardTitle: plsColors.white,
      cardTitleSecondary: plsColors.darkBluePrimary,
      cardText: plsColors.white,
      addressValueText: plsColors.darkBluePrimary,
      buttonBg: plsColors.darkBluePrimary,
      buttonBgHover: plsColors.lightYellowPrimaryHover,
      buttonBgDisabled: plsColors.lightSkySecondary,

      buttonText: plsColors.lightSkyPrimary,
      verifiedBg: plsColors.lightGreenSecondary,
      warningBg: plsColors.darkRedPrimary,
      commentText: plsColors.lightSkySecondary,

      icon: plsColors.purple,
    },
    admin: {
      cardBackground: plsColors.lightSkyPrimary,
      cardTitle: plsColors.lightSkyThird,
      cardTitleSecondary: plsColors.lightSkyThird,
      cardText: plsColors.lightSkyThird,
      addressValueText: plsColors.darkBluePrimary,
      buttonBg: plsColors.darkBluePrimary,
      buttonBgHover: plsColors.lightYellowPrimaryHover,
      buttonBgDisabled: plsColors.lightSkySecondary,

      buttonText: plsColors.lightSkyPrimary,
      verifiedBg: plsColors.lightGreenSecondary,
      warningBg: plsColors.darkRedPrimary,
      commentText: plsColors.lightSkySecondary,

      icon: plsColors.darkBluePrimary,
    },
    stake: {
      background: plsColors.lightGrayThird,
    },
    partner: {
      logo: plsColors.lightSkyFourth,
    },
    swap: {
      background: plsColors.lightSkyPrimary,
      border: plsColors.lightSkyThird,
      inputBox: plsColors.darkBluePrimary,
      maxBg: plsColors.lightBlueFifth,
      maxHover: plsColors.lightBlueSixth,
      maxText: plsColors.lightBlueSeventh,
      header: plsColors.darkBluePrimary,
      exchangeButton: plsColors.lightBlueSixth,
    },
    dollarHighlight: plsColors.lightBlueSecondary,
    divider: plsColors.lightSkySecondary,
    backgroundColor: plsColors.lightWhitePrimary,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${PlsBackgroundImage})`,
  backgroundRepeat: "repeat-x",
  logoImage: oplsLogoImg,
  idoImage: mtcIdoImg,
  lockImage: mtcLockImg,
  exchangeUrl: "PulseX",
};

const baseThemeLight = {
  type: "base",
  chain: "Base",
  chainSymbol: "ETH",
  chainLogo: BaseIcon,

  ...(ENVIRONMENT === "mainnet"
    ? {
        chainName: "Base Mainnet",
        chainId: 8453,
        blockExplorer: "https://basescan.org/",
        rpcUrl: "https://base-rpc.publicnode.com",
      }
    : {
        chainName: "Base Sepolia",
        chainId: 84532,
        blockExplorer: "https://sepolia.basescan.org/",
        rpcUrl: "https://base-sepolia.publicnode.com",
      }),

  guide: "https://docs.starter.xyz/overview/about-the-team",
  lp: "https://v1exchange.pancakeswap.finance/#/add/BNB/0x31d0a7ada4d4c131eb612db48861211f63e57610",
  symbol: "ETH",
  swapSymbol: "USDC",
  stakingToken: "BUIDL",
  stakingTokenImage: buidlLogoImg,
  enabled: true,
  colors: {
    header: {
      backgroundColor: "#FCFCFD",
      background: "linear-gradient(180deg,#2c1143,#350859 120.56%)",
      text: baseColors.white,
      link: baseColors.white,
      linkHover: baseColors.lightPumpkin,
      linkHoverBg: baseColors.lightYellowPrimaryHover,
      menuButton: "#617CBF",
      boldBrownText: baseColors.lightYellowPrimary,
      bodyContainer: "#fcfcfd",
      balanceText: baseColors.lightYellowPrimary,
      balanceUsdText: baseColors.lightGraySecondary,
      balanceValue: baseColors.lightYellowPrimary,
      heading: baseColors.white,
      borderColor: baseColors.lightYellowPrimary,
      textSecondary: "#fafafa",
      closeButton: "#d0d0d0",
      tag: baseColors.lightYellowThird,
      darkBluePrimary: baseColors.white,
      linkSelected: baseColors.white,
      border: baseColors.darkBlack,
      comingSoon: baseColors.lightGreenPrimary,
      comingSoonText: baseColors.white,
      details: baseColors.lightYellowPrimaryHover,
      headerBackground:
        "linear-gradient(0deg, " +
        baseColors.black +
        " 0%, " +
        baseColors.darkBlack +
        " 100%)",
      balanceBackground: baseColors.darkBlack,
      stakeBackground: baseColors.darkBlack,
      tierBackground: baseColors.darkBlack,
      tierCircle: baseColors.darkBlack,
      tierCircleFilled: baseColors.lightGrayPrimary,
      tooltip: baseColors.lightGrayPrimary,
    },
    infoCard: {
      borderColor: baseColors.lightBlueFifth,
      boxShadow: "rgba(57,118,247,0.3)",
      backgroundGradient:
        "linear-gradient(90deg, rgba(57,118,247,0.6) 0%, rgba(97,124,191,0.7) 50%, rgba(51,117,255,0.6) 100%)",
    },

    disclaimerCard: {
      borderColor: baseColors.lightBlueFifth,
      boxShadow: "rgba(57,118,247,0.3)",

      backgroundGradient:
        "linear-gradient(90deg, rgba(51,117,255,0.6) 0%, rgba(75, 112, 204, 0.7) 25%, rgba(75, 112, 204, 0.7) 50%, rgba(75, 112, 204, 0.7) 75%, rgba(51,117,255,0.6) 100%)",
    },

    footer: {
      background: baseColors.blackPrimary,
      text: baseColors.lightGrayPrimary,
      textSecondary: baseColors.lightGraySecondary,
      icon: baseColors.lightYellowSecondary,
      border: baseColors.darkBlack,
      backgroundColor: "#fcfcfd",
      brandName: "#30354B",
      tokenBalance: "#8D97AD",
    },
    wallet: {
      buttonBorder: baseColors.lightYellowPrimaryBackground,
      buttonBackground: baseColors.lightYellowPrimaryBackground,
      buttonBackgroundSecondary: baseColors.lightYellowPrimaryHover,
      buttonTextTertiary: "#30354B",
      buttonBackgroundHover: "#7f92c3",
      buttonText: baseColors.lightYellowPrimaryBackground,
      buttonTextSecondary: "#fff",
      buttonBorderSecondary: "#D9E0EF",
    },
    home: {
      summaryContainer: "transparent",
      heading: "#30354B",
      infoBoxBackground: "#fcfcfd",
      infoBoxDescription: "#30354B",
      headingShadow: baseColors.lightYellowPrimary,
      headingSecondary: baseColors.lightYellowPrimary,
      text: baseColors.lightGrayPrimary,
      textSecondary: baseColors.lightGrayPrimary,
      background: baseColors.blackPrimary,
      buttonText: baseColors.white,
      aluminiBg: baseColors.blackPrimary,
    },
    voting: {
      heading: baseColors.white,
      tableHeaderText: baseColors.white,
      text: baseColors.white,
      secondaryText: baseColors.lightYellowPrimary,
      searchText: baseColors.lightGrayPrimary,
      border: baseColors.lightGrayPrimaryTrans,
      searchMagnifier: baseColors.lightGraySecondary,
      tableHeader: baseColors.lightGrayThird,
      tableBorder: baseColors.darkBluePrimary,
      tableCell: baseColors.lightBlackPrimary,
      tableRowHover: baseColors.lightBlueFourth,
    },
    pools: {
      border: baseColors.lightGrayPrimaryTrans,
      searchMagnifier: baseColors.lightGraySecondary,
      searchText: baseColors.lightGrayPrimary,
      text: baseColors.lightGrayPrimary,
      menuBackground: baseColors.lightBlackPrimary,
      menuBorder: baseColors.blackPrimary,
      textSelected: baseColors.lightYellowPrimary,
      paginationText: baseColors.lightYellowPrimary,
      paginationItemSelectedBkg: baseColors.lightYellowPrimary,
      paginationItemSelectedText: baseColors.lightBlackPrimary,
      paginationItemBorder: baseColors.lightYellowPrimary,
      headerBackground: "#FCFCFD",
      bodyBackground: "#fff",
      rowBorderBottom: "#EDEDED",
      tableDataText: "#30354B",
      status: "#fff",
    },
    poolCard: {
      background: baseColors.white,
      boxShadow: "inset 0 4px 34px rgb(47 109 254 / 40%)",
      border: baseColors.darkBluePrimary,
      title: "#30354B",
      titleSecondary: "#fff",
      perPrice: "#8D97AD",
      backgroundColor: "#FCFCFD",
      textSecondary: baseColors.lightYellowPrimary,
      statusBarBackground: baseColors.statusBarBackgroundGray,
      statusBarLabel: baseColors.lightGraySecondary,
      statusBarFilled: {
        GREEN: "#00c76b",
        DARK_GREEN: "#044326",
        RED: baseColors.lightRedPrimary,
        YELLOW: "#F0B900",
        ORANGE: "#f37921",
      },
      poolType: {
        NOTOPENED: baseColors.lightBluePrimary,
        ONGOING: baseColors.lightYellowPrimary,
        FILLED: baseColors.lightGreenPrimary,
        CANCELLED: baseColors.lightRedSecondary,
        EXPIRED: baseColors.lightRedSecondary,
      },
      statusBarLabelSecondary: baseColors.white,
      poolTypeText: "#fff",
      linkIcon: baseColors.lightYellowPrimary,
      linkIconHover: baseColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: baseColors.white,
      headingSecondary: baseColors.lightYellowPrimary,
      text: baseColors.lightGrayPrimary,
      textSecondary: baseColors.lightGraySecondary,
      background: baseColors.blackPrimary,
      buttonText: baseColors.white,
      bgBreakpoint: [baseColors.lightGreenBg1, baseColors.lightGreenBg2],
      disclaimerBackground: baseColors.darkBlack,
    },
    presale: {
      fundraiseGoalTextSecondary: "#9AA0AD",
      fundraiseGoalTextPrimary: "#30354B",
      linkText: "#547ACA",
      cardBackground: "#fff",
      cardTitle: "#30354B",
      cardTitleSecondary: "#8D97AD",
      cardTitleTertiary: "#fff",
      percentageBarBackground: "#EDEDED",
      divider: "#EDEDED",
      cardText: "#9AA0AD",
      addressValueText: baseColors.lightYellowPrimary,
      buttonBg: baseColors.lightYellowPrimary,
      buttonBgHover: baseColors.lightYellowPrimaryHover,
      buttonBgDisabled: baseColors.lightGraySecondary,

      buttonText: baseColors.lightBlackPrimary,
      verifiedBg: baseColors.lightGreenSecondary,
      warningBg: baseColors.darkRedSecondary,
      commentText: baseColors.lightGraySecondary,

      icon: baseColors.lightYellowPrimary,
      loadingBackground: "rgba(255,255,255,0)",
      loadingIcon: "#617CBF",
    },
    apply: {
      cardBackground: baseColors.darkBlack,
      cardTitle: baseColors.white,
      cardTitleSecondary: baseColors.lightGrayPrimary,
      cardText: baseColors.white,
      addressValueText: baseColors.lightYellowPrimary,
      buttonBg: baseColors.lightYellowPrimary,
      buttonBgHover: baseColors.lightYellowPrimaryHover,
      buttonBgDisabled: baseColors.lightGraySecondary,

      buttonText: baseColors.lightBlackPrimary,
      verifiedBg: baseColors.lightGreenSecondary,
      warningBg: baseColors.darkRedPrimary,
      commentText: baseColors.lightGraySecondary,

      icon: baseColors.lightYellowPrimary,
    },
    createPool: {
      contentContainerBackground: "#fff",
      inputLabel: "#30354B",
      inputBorder: "#EDEDED",
      subTitleText: "#30354B",
    },
    admin: {
      cardBackground: baseColors.darkBlack,
      cardTitle: baseColors.white,
      cardTitleSecondary: baseColors.lightGrayPrimary,
      cardText: baseColors.white,
      addressValueText: baseColors.lightYellowPrimary,
      buttonBg: baseColors.lightYellowPrimary,
      buttonBgHover: baseColors.lightYellowPrimaryHover,
      buttonBgDisabled: baseColors.lightGraySecondary,

      buttonText: baseColors.lightBlackPrimary,
      verifiedBg: baseColors.lightGreenSecondary,
      warningBg: baseColors.darkRedPrimary,
      commentText: baseColors.lightGraySecondary,

      icon: baseColors.lightYellowPrimary,
    },
    stake: {
      background: "#fff",
    },
    partner: {
      logo: baseColors.lightBlackPrimary,
    },
    swap: {
      background: "#fff",
      border: "#EDEDED",
      inputBox: "#30354B",
      maxBg: "transparent",
      maxBorder: "#617CBF",
      maxHover: baseColors.lightBlueSixth,
      maxText: "#617CBF",
      header: "#30354B",
      exchangeButton: "#617CBF",
    },
    aboutCard: {
      title: "#30354B",
    },

    networkContent: {
      title: "#30354B",
      textColor: "#30354B",
      backgroundColor: "#fff",
    },

    containerShadow: "#8F97AB40",
    dollarHighlight: baseColors.lightBlueSecondary,
    divider: baseColors.lightGraySecondary,
    backgroundColor: baseColors.darkBlack,
    linkText: "#30354B",
    modal: {
      text: "#30354B",
      title: "#30354B",
    },
    modalBackground: "#fff",
  },
  breakpoints: breakpoints,
  logoImage: buidlLogoImg,
  idoImage: baseIdoImg,
  lockImage: baseLockImg,
  swapSymbolImage: UsdcIcon,
  exchangeUrl: "Uniswap",
};

const baseThemeDark = {
  type: "base",
  chain: "Base",
  chainSymbol: "ETH",
  chainLogo: BaseIcon,

  ...(ENVIRONMENT === "mainnet"
    ? {
        chainName: "Base Mainnet",
        chainId: 8453,
        blockExplorer: "https://basescan.org/",
        rpcUrl: "https://base-rpc.publicnode.com",
      }
    : {
        chainName: "Base Sepolia",
        chainId: 84532,
        blockExplorer: "https://sepolia.basescan.org/",
        rpcUrl: "https://base-sepolia.publicnode.com",
      }),

  guide: "https://docs.starter.xyz/overview/about-the-team",
  lp: "https://v1exchange.pancakeswap.finance/#/add/BNB/0x31d0a7ada4d4c131eb612db48861211f63e57610",
  symbol: "ETH",
  swapSymbol: "USDC",
  stakingToken: "BUIDL",
  stakingTokenImage: buidlLogoImg,
  enabled: true,
  colors: {
    header: {
      backgroundColor: "#13161b",

      bodyContainer: "#121212",
      background: "linear-gradient(180deg,#2c1143,#350859 120.56%)",
      text: baseColors.white,
      link: baseColors.white,
      linkHover: baseColors.lightPumpkin,
      linkHoverBg: baseColors.lightYellowPrimaryHover,
      boldBrownText: baseColors.lightYellowPrimary,
      balanceText: baseColors.lightYellowPrimary,
      balanceUsdText: baseColors.lightGraySecondary,
      balanceValue: baseColors.lightYellowPrimary,
      heading: baseColors.white,
      borderColor: baseColors.lightYellowPrimary,
      textSecondary: "#fafafa",
      closeButton: baseColors.white,
      tag: baseColors.lightYellowThird,
      darkBluePrimary: baseColors.white,
      linkSelected: baseColors.white,
      border: baseColors.darkBlack,
      comingSoon: baseColors.lightGreenPrimary,
      comingSoonText: baseColors.white,
      details: baseColors.lightYellowPrimaryHover,
      headerBackground:
        "linear-gradient(0deg, " +
        baseColors.black +
        " 0%, " +
        baseColors.darkBlack +
        " 100%)",
      balanceBackground: baseColors.darkBlack,
      stakeBackground: baseColors.darkBlack,
      tierBackground: baseColors.darkBlack,
      tierCircle: baseColors.darkBlack,
      tierCircleFilled: "#fafafa",
      tooltip: "#fafafa",
      menuButton: "#617CBF",
    },
    footer: {
      brandName: "#fff",
      tokenBalance: "#8D97AD",
      backgroundColor: "#242424",
      background: baseColors.blackPrimary,
      text: "#fafafa",
      textSecondary: baseColors.lightGraySecondary,
      icon: baseColors.lightYellowSecondary,
      border: baseColors.darkBlack,
    },
    wallet: {
      text: "#fff",
      buttonBorder: "#fff",
      buttonBackground: baseColors.lightYellowPrimaryBackground,
      buttonBackgroundSecondary: baseColors.lightYellowPrimaryHover,
      buttonTextTertiary: "#fff",
      buttonText: baseColors.lightYellowPrimaryBackground,
      buttonTextSecondary: baseColors.white,
      buttonBorderSecondary: "#D9E0EF",
      buttonBackgroundHover: "#7f92c3",
    },
    home: {
      heading: baseColors.white,
      infoBoxBackground: "#242424",
      infoBoxDescription: "#FCFCFD",
      summaryContainer: "#242424",

      headingShadow: baseColors.lightYellowPrimary,
      headingSecondary: baseColors.lightYellowPrimary,
      text: "#fafafa",
      textSecondary: baseColors.lightGraySecondary,
      background: baseColors.blackPrimary,
      buttonText: baseColors.white,
      aluminiBg: baseColors.blackPrimary,
    },
    voting: {
      heading: baseColors.white,
      tableHeaderText: baseColors.lightGraySecondary,
      text: "#fafafa",
      secondaryText: baseColors.lightYellowPrimary,
      searchText: "#fafafa",
      border: baseColors.lightGrayPrimaryTrans,
      searchMagnifier: baseColors.lightGraySecondary,
      tableHeader: baseColors.lightGrayThird,
      tableBorder: baseColors.lightGrayFourth,
      tableCell: baseColors.lightBlackPrimary,
      tableRowHover: baseColors.lightBlueFourth,
    },
    pools: {
      border: baseColors.lightGrayPrimaryTrans,
      searchMagnifier: baseColors.lightGraySecondary,
      searchText: "#fafafa",
      text: "#fafafa",
      menuBackground: baseColors.lightBlackPrimary,
      menuBorder: baseColors.blackPrimary,
      textSelected: baseColors.lightYellowPrimary,
      paginationText: baseColors.lightYellowPrimary,
      paginationItemSelectedBkg: baseColors.lightYellowPrimary,
      paginationItemSelectedText: baseColors.lightBlackPrimary,
      paginationItemBorder: baseColors.lightYellowPrimary,
      headerBackground: "#393939",
      bodyBackground: "#242424",
      rowBorderBottom: "#393939",
      tableDataText: "#fff",
      status: "#fff",
    },
    poolCard: {
      background: baseColors.lightYellowPrimaryBackground,
      boxShadow: "inset 0 4px 34px rgb(243 38 73 / 40%)",
      border: baseColors.lightGrayFourth,
      title: "#fafafa",
      titleSecondary: "#fafafa",
      backgroundColor: "#242424",
      textSecondary: baseColors.lightYellowPrimary,
      statusBarBackground: baseColors.statusBarBackgroundGray,
      statusBarLabel: baseColors.lightGraySecondary,
      perPrice: "#A0A0A0",
      statusBarFilled: {
        RED: baseColors.lightRedPrimary,
        GREEN: "#00c76b",
        DARK_GREEN: "#044326",
        YELLOW: "#F0B900",
        ORANGE: "#f37921",
      },
      poolType: {
        NOTOPENED: baseColors.lightBluePrimary,
        ONGOING: baseColors.lightYellowPrimary,
        FILLED: baseColors.lightGreenPrimary,
        CANCELLED: baseColors.lightRedSecondary,
        EXPIRED: baseColors.lightRedSecondary,
      },
      statusBarLabelSecondary: baseColors.white,
      poolTypeText: "#fafafa",
      linkIcon: baseColors.lightYellowPrimary,
      linkIconHover: baseColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: baseColors.white,
      headingSecondary: baseColors.lightYellowPrimary,
      text: "#fafafa",
      textSecondary: baseColors.lightGraySecondary,
      background: baseColors.blackPrimary,
      buttonText: baseColors.white,
      bgBreakpoint: [baseColors.lightGreenBg1, baseColors.lightGreenBg2],
      disclaimerBackground: baseColors.darkBlack,
    },
    presale: {
      fundraiseGoalTextSecondary: "#cdcdcd",
      fundraiseGoalTextPrimary: baseColors.white,

      cardTitleTertiary: "#fff",
      cardBackground: baseColors.darkBlack,
      cardTitle: baseColors.white,
      addressValueText: baseColors.lightYellowPrimary,
      buttonBg: baseColors.lightYellowPrimary,
      buttonBgHover: baseColors.lightYellowPrimaryHover,
      buttonBgDisabled: baseColors.lightGraySecondary,
      buttonText: baseColors.lightBlackPrimary,
      verifiedBg: baseColors.lightGreenSecondary,
      warningBg: baseColors.darkRedSecondary,
      commentText: "#d5d5d5",

      icon: "#617CBF",
      loadingBackground: baseColors.darkBlackTransparent,

      linkText: "#617CBF",
      cardTitleSecondary: "#cdcdcd",
      cardText: "#cdcdcd",
      percentageBarBackground: baseColors.white,
      divider: baseColors.statusBarBackgroundGray,

      loadingIcon: "#617CBF",
    },
    apply: {
      cardBackground: baseColors.darkBlack,
      cardTitle: baseColors.white,
      cardTitleSecondary: "#fafafa",
      cardText: baseColors.white,
      addressValueText: baseColors.lightYellowPrimary,
      buttonBg: baseColors.lightYellowPrimary,
      buttonBgHover: baseColors.lightYellowPrimaryHover,
      buttonBgDisabled: baseColors.lightGraySecondary,

      buttonText: baseColors.lightBlackPrimary,
      verifiedBg: baseColors.lightGreenSecondary,
      warningBg: baseColors.darkRedPrimary,
      commentText: baseColors.lightGraySecondary,

      icon: baseColors.lightYellowPrimary,
    },
    infoCard: {
      borderColor: baseColors.lightBlueFifth,
      boxShadow: "rgba(57,118,247,0.3)",
      backgroundGradient:
        "linear-gradient(90deg, rgba(57,118,247,0.6) 0%, rgba(97,124,191,0.7) 50%, rgba(51,117,255,0.6) 100%)",
    },

    disclaimerCard: {
      borderColor: baseColors.lightBlueFifth,
      boxShadow: "rgba(57,118,247,0.3)",

      backgroundGradient:
        "linear-gradient(90deg, rgba(51,117,255,0.6) 0%, rgba(75, 112, 204, 0.7) 25%, rgba(75, 112, 204, 0.7) 50%, rgba(75, 112, 204, 0.7) 75%, rgba(51,117,255,0.6) 100%)",
    },

    createPool: {
      contentContainerBackground: "#242424",
      inputLabel: "#FFFFFF",
      inputBorder: "#393939",
      subTitleText: "#FFFFFF",

      datetimeSchema: "dark",
    },
    admin: {
      cardBackground: baseColors.darkBlack,
      cardTitle: baseColors.white,
      cardTitleSecondary: baseColors.white,
      cardText: baseColors.white,
      addressValueText: baseColors.lightYellowPrimary,
      buttonBg: baseColors.lightYellowPrimary,
      buttonBgHover: baseColors.lightYellowPrimaryHover,
      buttonBgDisabled: baseColors.lightGraySecondary,

      buttonText: baseColors.lightBlackPrimary,
      verifiedBg: baseColors.lightGreenSecondary,
      warningBg: baseColors.darkRedPrimary,
      commentText: baseColors.lightGraySecondary,

      icon: baseColors.lightYellowPrimary,
    },
    stake: {
      background: baseColors.lightBlackPrimary,
    },
    partner: {
      logo: baseColors.lightBlackPrimary,
    },
    swap: {
      background: baseColors.lightBlackPrimary,
      border: baseColors.lightGrayFifth,
      inputBox: baseColors.lightWhitePrimary,
      maxBg: baseColors.lightBlueFifth,
      maxBorder: baseColors.lightBlueFifth,
      maxHover: baseColors.lightBlueSixth,
      maxText: baseColors.lightBlueSeventh,
      header: baseColors.lightWhitePrimary,
      exchangeButton: "#617CBF",
    },
    aboutCard: {
      title: "#fff",
    },

    networkContent: {
      title: "#fff",

      textColor: "#fff",
      backgroundColor: "#242424",
    },

    containerShadow: "transparent",
    dollarHighlight: baseColors.lightBlueSecondary,
    divider: baseColors.lightGraySecondary,
    linkText: "#FFFFFF",
    modal: {
      text: "#fff",
      title: "#fff",
    },
    modalBackground: "#242424",
    backgroundColor: baseColors.darkBlack,
  },
  breakpoints: breakpoints,
  logoImage: buidlLogoImg,
  idoImage: baseIdoImg,
  lockImage: baseLockImg,
  swapSymbolImage: UsdcIcon,
  exchangeUrl: "Uniswap",
};

export { bscTheme, mtcTheme, plsTheme, baseThemeDark, baseThemeLight };
