import STARTokenAbi from "./abis/startToken";
import SeedPresaleAbi from "./abis/seedPresale";
import PrivatePresaleAbi from "./abis/privatePresale";
import StrategicPresaleAbi from "./abis/strategicPresale";
import ERC20Abi from "./abis/erc20";
import factoryAbi from "./abis/factory";
import factoryV2Abi from "./abis/factoryV2";
import factoryV3Abi from "./abis/factoryV3";
import starterInfoAbi from "./abis/starterInfo";
import starterInfoV3Abi from "./abis/starterInfoV3";
import vestStakingAbi from "./abis/vestStaking";
import starterPresaleAbi from "./abis/starterPresale";
import starterPresaleV2Abi from "./abis/starterPresaleV2";
import starterPresaleV3Abi from "./abis/starterPresaleV3";

import vestingAbi from "./abis/vesting";
import wsbTokenAbi from "./abis/wsb";
import wsbPrivatePresaleAbi from "./abis/wsbPrivatePresale";
import wiseTokenAbi from "./abis/wise";
import wisePublicPresaleAbi from "./abis/wisePublicPresale";
import vestTokenAbi from "./abis/vest";
import vestPrivateSaleAbi from "./abis/vestPrivateSale";
import vestSeedSaleAbi from "./abis/vestSeed";

import farmingAbi from "./abis/farming";
import farmingPairAbi from "./abis/farmingPair";

import starterVestingAbi from "./abis/starterVesting";

const config = {
  STARTokenAddress: "0x31d0a7ada4d4c131eb612db48861211f63e57610",
  STARTokenAbi,
  SeedPresaleAddress: "0x2019459B04E37436E7EdC3D5d6346453d08606Fe",
  SeedPresaleAbi,
  PrivatePresaleAddress: "0x3b447f280D03761De72AE1d1640C4E3D4e6D0909",
  PrivatePresaleAbi,
  StrategicPresaleAddress: "0x58DAB443fc4E7977dD09EA4cD83944a60f17C283",
  StrategicPresaleAbi,
  ERC20Abi,
  factoryAbi,
  factoryAddress: "0x34Cc2A333d4A6086726B3d84220eA15d78Ca1962",
  factoryV2Abi,
  factoryV2Address: "0x43a953884f7C2bB63f74F921b266f5174195afe3",
  factoryV3Abi,
  factoryV3Address: "0xC4Bc9098C6a5ec1Ed12C3C8beB201ff7Cd271290",

  starterInfoAddress: "0x135C2C8956Af96c41Ac6BF7ae76e2BCcD526FDBe",
  starterInfoAbi,

  starterInfoV3Address: "0x9AB4268495934F3C64896E817d137F969023296f",
  starterInfoV3Abi,

  vestStaking: "0x691d04d100850f05d626f8076a2b2e85d69b6f2e",
  vestStakingAbi,

  starterPresaleAbi,
  starterPresaleV2Abi,
  starterPresaleV3Abi,
  vestingAddress: "0x3C24256B2cE30c88c29cc57e273958038B62222a",
  vestingAbi,

  wsbToken: "0x22168882276e5D5e1da694343b41DD7726eeb288",
  wsbTokenAbi,
  wsbPrivatePresaleAddress: "0x84E736a4cCC7FB032d671B2877d6210E3B64F105",
  wsbPrivatePresaleAbi,

  wiseToken: "0x0000000000000000000000000000000000000000",
  wiseTokenAbi,
  wisePublicPresaleAddress: "0xbB064642C34C3FDE27fba69940B0de5FE5445609",
  wisePublicPresaleAbi,

  vestTokenAddress: "0x5ff584f11b3dc5bc26cd099239ced7eaa2d4e581",
  vestTokenAbi,

  vestPrivateSale: "0x79E9042C67E1672869D0D745080A239c8FED8c38",
  vestPrivateSaleAbi,
  vestSeedSale: "0xF9A761254481796F6B8b0DE711A5029215775f93",
  vestSeedSaleAbi,

  starterVestingAddress: "0x0e7b582003de0E541548cF02a1F00725Df6E6E6f",
  starterVestingAbi,
  farmingAddress: "0x38E85adcaBdFe9f07AD155147EB5F55582011FD0",
  farmingAbi,
  farmingPairAddress: "0x67b78318db88eeb6c9271816114d3ca6423fd66d",
  farmingPairAbi,
  wbnbAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
};

export default config;
