import STARTokenAbi from "./abis/startToken";
import ERC20Abi from "./abis/erc20";
import factoryV3Abi from "./abis/factoryV3";
import starterInfoAbi from "./abis/starterInfo";
import starterInfoV3Abi from "./abis/starterInfoV3";
import vestStakingAbi from "./abis/vestStaking";

import vestingAbi from "./abis/vesting";
import vestTokenAbi from "./abis/vest";
import vestPrivateSaleAbi from "./abis/vestPrivateSale";
import vestSeedSaleAbi from "./abis/vestSeed";

import farmingAbi from "./abis/farming";
import farmingPairAbi from "./abis/farmingPair";

import starterVestingAbi from "./abis/starterVesting";

const config = {
  STARTokenAddress: "0x31d0a7ada4d4c131eb612db48861211f63e57610",
  STARTokenAbi,
  ERC20Abi,
  factoryV3Abi,
  factoryV3Address: "0xC4Bc9098C6a5ec1Ed12C3C8beB201ff7Cd271290",

  starterInfoAddress: "0x135C2C8956Af96c41Ac6BF7ae76e2BCcD526FDBe",
  starterInfoAbi,

  starterInfoV3Address: "0x9AB4268495934F3C64896E817d137F969023296f",
  starterInfoV3Abi,

  vestStaking: "0x1251c2934D84BECD776069224c8F538B3979A47B",
  vestStakingAbi,

  vestingAddress: "0x3C24256B2cE30c88c29cc57e273958038B62222a",
  vestingAbi,

  vestTokenAddress: "0xa56c978be266b1a9ab7b8982050d500505eeae61",
  vestTokenAbi,

  vestPrivateSale: "0x79E9042C67E1672869D0D745080A239c8FED8c38",
  vestPrivateSaleAbi,
  vestSeedSale: "0xF9A761254481796F6B8b0DE711A5029215775f93",
  vestSeedSaleAbi,

  starterVestingAddress: "0x347c017b97db9ba86892f24740c8ee7871ea8830",
  starterVestingAbi,
  farmingAddress: "0x38E85adcaBdFe9f07AD155147EB5F55582011FD0",
  farmingAbi,
  farmingPairAddress: "0x67b78318db88eeb6c9271816114d3ca6423fd66d",
  farmingPairAbi,
  wbnbAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
};

export default config;
