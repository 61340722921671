import React, { useEffect, useState } from "react";
import bigInt from "big-integer";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Dialog,
  Slide,
  Button,
  Typography,
  Input,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { toFixed, fetchTokenData } from "../../../constants/constants";
import EmptyTokenImage from "../../../assets/base-logo.png";
import Web3 from "web3";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  screenMask: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  walletButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
    },

    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    height: "40px",
    width: "130px",
    padding: "8px",

    fontFamily: "Poppins",
    letterSpace: "0px",
    whiteSpace: "nowrap",
  },
  cancelButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    textTransform: "capitalize",
    height: "40px",
    width: "130px",
    color: theme?.colors?.wallet?.buttonText,
    padding: "8px",
    "&:hover": {
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonBackgroundHover,
    },
    fontFamily: "Poppins",
    letterSpace: "0px",
    whiteSpace: "nowrap",
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },

  priceHeader: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  inputHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  inputHeading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "10px",
  },
  priceText: {
    color: theme?.colors?.pools?.searchText,
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "20px",
  },
  minMaxText: {
    color: theme?.colors?.presale?.commentText,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    marginBottom: "16px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    marginBottom: "16px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "28px",
    },
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    position: "relative",
    width: "100%",
    marginBottom: "10px",
    [theme.breakpoints.up("ms")]: {
      flexDirection: "row",
    },
  },
  actionInput: {
    border: "solid 1px " + theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    paddingRight: "70px",
    color: theme?.colors?.presale.cardTitleSecondary,
    outline: "0px",
  },
  tokenInput: {
    border: "solid 1px " + theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: theme?.colors?.presale.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.presale.cardTitleSecondary,
  },
  maxButton: {
    position: "absolute",
    right: "0px",
    borderRadius: "0px 12px 12px 0px",
    height: "50px",
    backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    width: "70px",
    padding: "14px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackground,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  dayInput: {
    position: "absolute",
    right: "16px",
    top: "16px",
    color: theme?.colors?.wallet?.buttonBackground,
    fontSize: "15px",
    fontWeight: "bold",

    lineHeight: "18px",
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
  },
  lockContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
  },
  stakingAmountLine: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",

    marginBottom: "16px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "28px",
    },
  },
  textInput: {
    background: "transparent",
    border: "2px solid" + theme?.colors?.createPool?.inputBorder,
    color: theme?.colors?.createPool?.inputLabel,

    width: "100%",
    borderRadius: "20px",
    padding: "16px",
  },
  stakingMaxButton: {
    position: "absolute",
    right: "16px",
    fontFamily: "Poppins",
    fontSize: "18px",
    letterSpacing: "0px",
    color: "#A0A0A0",
    cursor: "pointer",
    "&:hover": {
      color: theme?.colors?.presale?.cardTitle,
    },
  },
  tokenInfoContainer: {
    width: "100%",
    display: "flex",
    border: "1px",
    borderRadius: "5px",
    padding: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme?.colors?.pools?.searchMagnifier,
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
    },
  },
  tokenNameSymbolContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  tokenImage: {
    width: "20px",
    height: "20px",
    marginRight: "5px",
  },
  tokenSymbolName: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  tokenSymbol: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    color: theme?.colors?.pools?.searchText,
  },
  tokenName: {
    fontSize: "12px",
    lineHeight: "14px",
    whiteSpace: "nowrap",
    fontWeight: "normal",
    color: theme?.colors?.pools?.searchText,
  },

  root: {
    borderRadius: "34px !important",
    padding: "32px 48px 24px 48px",
    position: "relative",
  },
  paper: {
    background: theme?.colors?.swap?.background,
    borderRadius: "30px",
    boxShadow:
      "0px 10px 60px " + theme?.colors?.containerShadow + " !important",
  },
  modalRoot: {
    maxWidth: "640px",
    [theme.breakpoints.up("md")]: {
      borderRadius: "30px",
    },
  },
  modalCloseIcon: {
    width: "28px",
    height: "28px",
    position: "absolute",
    top: "25px",
    right: "25px",
    color: theme?.colors?.presale?.cardTitle,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  modalHeadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "8px 24px 0px",
  },
  modalHeadingLogoName: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    width: "100%",
  },
  modalHeadingLg: {
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "25px",
    letterSpacing: "0px",
    color: theme?.colors?.presale?.cardTitle,
    textTransform: "capitalize",
    fontWeight: 600,
  },
  modalSubHeader: {
    color: "#A0A0A0",
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "20px",
  },
});

const LockModal = ({
  classes,
  closeModal,
  modalOpen,
  onLock,
  account,
  store,
}) => {
  const [inputValue, setInputValue] = useState(0);
  const [unlockDays, setUnlockDays] = useState(30);
  const [unlockDate, setUnlockDate] = useState(
    parseInt(Date.now() / 1000) + 2592000
  );
  const [maximumAmount, setMaximumAmount] = useState(0);
  const [tokenAddress, setTokenAddress] = useState("");
  const [poolInfo, setPoolInfo] = useState({});

  const fullScreen = window.innerWidth < 900;

  const getUnlockTime = (timestamp) => {
    const unlockTime = new Date(parseInt(timestamp) * 1000);
    return unlockTime.toLocaleDateString();
  };

  useEffect(() => {
    if (Web3.utils.isAddress(tokenAddress)) {
      store.fetchTokenData(tokenAddress, account).then((_poolInfo) => {
        setPoolInfo(_poolInfo);
      });
    }
  }, [tokenAddress, account]);

  useEffect(() => {
    setMaximumAmount(
      parseFloat(toFixed(bigInt(poolInfo?.tokenBalance), poolInfo?.decimals, 5))
    );
  }, [poolInfo]);

  useEffect(() => {
    if (unlockDays) {
      setUnlockDate(
        parseInt(Date.now() / 1000) + 3600 * 24 * parseInt(unlockDays)
      );
    }
  }, [unlockDays]);

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      classes={{ paper: classes.paper, paperWidthSm: classes.modalRoot }}
    >
      <DialogTitle disableTypography className={classes.modalHeader}>
        <div className={classes.modalHeadingContainer}>
          <div className={classes.modalHeadingLogoName}>
            <span className={classes.modalHeadingLg}>New Lock</span>
          </div>
        </div>
        <IconButton
          aria-label="close"
          className={classes.modalCloseIcon}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.root}>
        <div className={classes.modalContainer}>
          <div className={classes.inputHeader}>
            <Typography variant="h3" className={classes.inputHeading}>
              Token Address
            </Typography>
          </div>
          <div className={classes.inputContainer}>
            <input
              className={classes.textInput}
              label=""
              type="text"
              style={{ letterSpacing: "normal" }}
              value={tokenAddress}
              onChange={(ev) => {
                setTokenAddress(ev.target.value);
              }}
            />
          </div>
          {poolInfo?.name && (
            <div className={classes.tokenInfoContainer}>
              <div className={classes.tokenNameSymbolContainer}>
                <img
                  src={poolInfo?.tokenImage || EmptyTokenImage}
                  className={classes.tokenImage}
                  alt="."
                />
                <div className={classes.tokenSymbolName}>
                  <Typography variant="h5" className={classes.tokenSymbol}>
                    {poolInfo?.symbol}
                  </Typography>
                  <Typography variant="h5" className={classes.tokenName}>
                    {poolInfo?.name}
                  </Typography>
                </div>
              </div>
              <Typography variant="h3" className={classes.priceText}>
                {toFixed(
                  bigInt(poolInfo?.tokenBalance),
                  poolInfo?.decimals,
                  5
                ) || 0}
              </Typography>
            </div>
          )}
          <div className={classes.lockContainer}>
            <div className={classes.inputHeader}>
              <Typography variant="h3" className={classes.inputHeading}>
                Lock Amount
              </Typography>
            </div>

            <div className={classes.stakingAmountLine}>
              <input
                className={classes.textInput}
                label=""
                type="text"
                value={inputValue}
                onChange={(ev) => {
                  if (/^[0-9]*\.?[0-9]*$/.test(ev.target.value)) {
                    setInputValue(ev.target.value);
                  }
                }}
              />

              <div
                className={classes.stakingMaxButton}
                onClick={() => {
                  setInputValue(maximumAmount.toString());
                }}
                aria-hidden="true"
              >
                Max
              </div>
            </div>

            <div className={classes.inputHeader}>
              <Typography variant="h3" className={classes.inputHeading}>
                Unlock Date
              </Typography>
              <Typography variant="h3" className={classes.inputHeading}>
                {getUnlockTime(unlockDate)}
              </Typography>
            </div>
            <div className={classes.inputContainer}>
              <input
                className={classes.textInput}
                label=""
                type="text"
                value={unlockDays}
                onChange={(ev) => {
                  if (/^[0-9]*\.?[0-9]*$/.test(ev.target.value)) {
                    setUnlockDays(ev.target.value);
                  }
                }}
              />
              <Typography variant="h3" className={classes.dayInput}>
                Days
              </Typography>
            </div>
          </div>

          <div className={classes.actionContainer}>
            <Button
              classes={{
                root: classes.cancelButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </Button>
            <Button
              classes={{
                root: classes.walletButton,
                label: classes.buttonLabel,
              }}
              onClick={(ev) => {
                if (
                  poolInfo?.name &&
                  parseFloat(inputValue) <= maximumAmount &&
                  parseFloat(inputValue) > 0 &&
                  parseInt(unlockDays) > 0
                ) {
                  onLock(
                    tokenAddress,
                    inputValue,
                    unlockDate,
                    poolInfo?.decimals
                  );
                  return;
                }
                if (parseFloat(inputValue) > maximumAmount) {
                  setInputValue(maximumAmount);
                }
                if (parseInt(unlockDays) <= 0) {
                  setUnlockDays(1);
                }
              }}
            >
              Lock
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(LockModal));
