import STARTokenAbi from "./abis/startToken";
import SeedPresaleAbi from "./abis/seedPresale";
import PrivatePresaleAbi from "./abis/privatePresale";
import StrategicPresaleAbi from "./abis/strategicPresale";
import ERC20Abi from "./abis/erc20";
import factoryAbi from "./abis/factory";
import factoryV2Abi from "./abis/factoryV2";
import factoryV3Abi from "./abis/factoryV3";
import starterInfoAbi from "./abis/starterInfo";
import starterInfoV3Abi from "./abis/starterInfoV3";
import vestStakingAbi from "./abis/vestStaking";
import starterPresaleAbi from "./abis/starterPresale";
import starterPresaleV2Abi from "./abis/starterPresaleV2";
import starterPresaleV3Abi from "./abis/starterPresaleV3";

import vestingAbi from "./abis/vesting";
import wsbTokenAbi from "./abis/wsb";
import wsbPrivatePresaleAbi from "./abis/wsbPrivatePresale";
import wiseTokenAbi from "./abis/wise";
import wisePublicPresaleAbi from "./abis/wisePublicPresale";
import vestTokenAbi from "./abis/vest";
import vestPrivateSaleAbi from "./abis/vestPrivateSale";
import vestSeedSaleAbi from "./abis/vestSeed";

import farmingAbi from "./abis/farming";
import farmingPairAbi from "./abis/farmingPair";

import starterVestingAbi from "./abis/starterVesting";

import lockerAbi from "./abis/Locker";
import lockerStakingAbi from "./abis/LockerStaking";

const config = {
  STARTokenAddress: "0x2ab516A0a7fc2026f93Ad041ACa8942B1F4748aA",
  STARTokenAbi,
  SeedPresaleAddress: "0x2019459B04E37436E7EdC3D5d6346453d08606Fe",
  SeedPresaleAbi,
  PrivatePresaleAddress: "0x3b447f280D03761De72AE1d1640C4E3D4e6D0909",
  PrivatePresaleAbi,
  StrategicPresaleAddress: "0x58DAB443fc4E7977dD09EA4cD83944a60f17C283",
  StrategicPresaleAbi,
  ERC20Abi,
  factoryAbi,
  factoryAddress: "0x34Cc2A333d4A6086726B3d84220eA15d78Ca1962",
  factoryV2Abi,
  factoryV2Address: "0x43a953884f7C2bB63f74F921b266f5174195afe3",
  factoryV3Abi,
  factoryV3Address: "0xC4Bc9098C6a5ec1Ed12C3C8beB201ff7Cd271290",

  starterInfoAddress: "0x2593624e8d4bD91ef12F45f0324e09f2cd340907", // StarterInfo
  starterInfoAbi,

  starterInfoV3Address: "0x9AB4268495934F3C64896E817d137F969023296f",
  starterInfoV3Abi,

  vestStaking: "0xb90a9445413095dF23c0D4cF23f875A176a34d2A", // LockerStaking
  vestStakingAbi: lockerStakingAbi,

  starterPresaleAbi,
  starterPresaleV2Abi,
  starterPresaleV3Abi,
  vestingAddress: "0x3C24256B2cE30c88c29cc57e273958038B62222a",
  vestingAbi,

  wsbToken: "0x22168882276e5D5e1da694343b41DD7726eeb288",
  wsbTokenAbi,
  wsbPrivatePresaleAddress: "0x84E736a4cCC7FB032d671B2877d6210E3B64F105",
  wsbPrivatePresaleAbi,

  wiseToken: "0x0000000000000000000000000000000000000000",
  wiseTokenAbi,
  wisePublicPresaleAddress: "0xbB064642C34C3FDE27fba69940B0de5FE5445609",
  wisePublicPresaleAbi,

  vestTokenAddress: "0x2ab516A0a7fc2026f93Ad041ACa8942B1F4748aA", // OPLS token
  vestTokenAbi,

  vestPrivateSale: "0x79E9042C67E1672869D0D745080A239c8FED8c38",
  vestPrivateSaleAbi,
  vestSeedSale: "0xF9A761254481796F6B8b0DE711A5029215775f93",
  vestSeedSaleAbi,

  starterVestingAddress: "0x572cad27E3eB08d5ad879937477337cf5C95CBF6", // Locker
  starterVestingAbi: lockerAbi,
  farmingAddress: "0x38E85adcaBdFe9f07AD155147EB5F55582011FD0",
  farmingAbi,
  farmingPairAddress: "0x67b78318db88eeb6c9271816114d3ca6423fd66d",
  farmingPairAbi,
  wbnbAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
};

export default config;

/* PulseChain v3
✅ Starting contract publishing...
⌛ Deploying OPLS token ... txhash 0xeece52a080ad9d1cbdd8141588a7e864f90d2864670cb16bf130cb4c340e0dfe
✅ Deployed OPLS token to 0x2ab516A0a7fc2026f93Ad041ACa8942B1F4748aA
⌛ Deploying StarterInfo ... txhash 0x65163166ec0102354b9b9cc9e5efb3695009e3fdb78e6e1234ac4c7f28c8d57b
✅ Deployed StarterInfo to 0x2593624e8d4bD91ef12F45f0324e09f2cd340907
⌛ Deploying LockerStaking ... txhash 0x6ea181265df2251ab35f6ffb11ce38de65b0c47e97cb65bbca2098971ee8b8d6
✅ Deployed LockerStaking to 0xb90a9445413095dF23c0D4cF23f875A176a34d2A
⌛ Deploying Locker ... txhash 0xf57e12f0bb44851869f49928b5aa6adda4ff8f5344c072c6b2ca159ab2149d2a
✅ Deployed Locker to 0x572cad27E3eB08d5ad879937477337cf5C95CBF6
✅ Publishing completed successfully...
*/

/* PulseChain v4
✅ Starting contract publishing...
⌛ Deploying OPLS token ... txhash 0x6b0572deef05c7399f3dba78e1e9b0d047bea28342177035ae9be8104e35ea5e
✅ Deployed OPLS token to 0x2ab516A0a7fc2026f93Ad041ACa8942B1F4748aA
⌛ Deploying StarterInfo ... txhash 0x4ba0d4a64c13f4726241432f55e9198c5fddec0dcd3cfe60e9b3343ab991725e
✅ Deployed StarterInfo to 0x2593624e8d4bD91ef12F45f0324e09f2cd340907
⌛ Deploying LockerStaking ... txhash 0xdf69aa59d0aca98b41404fe3b876c10b5d34d78d236009273aacf1e7c37c4c5e
✅ Deployed LockerStaking to 0xb90a9445413095dF23c0D4cF23f875A176a34d2A
⌛ Deploying Locker ... txhash 0xb8dfe47df953750b01ac61d2db5e175d7b0406b4fff13c749df496a64bb51fff
✅ Deployed Locker to 0x572cad27E3eB08d5ad879937477337cf5C95CBF6
✅ Publishing completed successfully...
*/