import React, { useState, useEffect, useMemo } from "react";

import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import bigInt from "big-integer";
import moment from "moment";
import { toFixed } from "../../../constants";
import EmptyTokenImage from "../../../assets/empty-token.png";
import LogoImage from "./logoImage";
import PoolCard from "./poolCard";
import { formatCurrency } from "../../../utils/formatCurrency";

const styles = (theme) => ({
  poolsTableContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "75px",
    },
  },
  poolsTable: {
    minWidth: "768px",
  },
  logoTitleContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
  },
  priceText: {
    color: theme?.colors?.pools?.textSelected,
  },
  timeText: {
    color: theme?.colors?.pools?.text,
  },
  viewButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    minWidth: "150px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonTextSecondary,
    },
    "&:disabled": {
      opacity: "0.7",
      color: theme?.colors?.wallet?.buttonTextSecondary,
      cursor: "not-allowed",
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  ///////////////////////////////////////////////////////////////////////
  table: {
    width: "100%",
    marginTop: "1.5rem",
    borderCollapse: "collapse",
    borderSpacing: "0",
    boxShadow: "0px 10px 60px " + theme?.colors?.containerShadow,
    borderRadius: "32px",

    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "table",
    },
  },
  tableHeader: {
    color: "#A0A0A0",
    fontSize: "14px",
    background: theme?.colors?.pools?.headerBackground,
    borderBottom: "1px solid " + theme?.colors?.pools?.rowBorderBottom,
    padding: "16px",
  },
  tableHeaderItem: {
    width: "15%",
    padding: "16px",
    fontWeight: "normal",
  },
  tableBody: {
    background: theme?.colors?.pools?.bodyBackground,
    color: "#fff",
    width: "100%",
  },
  tableRow: {
    borderBottom: "1px solid" + theme?.colors?.pools?.rowBorderBottom,
    color: theme?.colors?.pools?.tableDataText,
  },
  tableRowLast: {
    borderBottom: "none",
    color: theme?.colors?.pools?.tableDataText,
  },
  tableNameRow: {
    display: "flex",
    alignItems: "center",
    color: theme?.colors?.pools?.tableDataText,
    fontWeight: 600,
  },
  tableName: {
    width: "max-content",
    alignItems: "center",
    margin: "0 2px 0 0",
  },
  tableData: {
    padding: "16px",
    color: theme?.colors?.pools?.tableDataText,
  },
  emptyContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "24px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textAlign: "center",
    fontSize: "20px",
  },
  mobileContainer: {
    width: "100%",
    marginTop: "1.5rem",
    gap: "1.5rem",

    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  button: {
    padding: "14px 20px",
    fontSize: "16px",
    margin: "10px",
    cursor: "pointer",

    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBorderSecondary,
    color: theme?.colors?.wallet?.buttonTextTertiary,
  },
  disabledButton: {
    padding: "14px 20px",
    fontSize: "16px",
    margin: "10px",
    cursor: "initial",
    opacity: "0.4",

    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBorderSecondary,
    color: theme?.colors?.wallet?.buttonTextTertiary,
  },
});

const PoolTable = ({ classes, pools, location }) => {
  const history = useHistory();
  const [currentTimestamp, setCurrentTimestamp] = useState(moment.now() / 1000);
  const [mappedPools, setMappedPools] = useState({});

  const itemsPerPage = 10;
  const [page, setPage] = useState(1);

  useEffect(() => {
    const _factoredPools = pools.reduce((orgPools, pool) => {
      if (orgPools[pool.tokenAddress]?.length > 0) {
        const newPools = {
          ...orgPools,
          [`${pool.tokenAddress}`]: [...orgPools[pool.tokenAddress], pool],
        };
        return newPools;
      } else {
        const newPools = {
          ...orgPools,
          [`${pool.tokenAddress}`]: [pool],
        };
        return newPools;
      }
    }, {});
    const _sortedPools = Object.keys(_factoredPools).reduce(
      (orgPools, pool) => {
        return {
          ...orgPools,
          [`${pool}`]: _factoredPools[pool].sort(
            (pool1, pool2) =>
              parseInt(pool1.unlockTime) - parseInt(pool2.unlockTime)
          ),
        };
      },
      {}
    );
    console.log(_sortedPools);
    setMappedPools(_sortedPools);
  }, [pools]);

  const getTokenLogo = (pool) => {
    if (pool?.type === 1) {
      const leftTokenImage = pool.token0Info?.image || EmptyTokenImage;
      const rightTokenImage = pool.token1Info?.image || EmptyTokenImage;
      return { left: leftTokenImage, right: rightTokenImage };
    } else if (pool?.type === 0) {
      return pool?.image || pool.imageUrl || EmptyTokenImage;
    }
  };

  const getTokenName = (pool) => {
    if (pool?.type === 1) {
      return (
        pool.token0Info.symbol.toUpperCase() +
        "-" +
        pool.token1Info.symbol.toUpperCase() +
        " LP"
      );
    } else if (pool?.type === 0) {
      return pool.symbol;
    }
  };

  const getTokenPrice = (pool) => {
    return pool?.price;
  };

  const getRemainingTime = (pool) => {
    const timestamp = parseInt(pool.unlockTime) - currentTimestamp;
    if (timestamp < 0) {
      return "Expired";
    }
    if (timestamp >= 3600 * 24) {
      return parseInt(timestamp / (3600 * 24)) + " days";
    }
    if (timestamp >= 3600) {
      return parseInt(timestamp / 3600) + " hours";
    }
    if (timestamp >= 60) {
      return parseInt(timestamp / 60) + " mins";
    }
    return (timestamp % 60) + " seconds";
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentTimestamp(moment.now() / 1000);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleView = (pool) => {
    history.push("/pool/" + pool);
  };

  const paginatedData = useMemo(
    () =>
      Object.keys(mappedPools).slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
      ),
    [mappedPools, page]
  );

  const maxPage = useMemo(
    () => Math.ceil(Object.keys(mappedPools).length / itemsPerPage),
    [mappedPools]
  );

  useEffect(() => {
    const queryPage =
      Number(new URLSearchParams(location.search).get("page")) <= 0
        ? 1
        : Number(new URLSearchParams(location.search).get("page"));

    if (maxPage >= queryPage) {
      setPage(queryPage);

      const params = new URLSearchParams();
      params.append("page", queryPage);

      history.push({ search: params.toString() });
    } else {
      setPage(1);

      const params = new URLSearchParams();
      params.append("page", 1);

      history.push({ search: params.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPage, location.search]);

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);

      const params = new URLSearchParams();
      params.append("page", page - 1);

      history.push({ search: params.toString() });
    }
  };

  const handleNext = () => {
    if (page < maxPage) {
      setPage(page + 1);
      const params = new URLSearchParams();
      params.append("page", page + 1);

      history.push({ search: params.toString() });
    }
  };

  return (
    <div className={classes.poolsTableContainer}>
      {paginatedData.length === 0 ? (
        <div className={classes.emptyContainer}>
          <div className={classes.warningContainer}>
            <Typography variant="h4" className={classes.warningText}>
              There are currently no locks to display... Be the first to create
              one by clicking New Lock in the menu above.
            </Typography>
          </div>
        </div>
      ) : (
        <>
          <div>
            <table className={classes.table}>
              {paginatedData.length > 0 && (
                <thead className={classes.tableHeader}>
                  <tr>
                    <th
                      style={{
                        padding: "16px",
                        borderTopLeftRadius: "32px",
                        fontWeight: "normal",
                        width: "30%",
                      }}
                    >
                      Name
                    </th>
                    <th className={classes.tableHeaderItem}>Price($)</th>
                    <th className={classes.tableHeaderItem}>Tokens Locked</th>
                    <th className={classes.tableHeaderItem}>Value Locked($)</th>
                    <th className={classes.tableHeaderItem}>Next Unlock</th>
                    <th
                      style={{
                        padding: "16px",
                        borderTopRightRadius: "32px",
                        fontWeight: "normal",
                        width: "30%",
                      }}
                    />
                  </tr>
                </thead>
              )}
              <tbody className={classes.tableBody}>
                {paginatedData.map((pool, index) => {
                  const tokenInfo = { ...mappedPools[pool][0] };
                  const name = getTokenName(mappedPools[pool][0]);
                  const tokenPrice = getTokenPrice(mappedPools[pool][0]);
                  const lockedAmount = mappedPools[pool].reduce(
                    (orgSum, lock) => {
                      return (
                        orgSum +
                        parseFloat(
                          toFixed(
                            bigInt(lock?.lockedAmount),
                            lock?.decimals ? Number(lock?.decimals) : 18,
                            4
                          )
                        )
                      );
                    },
                    0
                  );
                  const lockedValue =
                    parseFloat(lockedAmount) * parseFloat(tokenPrice);
                  const tokenLogo = getTokenLogo(mappedPools[pool][0]);

                  return (
                    <tr
                      key={pool}
                      className={
                        index === paginatedData.length - 1
                          ? classes.tableRowLast
                          : classes.tableRow
                      }
                    >
                      <td
                        style={
                          index === paginatedData.length - 1
                            ? {
                                padding: "16px",
                                borderBottomLeftRadius: "32px",
                              }
                            : { padding: "16px" }
                        }
                      >
                        <div className={classes.logoTitleContainer}>
                          <LogoImage
                            type={tokenInfo.type}
                            src={tokenLogo}
                            alt="."
                            defaultImage={"pools/default.png"}
                          />
                          {name}
                        </div>
                      </td>
                      <td className={classes.tableData}>
                        {formatCurrency(tokenPrice || 0, 0)}
                      </td>
                      <td className={classes.tableData}>
                        {lockedAmount.toFixed(2)}
                      </td>
                      <td className={classes.tableData}>
                        {formatCurrency(lockedValue || 0, 0)}
                      </td>
                      <td className={classes.tableData}>
                        {getRemainingTime(mappedPools[pool][0])}
                      </td>
                      <td
                        style={
                          index === paginatedData.length - 1
                            ? {
                                padding: "16px",
                                borderBottomRightRadius: "32px",
                              }
                            : { padding: "16px" }
                        }
                      >
                        <Button
                          classes={{
                            root: classes.viewButton,
                            label: classes.buttonLabel,
                          }}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            handleView(pool);
                          }}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className={classes.mobileContainer}>
              {paginatedData
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((pool) => {
                  const tokenInfo = { ...mappedPools[pool][0] };
                  const name = getTokenName(mappedPools[pool][0]);
                  const tokenPrice = getTokenPrice(mappedPools[pool][0]);
                  const lockedAmount = mappedPools[pool].reduce(
                    (orgSum, lock) => {
                      return (
                        orgSum +
                        parseFloat(
                          toFixed(
                            bigInt(lock?.lockedAmount),
                            lock?.decimals ? Number(lock?.decimals) : 18,
                            4
                          )
                        )
                      );
                    },
                    0
                  );
                  const lockedValue =
                    parseFloat(lockedAmount) * parseFloat(tokenPrice);
                  const tokenLogo = getTokenLogo(mappedPools[pool][0]);

                  const remainingTime = getRemainingTime(mappedPools[pool][0]);

                  return (
                    <PoolCard
                      key={name}
                      tokenInfo={tokenInfo}
                      name={name}
                      tokenPrice={tokenPrice}
                      lockedAmount={lockedAmount}
                      lockedValue={lockedValue}
                      tokenLogo={tokenLogo}
                      remainingTime={remainingTime}
                      onView={() => {
                        handleView(pool);
                      }}
                    />
                  );
                })}
            </div>
          </div>

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className={page === 1 ? classes.disabledButton : classes.button}
              onClick={handlePrev}
            >{`< ${page * itemsPerPage - itemsPerPage} - ${
              page * itemsPerPage
            }`}</button>
            <button
              className={
                page === maxPage ? classes.disabledButton : classes.button
              }
              onClick={handleNext}
            >{`${page * itemsPerPage + 1} - ${
              page * itemsPerPage + itemsPerPage
            } >`}</button>
          </div>
        </>
      )}
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(PoolTable)));
