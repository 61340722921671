import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Fade, Input } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";

import { withNamespaces } from "react-i18next";
import {
  GET_POOLS_SUMMARY_RETURNED,
  GET_VEST_STATS_RETURNED,
} from "../../constants";

import Store from "../../stores";
import TVLCard from "./components/tvlCard";
import TotalProjectsCard from "./components/totalProjectsCard";
import PoolTable from "./components/poolTable";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    background: theme?.colors?.header?.bodyContainer,
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "'Poppins', sans-serif",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "1140px",
  },
  totalStatusContainer: {
    display: "grid",
    gridTemplateColumns: "auto",
    gap: "30px",
    width: "100%",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      marginBottom: "50px",
    },
  },
  logo: {
    width: "250px",
    height: "250px",
    objectFit: "contain",
    marginBottom: "1rem",
  },
  header: {
    fontSize: "30px",
    lineHeight: "35px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold",
    textAlign: "center",
    color: theme?.colors?.home?.heading,
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
      lineHeight: "60px",
      maxWidth: "768px",
      marginBottom: "20px",
    },
  },
  headerSecondary: {
    fontSize: "30px",
    lineHeight: "35px",
    fontWeight: "bold",
    textAlign: "left",
    color: theme?.colors?.home?.headingSecondary,
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
      lineHeight: "60px",
    },
  },
  headerSubText: {
    fontSize: "10px",
    fontFamily: "'Poppins', sans-serif",
    lineHeight: "15px",
    fontWeight: "lighter",
    textAlign: "center",
    letterSpacing: "0px",
    color: theme?.colors?.home?.textSecondary,
    width: "100%",
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "20px",
      maxWidth: "660px",
      marginBottom: "60px",
    },
  },
  headerActionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginBottom: "100px",
    },
  },
  mainBody: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  poolsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "75px",
    },
  },
  poolLabelContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  poolsLabel: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "30px",
    fontWeight: "lighter",
    lineHeight: "36px",
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.home?.text,
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "50px",
    },
  },
  switchLabel: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.home?.text,
  },
  switchIcon: {
    width: "20px",
    height: "20px",
  },
  switchTrack: {
    opacity: "0.8 !important",
    background: `${theme?.colors?.home?.text} !important`,
  },
  mediaLabel: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "25px",
    fontWeight: "lighter",
    lineHeight: "30px",
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.home?.text,
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "20px",
    },
  },
  poolsWrapper: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridColumnGap: "30px",
    gridRowGap: "40px",

    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },

    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },
  },
  poolItemWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  text: {
    fontSize: "16px",
    fontWeight: "lighter",
    textAlign: "left",
    color: theme?.colors?.home?.text,
    lineHeight: "24px",
    marginBottom: "15px",
    marginTop: "0px",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
  },
  poolButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "170px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    fontFamily: "'Poppins', sans-serif",

    marginRight: "5px",
    marginLeft: "5px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "15px",
      marginLeft: "15px",
      marginBottom: "15px",
    },
  },
  joinButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "170px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    fontFamily: "'Poppins', sans-serif",
    letterSpace: "0px",
    whiteSpace: "nowrap",

    marginRight: "5px",
    marginLeft: "5px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "15px",
      marginLeft: "15px",
      marginBottom: "15px",
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    whiteSpace: "nowrap",
  },
  emptyContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  warningText: {
    color: "#ededed",
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  mediaContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    [theme.breakpoints.up("ms")]: {
      marginBottom: "50px",
    },
  },
  mediasWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  mediaItem: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
    [theme.breakpoints.up("lg")]: {
      flex: 1,
      marginRight: "15px",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  searchBox: {
    width: "100%",
    height: "40px",
    borderRadius: "24px",
    border: "solid 1px " + theme?.colors?.pools?.border,
  },
  searchInput: {
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0px",
    color: theme?.colors?.pools?.searchText,
    borderRadius: "14px",
  },

  searchMagnifier: {
    width: "17px",
    height: "17px",
    margin: "16px",
    color: theme?.colors?.pools?.searchMagnifier,
  },
});

const emitter = Store.emitter;
const store = Store.store;

const Home = ({ classes, location }) => {
  const [totalRaisedUSD, setTotalRaisedUSD] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [vestPools, setVestPools] = useState([]);
  const [filteredPools, setFilteredPools] = useState([]);
  const [vestStats, setVestStats] = useState(store.getStore("vestStats"));

  useEffect(() => {
    fetchPools();
    emitter.on(GET_POOLS_SUMMARY_RETURNED, getPoolsSummaryReturned);
    emitter.on(GET_VEST_STATS_RETURNED, getVestStatsReturned);
    return () => {
      emitter.removeListener(
        GET_POOLS_SUMMARY_RETURNED,
        getPoolsSummaryReturned
      );
    };
  }, []);

  useEffect(() => {
    const totalValueLocked = Object.values(vestPools).reduce((orgSum, pool) => {
      return orgSum + (pool?.lockedValue || 0);
    }, 0);
    setTotalRaisedUSD(totalValueLocked);
    setFilteredPools(
      Object.values(vestPools).filter((pool) => {
        return (
          JSON.stringify(pool)
            .toLowerCase()
            .includes(searchString.toLowerCase()) && pool?.lockedValue > 0
        );
      }) || []
    );
  }, [vestPools, searchString]);

  const fetchPools = () => {
    setVestPools(store.getStore("pools"));
  };

  const fetchVestStats = () => {
    setVestStats(store.getStore("vestStats"));
  };

  const getVestStatsReturned = () => {
    fetchVestStats();
  };
  const getPoolsSummaryReturned = () => {
    fetchPools();
  };

  return (
    <div className={classes.root}>
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          <div className={classes.totalStatusContainer}>
            <TVLCard value={totalRaisedUSD} />
            <TotalProjectsCard value={filteredPools.length || 0} />
          </div>

          <Input
            classes={{
              root: classes.searchBox,
              input: classes.searchInput,
            }}
            disableUnderline={true}
            placeholder="Search by Name, Token contract address, Token description"
            value={searchString}
            startAdornment={
              <SearchIcon classes={{ root: classes.searchMagnifier }} />
            }
            onChange={(ev) => {
              setSearchString(ev.target.value);
            }}
          />

          <PoolTable pools={filteredPools} />
        </div>
      </Fade>
      <div className="klaviyo-form-YdiSzv" />
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(Home)));
