import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Button, Snackbar, useTheme } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Menu";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

import BUIDLLogo from "../../assets/logos/lock-on-base-logo.png";
import HeaderLink from "./components/headerLink";
import RedirectModal from "./modal";
import StakeModal from "./modal/stakeModal";
import LockModal from "./modal/lockModal";
import ThemeModal from "./modal/themeModal";

import { injected } from "../../stores/connectors";
import GlobalStore from "../../stores";
import UnlockModal from "../unlock/unlockModal";
import bigInt from "big-integer";

import ThemeSwitcher from "./components/ThemeSwitcher";
import ConnectWalletButton from "../connectWalletButton";

import {
  ERROR,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONNECT_WALLET,
  GET_BALANCES_RETURNED,
  STAKE,
  STAKE_RETURNED,
  UNSTAKE,
  UNSTAKE_RETURNED,
  toFixed,
  CLAIM_RETURNED,
  CANCEL_PRESALE_RETURNED,
  WRITE_RETURNED,
  PUBLIC_LOCK,
  PUBLIC_LOCK_RETURNED,
  PUBLIC_WITHDRAW_RETURNED,
} from "../../constants";

const emitter = GlobalStore.emitter;
const Store = GlobalStore.store;
const dispatcher = GlobalStore.dispatcher;

const styles = (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: theme?.colors?.header?.background,
    [theme.breakpoints.up("md")]: {
      maxWidth: "100vw",
      overflow: "hidden",
    },
  },
  headerContainer: {
    display: "flex",
    width: "100vw",
    height: "90px",
    background: theme?.colors?.header?.backgroundColor,
  },
  headerInnerContainer: {
    display: "flex",
    width: "100vw",
    height: "80px",
    boxShadow: "0px 3px 3px #0B193B24",
    background: theme?.colors?.header?.backgroundColor,

    alignItems: "center",
    justifyContent: "flex-start",
    padding: "16px 2rem",

    [theme.breakpoints.up("md")]: {
      gap: "1rem",
      padding: "16px 2rem",
    },

    [theme.breakpoints.up("lg")]: {
      gap: "2rem",
      padding: "16px 6rem",
    },
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  linkContainer: {
    display: "none", // todo: check
    alignItems: "center",
    justifyContent: "flex-end",

    width: "100%",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      gap: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      gap: "4rem",
    },
    [theme.breakpoints.up("xl")]: {
      gap: "6rem",
    },
  },
  walletContainer: {
    height: "50px",
    maxWidth: "1280px",
    display: "none",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gap: "0.7rem",
    },
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  createButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
    },

    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    height: "40px",
    width: "130px",
    padding: "8px",

    fontFamily: "Poppins",
    letterSpace: "0px",
    whiteSpace: "nowrap",
  },
  certifiedButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    textTransform: "capitalize",
    height: "40px",
    width: "130px",
    color: theme?.colors?.wallet?.buttonText,
    padding: "8px",
    "&:hover": {
      border: "solid 2px" + theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonBackgroundHover,
    },
    fontFamily: "Poppins",
    letterSpace: "0px",
    whiteSpace: "nowrap",
  },
  logoButton: {
    padding: "0px",
    display: "flex",
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  byText: {
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
    fontSize: "8pt",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    // marginTop: "-10px",
  },
  logoTitle: {
    color: theme?.colors?.home?.heading,

    fontFamily: "Poppins, sans-serif",
    fontSize: "22px",

    lineHeight: "21px",
    margin: "0px",
    marginTop: "3px",
  },
  logoSubtitle: {
    color: "#A1A1A4",
  },
  logoText: {
    color: theme?.colors?.header?.text,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  logoBoldText: {
    color: theme?.colors?.header?.boldBrownText,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  walletButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.header?.tierCircleFilled,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  applyButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    padding: "8px 16px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  ctaButton: {
    borderRadius: "5px",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    border: "solid 4px" + theme?.colors?.header?.callout,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  openMenuButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    position: "absolute",
    right: "2rem",
    top: "18px",
  },
  menuButton: {
    padding: "6px",
  },
  menuIcon: {
    width: "30px",
    height: "30px",
    color: theme?.colors?.header?.menuButton,
  },
  balanceInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors?.header?.balanceText,
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
    cursor: "pointer",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Header({ classes }) {
  const history = useHistory();
  const theme = useTheme();
  const nav = (screen) => {
    history.push("/" + screen);
  };
  const [modalOpen, setModalOpen] = React.useState(false);
  const [accountInfo, setAccountInfo] = React.useState("");
  const [accountModalOpen, setAccountModalOpen] = React.useState(false);
  const [stakeModalOpen, setStakeModalOpen] = React.useState(false);
  const [lockModalOpen, setLockModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [infoMessage, setInfoMessage] = React.useState(null);
  const [themeModalOpen, setThemeModalOpen] = React.useState(false);

  // const [walletBnbBalance, setWalletBnbBalance] = React.useState(0);
  const [walletStartBalance, setWalletStartBalance] = React.useState(0);
  const [stakedBalance, setStakedBalance] = React.useState(0);
  const [burnFee, setBurnFee] = React.useState(50);
  const [minStakeAmount, setMinStakeAmount] = React.useState(10000);

  const web3Context = useWeb3React();
  const { library, account, activate, active, chainId, deactivate } =
    web3Context;

  React.useEffect(() => {
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    emitter.on(CONNECT_WALLET, onConnectWallet);
    emitter.on(ERROR, errorReturned);
    emitter.on(GET_BALANCES_RETURNED, getBalancesReturned);

    emitter.on(STAKE_RETURNED, stakeReturned);
    emitter.on(UNSTAKE_RETURNED, unstakeReturned);

    emitter.on(CLAIM_RETURNED, claimReturned);
    emitter.on(CANCEL_PRESALE_RETURNED, cancelPresaleReturned);
    emitter.on(WRITE_RETURNED, writeReturned);
    emitter.on(PUBLIC_WITHDRAW_RETURNED, withdrawReturned);
    emitter.on(PUBLIC_LOCK_RETURNED, lockReturned);

    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected);
        injected.on("Web3ReactDeactivate", () => {
          Store.setStore({
            account: {},
            web3context: null,
          });
          emitter.emit(CONNECTION_DISCONNECTED);
        });
      }
    });

    return () => {
      emitter.removeListener(ERROR, errorReturned);
      emitter.removeListener(CONNECTION_CONNECTED, connectionConnected);
      emitter.removeListener(CONNECTION_DISCONNECTED, connectionDisconnected);
      emitter.removeListener(CONNECT_WALLET, onConnectWallet);

      emitter.removeListener(CLAIM_RETURNED, claimReturned);
      emitter.removeListener(PUBLIC_LOCK_RETURNED, lockReturned);
      emitter.removeListener(CANCEL_PRESALE_RETURNED, cancelPresaleReturned);
      emitter.removeListener(WRITE_RETURNED, writeReturned);
    };
  }, []);

  React.useEffect(() => {
    if (active) {
      if (account && library && chainId === theme.chainId) {
        Store.setStore({
          account: { address: account },
          web3context: web3Context,
        });
        emitter.emit(CONNECTION_CONNECTED);
      }
    } else {
      Store.setStore({ account: {}, web3context: null });
      emitter.emit(CONNECTION_DISCONNECTED);
    }
  }, [active]);

  React.useEffect(() => {
    if (chainId !== theme.chainId && chainId !== undefined) {
      setErrorMessage(
        `Connect your wallet and set network to ${theme.chainName}!`
      );
      deactivate();
      Store.setStore({ account: {}, web3context: null });
      emitter.emit(CONNECTION_DISCONNECTED);
    }
  }, [chainId]);

  const claimReturned = (receipt) => {
    console.log("CLAIM_RETURNED", receipt);
    setInfoMessage(
      "Claim Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const cancelPresaleReturned = (receipt) => {
    console.log("CANCEL_PRESALE_RETURNED", receipt);
    setInfoMessage(
      "CANCEL PRESALE Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const closeNavModal = () => {
    setModalOpen(false);
  };

  const connectionConnected = () => {
    setAccountInfo(Store.getStore("account"));
  };

  const connectionDisconnected = () => {
    setAccountInfo(Store.getStore("account"));
  };

  const errorReturned = (_error) => {
    const message = `${_error}`;
    if (!message.includes("Invalid JSON")) {
      setErrorMessage(
        (message && message.length > 0 && message.slice(0, 60) + "...") ||
          "Error"
      );
    }
  };

  const onConnectWallet = () => {
    if (chainId === theme.chainId) {
      setAccountModalOpen(true);
    } else {
      setErrorMessage(
        `Connect your wallet and set network to ${theme.chainName}!`
      );
    }
  };

  const getBalancesReturned = () => {
    // const bnbBalance = Store.getStore("bnbBalance");
    const vestBalance = Store.getStore("vestBalance");
    const stakedBalance = Store.getStore("stakedBalance");
    const minStakeAmount = Store.getStore("minStakeAmount");

    // setWalletBnbBalance(toFixed(bigInt(bnbBalance), 18, 3));
    setWalletStartBalance(toFixed(bigInt(vestBalance), 18, 3));
    setStakedBalance(toFixed(bigInt(stakedBalance), 18, 3));
    setBurnFee(Store.getStore("burnFee"));
    setMinStakeAmount(toFixed(bigInt(minStakeAmount), 18, 3));
  };

  const onStake = (amount) => {
    if (parseInt(amount) > 0 && parseInt(amount) <= walletStartBalance) {
      dispatcher.dispatch({
        type: STAKE,
        content: {
          amount: Web3.utils.toWei(amount),
        },
      });
    } else {
      setErrorMessage("Invalid parameter");
    }
  };

  const onUnstake = (amount) => {
    if (parseInt(amount) > 0 && parseInt(amount) <= stakedBalance) {
      dispatcher.dispatch({
        type: UNSTAKE,
        content: {
          amount: Web3.utils.toWei(amount),
        },
      });
    } else {
      setErrorMessage("Invalid parameter");
    }
  };

  const onLock = (tokenAddress, inputAmount, unlockDate, decimals) => {
    console.log("onLock", Web3.utils.toWei(inputAmount), unlockDate);
    const amount = bigInt(Web3.utils.toWei(inputAmount)).shiftRight(
      18 - decimals
    );
    console.log(amount);
    dispatcher.dispatch({
      type: PUBLIC_LOCK,
      content: {
        tokenAddress: tokenAddress,
        amount: amount.toString(),
        unlockTime: unlockDate,
      },
    });
  };

  const stakeReturned = (receipt) => {
    console.log("STAKE_RETURNED", receipt);
    setInfoMessage(
      "Stake Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const unstakeReturned = (receipt) => {
    console.log("UNSTAKE_RETURNED", receipt);
    setInfoMessage(
      "Unstake Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const writeReturned = (receipt) => {
    console.log("WRITE_RETURNED", receipt);
    setInfoMessage(
      "Write Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const withdrawReturned = (receipt) => {
    console.log("WITHDRAW_RETURNED", receipt);
    setInfoMessage(
      "Withdraw Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const lockReturned = (receipt) => {
    console.log("LOCK_RETURNED", receipt);
    setInfoMessage("Lock Success! TransactionHash: " + receipt.transactionHash);
  };

  return (
    <div className={classes.root}>
      {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={4000}
          onClick={() => {
            setErrorMessage(false);
          }}
          onClose={() => {
            setErrorMessage(false);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      )}
      {infoMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={6000}
          onClose={() => {
            setInfoMessage(false);
          }}
        >
          <Alert severity="info">{infoMessage}</Alert>
        </Snackbar>
      )}
      <div className={classes.headerContainer}>
        <div className={classes.headerInnerContainer}>
          <div className={classes.openMenuButtonContainer}>
            <IconButton
              aria-label="menu"
              classes={{ root: classes.menuButton }}
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </div>
          <div className={classes.logoContainer}>
            <div>
              <button
                type="button"
                aria-label="logo"
                className={classes.logoButton}
                onClick={() => {
                  nav("home");
                }}
              >
                <img
                  src={BUIDLLogo}
                  alt="."
                  style={{ height: "46px", marginRight: "8px" }}
                />
                <div className={classes.byText}>
                  <p className={classes.logoTitle}>Locker</p>
                  <span className={classes.logoSubtitle}>by Starter.xyz</span>
                </div>
              </button>
            </div>
          </div>
          <div className={classes.linkContainer}>
            <HeaderLink screenType="DESKTOP" text="All Locks" to="/home" />
            <HeaderLink
              screenType="DESKTOP"
              text="Launchpad"
              to="https://starter.xyz"
              externalLink={true}
            />
            <div className={classes.walletContainer}>
              <Button
                classes={{
                  root: classes.certifiedButton,
                  label: classes.buttonLabel,
                }}
                onClick={() => {
                  setStakeModalOpen(true);
                }}
              >
                Staking
              </Button>
              <Button
                classes={{
                  root: classes.createButton,
                  label: classes.buttonLabel,
                }}
                onClick={() => {
                  setLockModalOpen(true);
                }}
              >
                New Lock
              </Button>

              <ConnectWalletButton />
              <ThemeSwitcher />
            </div>
          </div>
        </div>
        <RedirectModal
          closeModal={closeNavModal}
          modalOpen={modalOpen}
          account={accountInfo}
          // balance={walletBnbBalance}
          onConnect={() => {
            setAccountModalOpen(true);
          }}
          setStakeModalOpen={setStakeModalOpen}
          setLockModalOpen={setLockModalOpen}
        />
        <UnlockModal
          closeModal={() => {
            setAccountModalOpen(false);
          }}
          modalOpen={accountModalOpen}
        />
        {stakeModalOpen && (
          <StakeModal
            closeModal={() => {
              setStakeModalOpen(false);
            }}
            account={accountInfo}
            modalOpen={stakeModalOpen}
            // bnbBalance={parseFloat(walletBnbBalance)}
            vestBalance={parseFloat(walletStartBalance)}
            stakedBalance={parseFloat(stakedBalance)}
            onStake={onStake}
            onUnstake={onUnstake}
            burnFee={burnFee}
            minStakeAmount={minStakeAmount}
          />
        )}
        {lockModalOpen && (
          <LockModal
            onLock={onLock}
            store={Store}
            modalOpen={lockModalOpen}
            closeModal={() => {
              setLockModalOpen(false);
            }}
            account={accountInfo}
          />
        )}
        <ThemeModal
          modalOpen={themeModalOpen}
          closeModal={() => {
            setThemeModalOpen(false);
          }}
        />
      </div>
    </div>
  );
}

export default withRouter(withStyles(styles)(Header));
